import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { FileType, RowType } from "../../common/constants"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import Button from "../Button"
import Card from '../cards/Card'
import TextInput from '../TextInput'
import Uploader from '../Uploader'
import styles from "./Row.module.css"

const Row = ({
  index = null,
  content = "",
  type = RowType.Text,
  columnIndex = null,
  onChange = () => { },
  onAddColumn = () => { },
  onDeleteColumn = () => { },
  onDeleteRow = () => { },
  onSelection = () => { },
  selection = null,
}) => {

  const [allContents, setContent] = useState({
    text: "",
    image: "",
    columns: [],
    video: ""
  })
  const [_type, setType] = useState(type)

  const [imageUrl, setImageUrl] = useState(type === RowType.Image ? content : "")
  const [videoUrl, setVideoUrl] = useState(type === RowType.Video ? content : "")
  const [uploadReset, setUploadeReset] = useState(false)

  const mdxRef = useRef(null)

  useEffect(() => {
    setContent(c => {
      c[_type] = content
      if (_type === RowType.Text && mdxRef?.current) {
        mdxRef.current.setMarkdown(content)
      }
      return { ...c }
    })
  }, [])

  useEffect(() => {
    if (columnIndex !== null) {
      onChange({ content: allContents[_type], type: _type, columnIndex: columnIndex - 1 })
    } else {
      onChange({ content: allContents[_type], type: _type })
    }
  }, [allContents, _type, columnIndex])

  useEffect(() => {
    if (_type === RowType.Image) {
      setContent(c => {
        c[_type] = imageUrl ?? ""
        return { ...c }
      })
    }
  }, [imageUrl])

  useEffect(() => {
    if (_type === RowType.Video) {
      setContent(c => {
        c[_type] = videoUrl ?? ""
        return { ...c }
      })
    }
  }, [videoUrl])

  const typeTabs = useMemo(() => {
    const types = [
      { label: "Testo", type: RowType.Text },
      { label: "Immagine", type: RowType.Image },
      { label: "Video", type: RowType.Video },
    ]

    if (columnIndex === null) {
      types.push({ label: "Colonne", type: RowType.Columns })
    }
    return types
  }, [])

  const onTypeChange = useCallback((type) => {
    setType(type)

    if (type === RowType.Columns && allContents.columns.length === 0) {
      setContent(c => {
        c.columns = [{ content: "", type: RowType.Text }]
        return { ...c }
      })
    }
  }, [])

  const onColumnChange = useCallback(({ content, type, columnIndex }) => {
    setContent(c => {
      c.columns[columnIndex] = {
        content,
        type
      }
      return { ...c }
    })
  }, [allContents])

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        onSelection({ rowIndex: index, columnIndex: columnIndex !== null ? columnIndex - 1 : null })
      }}
      className={columnIndex !== null ? `${styles.container} ${styles.columns}` : ""}>
      {
        columnIndex === null &&
        <div className={styles.rowHeader}>
          Riga {index + 1}
          {index > 0 &&
            <button className={styles.closeButton} onClick={(e) => {
              e.stopPropagation()
              onDeleteRow(index)
            }}>
              <CloseIcon />
            </button>
          }
        </div>
      }
      <div className={columnIndex !== null ? styles.columnsContent : ""}>
        {
          columnIndex !== null &&
          <div className={typo.subheadline} style={{ marginBottom: ".5rem", display: "flex", gap: ".3rem", alignItems: "center" }}>
            {columnIndex > 1 &&
              <button className={styles.closeButton} onClick={(e) => {
                e.stopPropagation()
                onDeleteColumn(columnIndex - 1)
              }}>
                <CloseIcon />
              </button>
            }
            Colonna {columnIndex}
          </div>
        }
        <div className={
          selection?.rowIndex === index && selection?.columnIndex !== null && (selection?.columnIndex + 1) === columnIndex ? `${styles.tabs} ${styles.selected}` :
            selection?.rowIndex === index && selection?.columnIndex === null ? `${styles.tabs} ${styles.selected}` : styles.tabs
        }>
          <Each
            of={typeTabs}
            render={(tab) => (
              <div
                className={_type === tab.type ? `${styles.tab} ${styles.selected}` : styles.tab}
                onClick={() => onTypeChange(tab.type)}
              >
                {tab.label}
              </div>
            )}
          />
        </div>
        <div className={
          selection?.rowIndex === index && selection?.columnIndex !== null && (selection?.columnIndex + 1) === columnIndex ? `${styles.content} ${styles.selected}` :
            selection?.rowIndex === index && selection?.columnIndex === null ? `${styles.content} ${styles.selected}` : styles.content
        }>
          {
            _type === RowType.Text && (
              <div className={styles.text}>
                <MDXEditor
                  ref={mdxRef}
                  markdown={allContents.text}
                  onChange={md => setContent(c => {
                    c.text = md
                    return { ...c }
                  })}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    linkDialogPlugin(),
                    diffSourcePlugin(),
                    tablePlugin(),
                    toolbarPlugin({
                      toolbarContents: () => (
                        <>
                          {' '}
                          <UndoRedo />
                          <Separator />
                          <BoldItalicUnderlineToggles />
                          <Separator />
                          <ListsToggle />
                          <CreateLink />
                          <InsertTable />
                          <Separator />
                          <BlockTypeSelect />
                          <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                        </>
                      )
                    })
                  ]}
                />
              </div>
            )
          }
          {
            _type === RowType.Columns &&
            <div className={styles.columns}>
              <Each
                of={allContents.columns}
                render={(column, columnIndex) => (
                  <div className={styles.column}>
                    <Row
                      {...column}
                      index={index}
                      selection={selection}
                      columnIndex={columnIndex + 1}
                      onSelection={onSelection}
                      onChange={onColumnChange}
                      onDeleteColumn={(i) => {
                        setContent(c => {
                          c.columns.splice(i, 1)
                          return { ...c }
                        })
                      }}
                      onAddColumn={() => {
                        setContent(c => {
                          c.columns.push({ content: "", type: RowType.Text })
                          return { ...c }
                        })
                      }} />
                  </div>
                )}
              />
            </div>
          }
          {
            _type === RowType.Image &&
            <div className={styles.mediaContainer}>
              <div className={styles.mediaTop}>
                <Uploader
                  preview={imageUrl}
                  reset={uploadReset}
                  multiple={false}
                  type={FileType.Image}
                  onComplete={uploads => setImageUrl(uploads[0].url)}
                  onDelete={() => setImageUrl(null)}
                />
                <div className={styles.mediaHelperText}>Oppure inserisci l'URL dell'immagine</div>
              </div>

              <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem", width: "100%" }} title={`URL immagine`}>
                <TextInput
                  type={"text"}
                  value={imageUrl}
                  onKeyUp={v => setImageUrl(v)}
                  style={{
                    padding: 0, border: "none", backgroundColor: "transparent",
                    fontSize: "1rem", fontWeight: 700, width: "100%",
                    borderRadius: 0
                  }} />
              </Card>
            </div>
          }
          {
            _type === RowType.Video &&
            <div className={styles.mediaContainer}>
              <div className={styles.mediaTop}>
                <Uploader
                  preview={videoUrl}
                  reset={uploadReset}
                  multiple={false}
                  type={FileType.Image}
                  onComplete={uploads => setVideoUrl(uploads[0].url)}
                  onDelete={() => setVideoUrl(null)}
                />
                <div className={styles.mediaHelperText}>Oppure inserisci l'URL del video</div>
              </div>

              <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem", width: "100%" }} title={`URL video`}>
                <TextInput
                  type={"text"}
                  value={videoUrl}
                  onKeyUp={v => setVideoUrl(v)}
                  style={{
                    padding: 0, border: "none", backgroundColor: "transparent",
                    fontSize: "1rem", fontWeight: 700, width: "100%",
                    borderRadius: 0
                  }} />
              </Card>
            </div>
          }
        </div>
      </div>
      {
        columnIndex !== null &&
        <div className={styles.actions}>
          <Button
            fullWidth
            inverse
            onClick={onAddColumn}
            style={{ padding: ".5rem" }}>
            <AddIcon /> Aggiungi colonna
          </Button>
        </div>
      }
    </div>
  )

}


export default Row
