import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise-inactive.svg"
import { ReactComponent as QuestionsIcon } from "../../assets/images/icons/ic-questions.svg"
import { ReactComponent as RepeatIcon } from "../../assets/images/icons/ic-repeat.svg"
import { ReactComponent as TrophyIcon } from "../../assets/images/icons/ic-trophy.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { DialogStatus, TestStatus, TestType } from "../../enums"
import { formatDateV2, formatTimeV2 } from "../../utils"
import AlertDialog from "../dialogs/AlertDialog"
import Skeleton from "../Skeleton"
import TooltipWrapper from "../TooltipWrapper"
import styles from "./TestCard.module.css"
import MeatBallsMenu from "../MeatBallsMenu"

const TestCard = ({
  test = null,
  loading = false,
  onEdit = () => { },
  onDelete = () => { },
  onComplete = () => { },
  style = {}
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  // delete
  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  // complete
  const [openCompleteAlert, setOpenCompleteAlert] = useState(false)
  const [completeAlertStatus, setCompleteAlertStatus] = useState(DialogStatus.Default)
  const [completeCloseTimeout, setCompleteCloseTimeout] = useState(null)

  const numberOfTests = useMemo(() => {
    if (!test) {
      return;
    }
    let numberOfTests = 0
    const { innerTests } = test.content
    for (const innerTest of innerTests) {
      const { testType } = innerTest
      switch (testType) {
        case TestType.SingleChoice:
        case TestType.MultipleChoice:
          numberOfTests += 1
          break
        case TestType.TrueFalse:
          numberOfTests += innerTest.answers?.length > 0 ? innerTest.answers.length : 0
          break
        case TestType.TextCompletion:
          if (innerTest.words) {
            numberOfTests += innerTest.words.reduce((a, c) => {
              if (c.hidden) {
                a += 1
              }
              return a
            }, 0)
          }
          break
        default: break;
      }
    }
    return numberOfTests
  }, [test])

  const status = useMemo(() => {
    if (!test) {
      return null;
    }

    if (test.deleted_at) {
      return {
        label: t("tests.status.deleted"),
        color: "#fa6675"
      }
    }
    const { status } = test
    switch (status) {
      case TestStatus.Draft:
        return {
          label: t("tests.status.draft"),
          color: "rgba(var(--text-color-rgb), 24%)"
        }
      case TestStatus.Completed:
        return {
          label: t("tests.status.completed"),
          color: "#2fc6a0"
        }
      default: return null
    }
  }, [test])

  const menuActions = useMemo(() => {
    if (!test) return []

    const actions = [
      {
        label: t("tests.delete"), icon: DeleteIcon, style: { color: "var(--sf-red)" }, onClick: () => setOpenAlert(true)
      }
    ]
    if (test.status === TestStatus.Draft) {
      actions.unshift({
        label: t("tests.edit"), icon: EditIcon, style: { color: "var(--sf-light-green)" }, onClick: onEdit
      },)
    }

    if (test.status === TestStatus.Public) {
      actions.unshift({
        label: t("tests.end"), icon: CheckIcon, style: { color: "var(--sf-yellow)" }, onClick: () => setOpenCompleteAlert(true)
      })
    }

    if (test.status === TestStatus.Public || test.status === TestStatus.Completed) {
      actions.unshift({
        label: t("tests.open"), icon: ExerciseIcon, style: { color: "var(--primary)" }, onClick: () => navigate(`/tests/${test.id}`),
      })
    }

    return actions
  }, [test])

  const deleteTest = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/admin/tests/${test.id}`)
      onDelete(test.id)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [test, onDelete])

  const completeTest = useCallback(async () => {
    try {
      setCompleteAlertStatus(DialogStatus.Loading)
      await api.put(`/admin/tests/${test.id}`, { status: TestStatus.Completed })
      onComplete(test.id)
      setCompleteAlertStatus(DialogStatus.Success)
      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setCompleteAlertStatus(DialogStatus.Error)

      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [test, onComplete])

  return (
    <div className={test?.deleted_at && !loading ? `${styles.container} ${styles.deleted}` : styles.container}
      style={style}>
      {
        loading === true &&
        <div className={styles.content}
          onClick={() => navigate(`/tests/${test.id}`)}
        >
          <div className={styles.teacher} >
            <Skeleton type="circle" width="48px" height="48px" />
            <div className={styles.column}>
              <Skeleton type="rect" width="146px" height="14px" borderRadius="8px" />
              <Skeleton type="rect" width="196px" height="14px" borderRadius="8px" />
            </div>
          </div>
          <div className={styles.info}>
            <Skeleton type="rect" width="256px" height="14px" borderRadius="8px" />
            <Skeleton type="rect" width="148px" height="14px" borderRadius="8px" />
          </div>
          <div className={styles.right} style={{ justifyContent: "flex-end" }}>
            <Skeleton type="rect" width="64px" height="20px" borderRadius="4px" />
          </div>
        </div>
      }

      {
        !loading &&
        <div className={styles.content}
          onClick={() => navigate(`/tests/${test.id}`)}
        >
          <div className={styles.teacher} >
            <img className={styles.picture} src={test.teacher.picture ?? UserPlaceholder} alt=""
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/users/teachers/${test.teacher.id}`)
              }}
            />
            <div className={styles.column}>
              <TooltipWrapper text={test.description}>
                <div className={styles.name}>{test.name}</div>
              </TooltipWrapper>
              <div className={styles.teacherName}
                onClick={() => navigate(`/users/teachers/${test.teacher.id}`)}
              >{test.teacher.name} {test.teacher.surname}</div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.value} style={{ maxWidth: "320px" }}>
              {`${test.course.name} - ${test.edition.name}`} - {test.module.name}
              {
                test.lesson &&
                ` - ${test.lesson?.name ?? "Nessuna lezione"}`
              }
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.column}>
              <TooltipWrapper text="Data di scadenza">
                <div className={styles.questions}>
                  <CalendarIcon />
                  {
                    test.expires_at &&
                    `${formatDateV2(test.expires_at)} ${formatTimeV2(test.expires_at)}`
                  }
                  {
                    !test.expires_at && "Nessuna scadenza"
                  }

                </div>
              </TooltipWrapper>
              <TooltipWrapper text="Numero di domande">
                <div className={styles.questions}><QuestionsIcon /> {numberOfTests} {(numberOfTests === 0 || numberOfTests > 1) ? t("tests.questions") : t("tests.question")}</div>
              </TooltipWrapper>
            </div>
            <div className={styles.column}>
              <TooltipWrapper text="Soglia di successo">
                <div className={styles.questions}><TrophyIcon /> {test.success_threshold ?? 0}/{numberOfTests}</div>
              </TooltipWrapper>
              <TooltipWrapper text="Ripetibile">
                <div className={styles.questions}><RepeatIcon /> {test.canBeRetried ? t("yes") : t("no")}</div>
              </TooltipWrapper>

            </div>
            <div className={styles.status} style={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
              {status &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": status.color }}>{status.label.toUpperCase()}</div>
                </div>
              }
              {test.publishable && test.status === TestStatus.Draft &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--tertiary)" }}>{t("tests.publishable").toUpperCase()}</div>
                </div>
              }
              {!test.publishable && test.status === TestStatus.Draft &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--secondary)" }}>{t("tests.toBeCompleted").toUpperCase()}</div>
                </div>
              }
              {test.status === TestStatus.Public && (!test.expires_at || (test.expires_at && new Date(test.expires_at) > new Date())) &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--sf-red)" }}>{"IN CORSO"}</div>
                </div>
              }
              {test.status === TestStatus.Public && (test.expires_at && new Date(test.expires_at) < new Date()) &&
                <div className={styles.badges}>
                  <div className={styles.statusBadge} style={{ "--color": "var(--sf-orange)" }}>{"SCADUTO"}</div>
                </div>
              }
            </div>
            <MeatBallsMenu actions={menuActions} />
          </div>
        </div>
      }

      <AlertDialog
        open={openAlert}
        title={t("tests.deleteTitle")}
        text={t("tests.deleteText")}
        status={alertStatus}
        onClose={() => {
          setOpenAlert(false)
          setAlertStatus(DialogStatus.Default)
          if (closeTimeout) {
            clearTimeout(closeTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.deleteConfirm").toUpperCase(),
            onClick: deleteTest,
          }
        ]}
      />
      <AlertDialog
        open={openCompleteAlert}
        title={t("tests.completeTitle")}
        text={t("tests.completeText")}
        status={completeAlertStatus}
        onClose={() => {
          setOpenCompleteAlert(false)
          setCompleteAlertStatus(DialogStatus.Default)
          if (completeCloseTimeout) {
            clearTimeout(completeCloseTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.completeConfirm").toUpperCase(),
            onClick: completeTest,
          }
        ]}
      />
    </div >
  )
}

export default TestCard
