import { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { Each } from "../common/Each"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from '../components/Skeleton'
import typo from "../typography.module.css"
import styles from "./Surveys.module.css"

const Surveys = ({ }) => {

    const [surveys, setSurveys] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const navigate = useNavigate()

    useEffect(() => {
        const getSurveys = async () => {
            setLoading(true)
            try {
                const data = await api.get(`/admin/surveys?page=${page}&per=${per}`)
                setSurveys(data.surveys)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        getSurveys()
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Sondaggio</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            Sondaggi
                        </div>
                        <div className={styles.surveys}>
                            {
                                !loading &&
                                <Each
                                    of={surveys}
                                    render={survey => (
                                        <button className={styles.survey} onClick={() => navigate(`/surveys/${survey.id}`)}>
                                            <div className={typo.caption}>{survey.created_at}</div>
                                            <div className={styles.surveyContent}>
                                                <div className={styles.labelled}>
                                                    <div className={styles.label}>Titolo</div>
                                                    <div className={styles.value}>{survey.description}</div>
                                                </div>
                                                <div className={styles.labelled}>
                                                    <div className={styles.label}>Completati</div>
                                                    <div className={styles.value} style={{ textAlign: "center" }}>{
                                                        survey.users_survey.filter(s => s.content && s.content.length > 0).length
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    )}
                                />
                            }
                            {
                                loading &&
                                <Each
                                    of={[0, 1, 2, 3, 4]}
                                    render={() => (
                                        <button disabled className={styles.survey} style={{ cursor: "default", pointerEvents: "none" }}>
                                            <div className={styles.surveyContent}>
                                                <div className={styles.labelled} style={{ flexGrow: 1 }}>
                                                    <Skeleton type="rect" width="144px" height="20px" borderRadius="8px" />
                                                    <Skeleton type="rect" width={`50%`} height="16px" borderRadius="8px" />
                                                </div>
                                                <div className={styles.labelled}>
                                                    <Skeleton type="rect" width="96px" height="16px" borderRadius="8px" />
                                                    <div className={styles.value} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Skeleton type="rect" width="28px" height="16px" borderRadius="8px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    )}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Surveys