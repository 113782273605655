import { useMemo, useState } from "react"
import { renderToString } from "react-dom/server"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { SurveyType } from "../../common/constants"
import { Each } from "../../common/Each"
import SimpleCollapsable from "../SimpleCollapsable"
import TextInput from "../TextInput"
import TooltipWrapper from '../TooltipWrapper'
import styles from "./SurveyQuestion.module.css"
import ReactMarkdown from "react-markdown"

const SurveyQuestion = ({ question, surveyId }) => {

    const [expandedId, setExpandedId] = useState(null)
    const [filteredStudents, setFilteredStudents] = useState([])
    const navigate = useNavigate()

    const MAX_STUDENTS = useMemo(() => 16, [])

    const parseSingleChoice = (question) => {
        let res = []
        let nAnswers = 0
        for (let i = 0; i < question.options.length; i++) {
            const a = question.answers?.filter(a => a.id === i)
            if (a) {
                nAnswers += a.length
                res.push({ ...question.options[i], answers: a })
            } else {
                res.push({ ...question.options[i], answers: [] })
            }
        }
        res = res.map(o => ({ ...o, percentage: nAnswers > 0 ? Math.round(o.answers.length / nAnswers * 100 * 100) / 100 : 0 }))
        const maxPercentage = Math.max(...res.map(o => o.percentage))
        res = res.map(o => ({ ...o, isMax: o.percentage === maxPercentage }))
        return res
    }

    const parseMultipleChoice = (question) => {
        let res = []
        let nAnswers = 0
        for (let i = 0; i < question.options.length; i++) {
            const a = []
            if (question.answers) {
                for (const userAnswer of question.answers) {
                    const { answers, ...user } = userAnswer
                    for (const answer of answers) {
                        if (answer.id === i) {
                            a.push({ ...answer, ...user })
                        }
                    }
                }
                nAnswers += a.length
                res.push({ ...question.options[i], answers: a })
            } else {
                res.push({ ...question.options[i], answers: [] })
            }
        }
        res = res.map(o => ({ ...o, percentage: nAnswers ? Math.round(o.answers.length / nAnswers * 100 * 100) / 100 : 0 }))
        const maxPercentage = Math.max(...res.map(o => o.percentage))
        res = res.map(o => ({ ...o, isMax: o.percentage === maxPercentage }))
        return res
    }

    const parseNumeric = (question) => {
        let res = []
        let nAnswers = 0
        const { min, max, step } = question
        for (let i = min; i <= max; i += step) {
            const a = question.answers?.filter(a => a.id === i)
            if (a) {
                nAnswers += a.length
                res.push({ value: i, answers: a })
            } else {
                res.push({ value: i, answers: [] })
            }

        }
        res = res.map(o => ({ ...o, percentage: nAnswers ? Math.round(o.answers.length / nAnswers * 100 * 100) / 100 : 0 }))
        const maxPercentage = Math.max(...res.map(o => o.percentage))
        res = res.map(o => ({ ...o, isMax: o.percentage === maxPercentage }))
        return res
    }

    const optionsWithAnswers = useMemo(() => {
        if (!question) {
            return []
        }
        switch (question.type) {
            case SurveyType.SingleChoice: return parseSingleChoice(question);
            case SurveyType.MultipleChoice: return parseMultipleChoice(question);
            case SurveyType.Numeric: return parseNumeric(question);
            default: break;
        }
    }, [question])

    const tooltipContent = useMemo(() => {
        if (filteredStudents.length > MAX_STUDENTS) {
            const data = filteredStudents.slice(MAX_STUDENTS, filteredStudents.length)
            const html = renderToString((
                <div className={styles.tooltip}>
                    {data.map((u, i) => (
                        <div key={i}>{u.name} {u.surname}</div>
                    ))}
                </div>
            ))
            return {
                html,
                count: data.length
            }
        }
    }, [filteredStudents])

    const onSearch = (searchText, index) => {
        if (searchText) {
            const users = optionsWithAnswers[index].answers.filter(u => `${u.name} ${u.surname}`.includes(searchText))
            setFilteredStudents(users)
        } else {
            setFilteredStudents(optionsWithAnswers[index].answers)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{question.text}</div>
            <Each
                of={optionsWithAnswers}
                render={(option, index) => (
                    <div className={styles.answerWrapper}>
                        <div className={styles.answerContainer}>
                            <div className={styles.answerSection}>
                                <div className={styles.answer}>
                                <ReactMarkdown>{`${option.value}`}</ReactMarkdown>
                                </div>
                            </div>
                            <div className={styles.stats}>
                                <div className={styles.percentage}>{option.percentage} %</div>
                                <div className={styles.barContainer}>
                                    <div className={styles.bar}
                                        style={{
                                            width: `${option.percentage}%`,
                                            backgroundColor: option.isMax ? "var(--primary)" : "rgba(var(--text-color-rgb), 32%);"
                                        }}
                                    />
                                </div>
                            </div>
                            <button
                                disabled={option.answers.length === 0}
                                className={expandedId === index ? `${styles.chevron} ${styles.expanded}` : styles.chevron}
                                onClick={() => {
                                    if (expandedId === index) {
                                        setExpandedId(null)
                                    } else {
                                        setExpandedId(index)
                                        setFilteredStudents(optionsWithAnswers[index].answers)
                                    }
                                }}
                            >
                                <ChevronIcon style={{
                                    transform: `rotate(${expandedId === index ? "0deg" : "180deg"})`,
                                }} />
                            </button>
                        </div>
                        <SimpleCollapsable expanded={expandedId === index}>
                            <div className={styles.students}>
                                <TextInput
                                    onKeyUp={(searchText) => onSearch(searchText, index)}
                                    type="search"
                                    placeholder={"Cerca"}
                                    style={{
                                        backgroundColor: "var(--background-secondary-color)",

                                    }} />
                                {
                                    expandedId === index &&
                                    <Each
                                        of={filteredStudents.slice(0, MAX_STUDENTS)}
                                        render={u => (
                                            <button className={styles.student}
                                                onClick={() => navigate(`/surveys/${surveyId}/students/${u.userId}`)}
                                            >
                                                <img src={u.picture ?? UserPlaceholder} alt="" onError={({ currentTarget }) => currentTarget.src = UserPlaceholder} />
                                                <div className={styles.studentContent}>
                                                    <div>{u.name} {u.surname}</div>
                                                    <div className={styles.studentResponse}>{u.answerValue}</div>
                                                </div>
                                            </button>
                                        )}
                                    />
                                }
                                {
                                    filteredStudents.length > MAX_STUDENTS &&
                                    <TooltipWrapper html={tooltipContent.html}>
                                        <div className={styles.student} style={{ fontWeight: 700, padding: "1.25rem", fontSize: "1.125rem" }}>
                                            +{tooltipContent.count}
                                        </div>
                                    </TooltipWrapper>
                                }
                            </div>
                        </SimpleCollapsable>
                    </div>
                )}
            />
        </div>
    )

}

export default SurveyQuestion