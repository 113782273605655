import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { ButtonStatus, FileType } from '../common/constants';
import Back from '../components/Back';
import Button from "../components/Button";
import Card from '../components/cards/Card';
import HeaderFooterLayout from '../components/layouts/HeaderFooterLayout';
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import Uploader from '../components/Uploader';
import typo from "../typography.module.css";
import styles from "./VideoEditor.module.css";

const VideoEditor = ({ }) => {
  const { video_id } = useParams()
  const navigate = useNavigate()
  const [video, setVideo] = useState(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [slug, setSlug] = useState("")
  const [tags, setTags] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [tab, setTab] = useState(0)

  const [thumbnail, setThumbnail] = useState(null)
  const [uploaderThumbnailReset, setUploaderThumbnailReset] = useState(null)

  const [url, setUrl] = useState("")
  const [uploaderVideoReset, setUploaderVideoReset] = useState(null)

  const [publishing, setPublishing] = useState(null)
  const mdRef = useRef(null)

  useEffect(() => {
    const getVideo = async () => {
      try {
        const video = await api.get(`/admin/videos/${video_id}`)
        setVideo(video)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (video_id !== "create") {
      getVideo()
    }
  }, []);


  useEffect(() => {
    const getTags = async () => {
      try {
        const tags = await api.get("/admin/tags?type=general")
        setFilteredTags(tags)
      }
      catch (e) {
        console.error(e)
      }
    }

    getTags()
  }, [])

  useEffect(() => {
    if (video) {
      setTitle(video.title)
      setDescription(video.description)
      mdRef.current?.setMarkdown(video.description)
      setUrl(video.url)
      setThumbnail(video.thumbnail)
      setSlug(video.slug)
      setTags(video.tags)
    }

  }, [video])

  const saveVideo = async () => {
    try {
      setPublishing(ButtonStatus.Loading)
      const newVideo = {
        title,
        slug,
        description,
        url,
        thumbnail,
        tags: JSON.stringify(tags.map(t => t.id)),
      }
      const res = video_id === "create" ?
        await api.post("/admin/videos", newVideo) :
        await api.put(`/admin/videos/${video_id}`, newVideo)

      setPublishing(ButtonStatus.Success)
      setUploaderThumbnailReset(o => !o)
      setUploaderVideoReset(o => !o)
      if (video_id === "create") {
        navigate(`/videos/${res.id}`)
      }
    } catch (e) {
      setPublishing(ButtonStatus.Error)

      console.error(e)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Editor video</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {video_id === "create" ? "Aggiungi video" : "Modifica video"}
              </div>
            </div>
            <Card>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.left}>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TITOLO</div>
                      <TextInput
                        type="text"
                        value={title}
                        placeholder={"Titolo"}
                        onKeyUp={(v) => {
                          setTitle(v)
                          const slug = v?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                            .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                            .toLowerCase()
                            .trim();
                          setSlug(slug)
                        }
                        }
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>SLUG</div>
                      <TextInput
                        type="text"
                        value={slug}
                        placeholder={"Slug"}
                        onKeyUp={(v) => setSlug(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TAG</div>
                      <TagSelector
                        selected={tags}
                        options={filteredTags}
                        placeholder={(tags.length === 0 && "Seleziona un tag") || ""}
                        style={{ fontSize: "1rem" }}
                        onAdd={(item) => {
                          setTags(t => {
                            t.push(item)
                            return [...t]
                          })
                        }}
                        onRemove={(item) => {
                          setTags(t => {
                            t = t.filter((tt) => tt.id !== item.id)
                            return [...t]
                          })
                        }}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL THUMBNAIL"}</div>
                      <TextInput
                        type="text"
                        value={thumbnail}
                        placeholder={"Url thumbnail"}
                        onKeyUp={(v) => setThumbnail(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL VIDEO"}</div>
                      <TextInput
                        type="text"
                        value={url}
                        placeholder={"Url video"}
                        onKeyUp={(v) => setUrl(v)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.switch}>
                      <div
                        className={tab === 0 ? `${styles.tab}` : `${styles.tab} ${styles.notSelected}`}
                        onClick={() => setTab(0)}
                      >
                        Thumbnail
                      </div>
                      <div
                        className={tab === 1 ? `${styles.tab}` : `${styles.tab} ${styles.notSelected}`}
                        onClick={() => setTab(1)}
                      >
                        Video
                      </div>
                    </div>
                    <div className={styles.mediaContainer}>
                      {
                        tab === 0 &&
                        <Uploader
                          preview={thumbnail}
                          reset={uploaderThumbnailReset}
                          multiple={false}
                          type={FileType.Image}
                          onComplete={uploads => setThumbnail(uploads[0].url)}
                          onDelete={() => setThumbnail("")}
                        />
                      }
                      {
                        tab === 1 &&
                        <Uploader
                          preview={url}
                          reset={uploaderVideoReset}
                          multiple={false}
                          type={FileType.Video}
                          onComplete={uploads => setUrl(uploads[0].url)}
                          onDelete={() => setUrl("")}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.editor}>
                  <MDXEditor
                    ref={mdRef}
                    contentEditableClassName={styles.markdownEditor}
                    markdown={description}
                    placeholder="Descrizione"
                    onChange={(value) => { setDescription(value) }}
                    plugins={[
                      headingsPlugin(),
                      listsPlugin(),
                      quotePlugin(),
                      thematicBreakPlugin(),
                      linkDialogPlugin(),
                      diffSourcePlugin(),
                      tablePlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <Separator />
                            <BoldItalicUnderlineToggles />
                            <Separator />
                            <ListsToggle />
                            <CreateLink />
                            <InsertTable />
                            <Separator />
                            <BlockTypeSelect />
                            <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                          </>
                        )
                      })
                    ]}
                  />
                </div>
                <div className={styles.action}>
                  <Button
                    status={publishing}
                    disabled={!title || !slug || !url}
                    style={{ padding: "0.75rem 2rem" }}
                    onClick={saveVideo}
                  >
                    {video_id === "create" ? "PUBBLICA" : "SALVA"}
                  </Button>
                </div>
              </div>

            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default VideoEditor
