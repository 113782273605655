import '@mdxeditor/editor/style.css';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import api from "../api";
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg";
import { FileType, LessonType, Role, TagType } from "../common/constants";
import { Each } from "../common/Each";
import Back from "../components/Back";
import Button from "../components/Button";
import Card from "../components/cards/Card";
import ModuleLessonCard from "../components/cards/ModuleLessonCard";
import TeacherCard from "../components/cards/TeacherCard";
import AlertDialog from "../components/dialogs/AlertDialog";
import Dialog from "../components/dialogs/Dialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import LessonEditor from "../components/LessonEditor";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import Uploader from '../components/Uploader';
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import styles from "./ModuleEditor.module.css";

const ModuleEditor = () => {

    const navigate = useNavigate()
    const [course, setCourse] = useState(null)
    const [edition, setEdition] = useState(null)
    const [module, setModule] = useState(null)
    const { courseId, editionId, moduleId } = useParams()
    const [tags, setTags] = useState(null)
    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })
    const [loading, setLoading] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [teachers, setTeachers] = useState(null)
    const [teacherDialog, setTeacherDialog] = useState(false)
    const [tutorDialog, setTutorDialog] = useState(false)
    const [tutors, setTutors] = useState(null)
    const [lesson, setLesson] = useState(null)
    const [uploaderReset, setUploaderReset] = useState(false)

    useEffect(() => {
        const getModule = async (module_id) => {
            try {
                let module = await api.get(`/admin/modules/${module_id}`)
                setModule(module)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (moduleId && moduleId !== 'create') {
            getModule(moduleId)
        }
        if (moduleId && moduleId === 'create') {
            setModule(
                {
                    name: '',
                    description: '',
                    thumbnail: '',
                    duration: 0,
                    tags: [],
                    teachers: [],
                    tutors: [],
                    lessons: []
                }
            )
        }
    }, [moduleId])

    useEffect(() => {
        const getCourse = async () => {
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (courseId && !course) {
            getCourse()
        }
    }, [courseId])

    useEffect(() => {
        const getEdition = async () => {
            try {
                let edition = await api.get(`/admin/editions/${editionId}`)
                setEdition(edition)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (editionId && !edition) {
            getEdition()
        }
    }, [editionId])

    useEffect(() => {
        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=all")
                tags = tags.filter(t => {
                    return !module.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getTeachers = async () => {
            try {
                let data = await api.get("/admin/teachers")

                const teachers = data.filter(t => t.role === Role.Teacher).map(teacher => {
                    teacher.selected = !!module.teachers.find(t => t.id === teacher.id)
                    return teacher
                })
                const tutors = data.filter(t => t.role === Role.Tutor).map(tutor => {
                    tutor.selected = !!module.tutors.find(t => t.id === tutor.id)
                    return tutor
                })

                setTeachers(teachers)
                setTutors(tutors)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (module && !tags) {
            getTags()
        }

        if (module && !teachers) {
            getTeachers()
        }

        if (module) {
            setSaveDisabled(module.name.trim() === '' || module.description.trim() === '' || !module.thumbnail  || module.thumbnail.trim() === '' )
        }
        if (module && !module.thumbnail && moduleId === 'create') {
            setModule((prev) => {
                prev.thumbnail = "https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/placeholders/module_placeholder.png"
                return { ...prev }
            })
        }
    }, [module])

    const save = async () => {
        setLoading(true)
        try {

            const body = {
                edition_id: editionId,
                name: module.name,
                description: module.description,
                duration: module.duration,
                thumbnail: module.thumbnail,
                tags: JSON.stringify(module.tags.map(t => t.id)),
                teachers: JSON.stringify(
                    [...module.teachers.map(t => t.id), ...module.tutors.map(t => t.id)]
                )
            }

            const response = (moduleId === "create") ?
                await api.post(`/admin/modules`, body) :
                await api.put(`/admin/modules/${moduleId}`, body)


            if (moduleId === "create") {
                navigate(`/courses/${courseId}/editions/${editionId}/modules/${response.id}`)
                return;
            }
            setModule(response)
            setUploaderReset(o => !o)
            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })

            setUploaderReset(!uploaderReset)
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const [lessonSortingDesc, setLessonSortingDesc] = useState(false)

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                {module &&

                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <Back onClick={() => { navigate(`/courses/${courseId}/editions/${editionId}`) }} />
                            {course && edition &&
                                <div className={`${typo.title} ${styles.navigation}`}>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}`)
                                    }}>{course.name}</div>
                                    <div>{">"}</div>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}/editions/${editionId}`)
                                    }}>{edition.name}</div>
                                    <div>{">"}</div>
                                    <div>{module.name}</div>
                                </div>
                            }
                            <Card>
                                <div className={styles.cardInner}>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>NOME</div>
                                                <TextInput type="text" value={module.name} placeholder="Nome" onKeyUp={(value) => {
                                                    setModule((prev) => {
                                                        prev.name = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DESCRIZIONE</div>
                                                <TextareaAutosize
                                                    value={module.description}
                                                    minRows={5}
                                                    maxRows={10}
                                                    type="text"
                                                    className={styles.textArea}
                                                    placeholder="Descrizione"
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        setModule((prev) => {
                                                            prev.description = value
                                                            return { ...prev }
                                                        })
                                                    }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>TAG</div>
                                                {tags &&
                                                    <TagSelector
                                                        selected={module.tags.filter(t => t.type === TagType.General)}
                                                        options={tags.filter(t => t.type === TagType.General)}
                                                        placeholder={"Cerca tag"}
                                                        onAdd={(item) => {
                                                            const newOptions = tags.filter(t => t.id !== item.id)
                                                            setTags([...newOptions])

                                                            setModule((prev) => {
                                                                if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                                    prev.tags.push(item)
                                                                }
                                                                return { ...prev }
                                                            })
                                                        }}
                                                        onRemove={(item) => {
                                                            const newOptions = [...tags]
                                                            if (!newOptions.map(t => t.id).includes(item.id)) {
                                                                newOptions.push(item)
                                                            }
                                                            setTags([...newOptions])

                                                            setModule((prev) => {
                                                                prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                                return { ...prev }
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DURATA</div>
                                                <div className={typo.body}><strong>{module.duration}h</strong></div>
                                                <div className={typo.caption}>La durata di un modulo è calcolata automaticamente sulla base delle lezioni contenute nel modulo.</div>
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>THUMBNAIL</div>
                                                <TextInput disabled={module?.thumbnail?.startsWith('blob:')} type="text" value={module?.thumbnail} placeholder="Thumbnail URL" onKeyUp={(value) => {
                                                    setModule((prev) => {
                                                        prev.thumbnail = value ? value : ''
                                                        return { ...prev }
                                                    })
                                                }} />

                                                <Uploader
                                                    crop={true}
                                                    preview={module?.thumbnail}
                                                    reset={uploaderReset}
                                                    multiple={false}
                                                    type={FileType.Image}
                                                    onComplete={(uploads) => {
                                                        setModule((prev) => {
                                                            prev.thumbnail = uploads[0].url
                                                            return { ...prev }
                                                        })
                                                    }}
                                                    onDelete={() => {
                                                        setModule((prev) => {
                                                            prev.thumbnail = ""
                                                            return { ...prev }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            {edition && course &&
                                <>
                                    <div className={[typo.subtitle, styles.header].join(' ')}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '.5rem', alignItems: 'center' }}>Lezioni • {module.lessons.length} <ChevronIcon className={`${styles.chevronIcon} ${lessonSortingDesc ? styles.descending : ''}`} onClick={() => {
                                            setLessonSortingDesc(!lessonSortingDesc)
                                        }} /></div>
                                        <div className={styles.lessonCreateContainer}>
                                            {
                                                moduleId === "create" &&
                                                <div className={typo.caption}>Crea il modulo prima</div>
                                            }
                                            <Button
                                                disabled={moduleId === "create"}
                                                onClick={() => {
                                                    setLesson({ name: '', type: LessonType.Live, description: '', thumbnail: module.thumbnail, tags: [], module_id: moduleId })
                                                }}>
                                                NUOVA
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles.lessons}>
                                        <Each of={module.lessons.sort((a, b) => {
                                            if (lessonSortingDesc) {
                                                return new Date(b.starts_at) - new Date(a.starts_at)
                                            }
                                            return new Date(a.starts_at) - new Date(b.starts_at)
                                        })} render={(lesson) => {
                                            edition.course = course
                                            lesson.edition = edition
                                            return (
                                                <div className={styles.teacher}>
                                                    <ModuleLessonCard lesson={lesson} onEdit={() => {
                                                        setLesson(lesson)
                                                    }} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </>
                            }

                            <div className={[typo.subtitle, styles.header].join(' ')}>
                                Docenti • {module.teachers.length}
                                <Button onClick={() => {
                                    setTeacherDialog(true)
                                }}>
                                    MODIFICA
                                </Button>
                            </div>
                            <div className={styles.teachers}>
                                <Each of={module.teachers} render={(teacher) => {
                                    return (
                                        <div className={styles.teacher}>
                                            <TeacherCard style={{ width: '100%' }} teacher={teacher} onClick={() => navigate(`/users/teachers/${teacher.id}`)} />
                                        </div>
                                    )
                                }} />
                            </div>
                            <div className={[typo.subtitle, styles.header].join(' ')}>
                                Tutor • {module.tutors.length}
                                <Button onClick={() => {
                                    setTutorDialog(true)
                                }}>
                                    MODIFICA
                                </Button>
                            </div>
                            <div className={styles.teachers}>
                                <Each of={module.tutors} render={(teacher) => {
                                    return (
                                        <div className={styles.teacher}>
                                            <TeacherCard style={{ width: '100%' }} teacher={teacher} onClick={() => navigate(`/users/tutors/${teacher.id}`)} />
                                        </div>
                                    )
                                }} />
                            </div>
                        </div>
                    </div>
                }
            </div >
            <div className={styles.bottomBar}>
                <Button
                    disabled={loading || saveDisabled}
                    onClick={() => {
                        save()
                    }}>
                    {moduleId === 'create' ? "SALVA" : "SALVA MODIFICHE"}
                </Button>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }}
            />

            {teachers &&
                <Dialog
                    open={teacherDialog}
                    title={"Seleziona Docenti"}
                    onClose={() => {
                        setTeacherDialog(false)
                    }}
                >
                    <div className={styles.teachers}>
                        <Each of={teachers.sort((t1, t2) => { return (t2.selected === true) - (t1.selected === true) })} render={(teacher) => {
                            return (
                                <div className={`${styles.teacherDialogCell} ${teacher.selected ? styles.selected : ''}`}>
                                    <TeacherCard
                                        teacher={teacher}
                                        options={{
                                            selected: teacher.selected,
                                            chevron: false,
                                            status: false,
                                            tags: false
                                        }}
                                        onClick={() => {
                                            let newTeachers = teachers.map(t => {
                                                if (t.id === teacher.id) {
                                                    t.selected = !t.selected
                                                }
                                                return t
                                            })
                                            setModule((prev) => {
                                                if (prev.teachers.map(t => t.id).includes(teacher.id)) {
                                                    prev.teachers = prev.teachers.filter(t => t.id !== teacher.id)
                                                }
                                                else {
                                                    prev.teachers = [...prev.teachers, teacher]
                                                }

                                                return { ...prev }
                                            })
                                            setTeachers(newTeachers)
                                        }}
                                    />
                                </div>
                            )
                        }} />
                    </div>
                </Dialog>
            }
            <Dialog
                open={tutorDialog}
                title={"Seleziona tutor"}
                onClose={() => {
                    setTutorDialog(false)
                }}
            >
                <div className={styles.teachers}>
                    <Each of={tutors} render={(tutor) => {
                        return (
                            <div className={`${styles.teacherDialogCell} ${tutor.selected ? styles.selected : ''}`}>
                                <TeacherCard
                                    teacher={tutor}
                                    options={{
                                        selected: tutor.selected,
                                        chevron: false,
                                        status: false,
                                        tags: false
                                    }}
                                    onClick={() => {
                                        let newTutors = tutors.map(t => {
                                            if (t.id === tutor.id) {
                                                t.selected = !t.selected
                                            }
                                            return t
                                        })
                                        setModule((prev) => {
                                            if (prev.tutors.map(t => t.id).includes(tutor.id)) {
                                                prev.tutors = prev.tutors.filter(t => t.id !== tutor.id)
                                            }
                                            else {
                                                prev.tutors = [...prev.tutors, tutor]
                                            }

                                            return { ...prev }
                                        })
                                        setTutors(newTutors)
                                    }}
                                />
                            </div>
                        )
                    }} />
                </div>
            </Dialog>
            <Dialog
                open={lesson}
                title={lesson?.id ? lesson.name : 'Nuova Lezione'}
                style={{ maxWidth: "60vw", minWidth: "400px", width: "100%" }}
                onClose={() => {
                    setLesson(null)
                }}>
                <LessonEditor lesson={lesson} onSave={(res) => {
                    if (module.lessons.map(l => l.id).includes(lesson.id)) {
                        setModule((prev) => {
                            prev.duration = 0
                            for(let l of prev.lessons){
                                if(l.id !== res.id){
                                prev.duration += (new Date(l.ends_at) - new Date(l.starts_at)) / 3600000; // Convert milliseconds to hours
                                }
                                else{
                                    prev.duration += (new Date(res.ends_at) - new Date(res.starts_at)) / 3600000; // Convert milliseconds to hours
                                }
                            }
                            prev.lessons = prev.lessons.map(l => {
                                if (l.id === res.id) {
                                    l = res
                                }
                                return l
                            })
                            return { ...prev }
                        })
                    }
                    else {
                        setModule((prev) => {
                            prev.lessons = [...prev.lessons, res]
                            prev.duration = prev.lessons.reduce((acc, l) => {
                                return acc + (new Date(l.ends_at) - new Date(l.starts_at)) / 3600000; // Convert milliseconds to hours
                            }, 0)
                            return { ...prev }
                        })
                    }
                    setLesson(null)
                }} />
            </Dialog>


        </HeaderFooterLayout >
    )

}

export default ModuleEditor
