import {
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  headingsPlugin,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png";
import { ButtonStatus } from '../common/constants';
import { Each } from '../common/Each';
import Back from '../components/Back';
import Button from "../components/Button";
import Card from '../components/cards/Card';
import ReviewPreview from '../components/cards/ReviewPreview';
import HeaderFooterLayout from '../components/layouts/HeaderFooterLayout';
import Select from '../components/Select';
import SimpleCollapsable from "../components/SimpleCollapsable";
import Spinner from '../components/Spinner';
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import styles from "./ReviewEditor.module.css";

const ReviewEditor = ({ }) => {
  const platforms = useMemo(() => (
    [{ id: 0, name: "TrustPilot", value: "trustpilot" }]
  ), [])

  const { review_id } = useParams()
  const navigate = useNavigate()
  const [review, setReview] = useState(null)
  const [url, setUrl] = useState(null)
  const [rating, setRating] = useState(null)
  const [message, setMessage] = useState("")
  const [username, setUsername] = useState(null)
  const [userAvatar, setUserAvatar] = useState(null)
  const [user, setUser] = useState(null)
  const [platform, setPlatform] = useState(platforms[0])
  const [users, setUsers] = useState([])
  const [media, setMedia] = useState(null)
  const [mediaType, setMediaType] = useState(null)
  const [usersLoading, setUsersLoading] = useState(false)
  const [userSearchText, setUserSearchText] = useState(null)
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState(null)
  const [publishing, setPublishing] = useState(null)

  const mdRef = useRef(null)


  const mediaTypes = useMemo(() => (
    [
      { id: 0, name: "Immagine", value: "image" },
      { id: 1, name: "Video", value: "video" },
    ]
  ), [])

  useEffect(() => {
    const getCourses = async () => {
      try {
        const courses = await api.get(`/admin/courses`)
        setCourses(courses)
      } catch (e) {
        console.error(e)
      }
    }

    const getReview = async () => {
      try {
        const review = await api.get(`/admin/reviews/${review_id}`)
        setReview(review)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (review_id !== "create") {
      getReview()
    }
    getCourses()
  }, []);


  useEffect(() => {
    if (review) {
      mdRef.current?.setMarkdown(review?.message ?? "")
      setMessage(review.message)
      setRating(review.rating)
      setMedia(review.media)
      setMediaType(mediaTypes.find(m => m.value === review.media_type))
      setUsername(review.user_name)
      setUserAvatar(review.user_avatar)
      setUrl(review.url)
      setPlatform(platforms.find(p => p.value === review.platform))
      setUser(review.user)
      setCourse(review.course)
    }

  }, [review])

  const saveReview = async () => {
    try {
      setPublishing(ButtonStatus.Loading)

      const newReview = {
        rating,
        message,
        media,
        media_type: mediaType?.value,
        user_name: username,
        user_avatar: userAvatar,
        url,
        platform: platform?.value,
        user_id: user?.id,
        course_id: course?.id
      }

      const res = review_id === "create" ?
        await api.post("/admin/reviews", newReview) :
        await api.put(`/admin/reviews/${review_id}`, newReview)

      setPublishing(ButtonStatus.Success)

      if (review_id === "create") {
        navigate(`/reviews`)
      } else {
        setReview(res)
      }
    } catch (e) {
      setPublishing(ButtonStatus.Error)

      console.error(e)
    }
  }

  const getUsers = async (q = null) => {
    setUsersLoading(true)
    try {
      const data = await api.get(`/admin/users?q=${q}`)
      const { users, count } = data
      setUsers(users)
    } catch (e) {
      console.error(e)
    }
    setUsersLoading(false)
  }

  const onSearch = (searchText) => {
    setUserSearchText(searchText)
    if (searchText) {
      getUsers(searchText)
    } else {
      setUsers([])
    }

  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Editor Recensione</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {review_id === "create" ? "Aggiungi recensione" : "Modifica recensione"}
              </div>
            </div>
            {/* <ReviewPreview
              
            /> */}
            <Card>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.left}>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Username</div>
                      <TextInput
                        type="text"
                        value={username}
                        placeholder={"Username"}
                        onKeyUp={v => setUsername(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Url avatar</div>
                      <TextInput
                        type="text"
                        value={userAvatar}
                        placeholder={"Url avatar"}
                        onKeyUp={v => setUserAvatar(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Associa utente Velv</div>
                      {
                        !user &&
                        <>
                          <TextInput
                            type="search"
                            value={userAvatar}
                            placeholder={"Cerca..."}
                            onKeyUp={onSearch}
                          />
                          <SimpleCollapsable expanded={users.length > 0 || usersLoading || userSearchText}>
                            <div className={styles.users}>
                              {
                                !usersLoading && users.length > 0 &&
                                <Each
                                  of={users}
                                  render={user => (
                                    <div className={styles.user}
                                      onClick={() => {
                                        setUsername(`${user.name} ${user.surname}`)
                                        setUser(user);
                                        setUsers([])
                                      }}
                                    >
                                      <img className={styles.picture} src={user.picture ?? UserPlaceholder} alt="" onError={() => {
                                        setUsers(u => {
                                          const idx = u.findIndex(uu => uu.id === user.id)
                                          u[idx].picture = UserPlaceholder
                                          return [...u]
                                        })
                                      }} />
                                      <div className={styles.userInfo}>
                                        <div className={styles.name}>{user.name} {user.surname}</div>
                                        <div className={styles.email}>{user.email}</div>
                                      </div>

                                    </div>
                                  )}
                                />
                              }
                              {
                                usersLoading &&
                                <div className={styles.spinner}>
                                  <Spinner color="var(--primary)" />
                                </div>
                              }
                              {
                                !usersLoading && users.length === 0 &&
                                <div className={styles.noUsers}>Nessun utente trovato</div>
                              }
                            </div>
                          </SimpleCollapsable>
                        </>
                      }
                      {
                        user &&
                        <div className={styles.user} style={{ cursor: "default" }}>
                          <img className={styles.picture} src={user.picture ?? UserPlaceholder} alt="" onError={() => {
                            setUsers(u => {
                              const idx = u.findIndex(uu => uu.id === user.id)
                              u[idx].picture = UserPlaceholder
                              return [...u]
                            })
                          }} />
                          <div className={styles.userInfo}>
                            <div className={styles.name}>{user.name} {user.surname}</div>
                            <div className={styles.email}>{user.email}</div>
                          </div>
                          <div className={styles.userChange}>
                            <CloseIcon onClick={() => {
                              setUser(null)
                              setUsername(null)
                            }} />
                          </div>
                        </div>
                      }

                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Associa un corso</div>
                      <Select nullable selected={course} options={courses} onSelect={async (value) => {
                        setCourse(value)
                      }} />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Rating</div>
                      <TextInput
                        type="number"
                        numberType="float"
                        value={rating}
                        placeholder={"Rating"}
                        min={0}
                        max={5}
                        onKeyUp={(v) => setRating(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Tipo media</div>
                      <Select nullable selected={mediaType} options={mediaTypes} onSelect={async (value) => {
                        setMediaType(value)
                      }} />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Url media</div>
                      <TextInput
                        type="text"
                        value={media}
                        placeholder={"Url media"}
                        onKeyUp={v => setMedia(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Piattaforma</div>
                      <Select nullable selected={platform} options={platforms} onSelect={async (value) => {
                        setPlatform(value)
                      }} />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>Url recensione</div>
                      <TextInput
                        type="text"
                        value={url}
                        placeholder={"Url recensione"}
                        onKeyUp={v => setUrl(v)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.previewContainer}>
                      <ReviewPreview review={{
                        rating,
                        message,
                        media,
                        media_type: mediaType?.value,
                        user_name: username,
                        user_avatar: userAvatar,
                        user,
                        url,
                        platform: platform?.value,
                      }} />
                    </div>
                    <div className={styles.editor}>
                      <MDXEditor
                        ref={mdRef}
                        contentEditableClassName={styles.markdownEditor}
                        markdown={message}
                        placeholder="Message"
                        onChange={(value) => { setMessage(value) }}
                        plugins={[
                          headingsPlugin(),
                          listsPlugin(),
                          quotePlugin(),
                          thematicBreakPlugin(),
                          linkDialogPlugin(),
                          diffSourcePlugin(),
                          tablePlugin(),
                          toolbarPlugin({
                            toolbarContents: () => (
                              <>
                                <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                                <ListsToggle />
                                <CreateLink />
                              </>
                            )
                          })
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.action}>
                  <Button
                    status={publishing}
                    disabled={!message}
                    style={{ padding: "0.75rem 2rem" }}
                    onClick={saveReview}
                  >
                    {review_id === "create" ? "PUBBLICA" : "SALVA"}
                  </Button>
                </div>
              </div>

            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default ReviewEditor
