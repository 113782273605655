import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../../api"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ButtonStatus, FileType } from "../../common/constants"
import { Each } from "../../common/Each"
import useContextMenu from "../../common/hooks/useContextMenu"
import { DialogStatus } from "../../enums"
import Button from "../Button"
import AlertDialog from "../dialogs/AlertDialog"
import TextInput from "../TextInput"
import Uploader from "../Uploader"
import styles from "./PartnersSettings.module.css"

const PartnersSettings = ({ }) => {
    const { t } = useTranslation()
    const [partners, setPartners] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [name, setName] = useState("")
    const [url, setUrl] = useState(null)
    const [addStatus, setAddStatus] = useState(null)
    const [addSuccessTimeout, setAddSuccessTimeout] = useState(null)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
    const [partnerToBeDeleted, setPartnerToBeDeleted] = useState(null)
    const [uploadReset, setUploadReset] = useState(false)
    const { showMenu } = useContextMenu()

    useEffect(() => {
        const getPartners = async () => {
            try {
                const partners = await api.get("/admin/partners")
                setPartners(partners)
                setFilteredPartners(partners)
            } catch (e) {
                console.error(e)
            }
        }
        getPartners()
        return () => {
            if (addSuccessTimeout) {
                clearTimeout(addSuccessTimeout)
            }
        }
    }, [])

    const onSearch = (searchText) => {
        if (!searchText) {
            setFilteredPartners(partners)
        } else {
            setFilteredPartners(partners.filter(p => {
                return p.name.toLowerCase().includes(searchText.toLowerCase())
            }))
        }
    }

    const add = async () => {
        setAddStatus(ButtonStatus.Loading)
        try {
            const body = { name, url }
            const partners = await api.post("/admin/partners", body)
            setPartners(partners)
            setFilteredPartners(partners)
            setUploadReset(!uploadReset)
            setAddStatus(ButtonStatus.Success)
            setUrl(null)
            setName(null)
            setAddSuccessTimeout(
                setTimeout(() => setAddStatus(null), 3000)
            )
        } catch (e) {
            console.error(e)
            setAddStatus(ButtonStatus.Error)
        }
    }

    const deletePartner = async () => {
        if (!partnerToBeDeleted) {
            return;
        }
        setAlertStatus(DialogStatus.Loading)
        try {
            const partners = await api.delete(`/admin/partners/${partnerToBeDeleted.id}`)
            setPartners(partners)
            setFilteredPartners(partners)
            setAlertStatus(DialogStatus.Success)
        } catch (e) {
            console.error(e)
            setAlertStatus(DialogStatus.Error)
        }
    }

    const onDialogClose = () => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
        setPartnerToBeDeleted(null)
    }

    return (
        <div className={styles.card}>
            <div className={styles.title}>Partners</div>
            <TextInput
                onKeyUp={onSearch}
                type="search"
                placeholder={t("search")}
                style={{
                    backgroundColor: "var(--background-secondary-color)",
                    overflow: "hidden"

                }} />
            <div className={styles.partners}>
                <Each
                    of={filteredPartners}
                    render={partner => (
                        <div className={styles.partnerCard} onContextMenu={e => {
                            showMenu(e,
                                [
                                    {
                                        name: "Elimina",
                                        icon: <DeleteIcon className={styles.icon} style={{ color: "var(--sf-red)" }} />,
                                        onClick: () => {
                                            setPartnerToBeDeleted(partner)
                                            setOpenAlert(true)
                                        }
                                    }
                                ]
                            )
                        }}>
                            <img className={styles.partnerLogo} alt={`${partner}-logo`} src={partner.logo} />
                            <DeleteIcon className={styles.deleteIcon} onClick={() => {
                                setPartnerToBeDeleted(partner)
                                setOpenAlert(true)
                            }} />
                        </div>
                    )}
                />
            </div>
            <div className={styles.bottom}>
                <div className={styles.dropzoneContainer}>
                    <Uploader
                        preview={url}
                        Preview={({ preview }) => (
                            <div className={styles.previewContainer}>
                                <div className={styles.partnerCard}>
                                    <img className={styles.partnerLogo} alt={`new-partner-logo`} src={preview} />
                                </div>
                            </div>
                        )}
                        reset={uploadReset}
                        multiple={false}
                        type={FileType.Image}
                        onComplete={uploads => setUrl(uploads[0].url)}
                        onDelete={() => setUrl(null)}
                    />
                </div>
                <div className={styles.form}>
                    <TextInput
                        type="text"
                        value={name}
                        placeholder="Nome"
                        onKeyUp={(v) => setName(v)} />
                    <TextInput
                        type="text"
                        value={url}
                        placeholder="Url logo"
                        onKeyUp={v => setUrl(v)} />
                    <Button
                        status={addStatus}
                        disabled={!name || !url}
                        onClick={add}
                        style={{ padding: ".7rem 1rem" }}
                    >
                        AGGIUNGI {addStatus === null && <AddIcon />}
                    </Button>
                </div>


            </div>
            <AlertDialog
                open={openAlert}
                title={"Elimina partner"}
                text={`Sei sicuro di voler eliminare ${partnerToBeDeleted?.name}?`}
                status={alertStatus}
                onClose={onDialogClose}
                actions={[
                    {
                        label: alertStatus === DialogStatus.Success ? "OK" : "SI, ELIMINA",
                        onClick: alertStatus === DialogStatus.Success ? onDialogClose : deletePartner,
                    }
                ]}
            />
        </div>
    )
}

export default PartnersSettings
