
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import Skeleton from "../Skeleton"
import styles from "./StudentCardItem.module.css"
const { Buffer } = require("buffer")

const StudentCardItem = ({ student, loading = false, bySurname = false }) => {

  const navigate = useNavigate()

  const [picture, setPicture] = useState(student?.picture ?? UserPlaceholder)

  return (
    <div className={styles.container} onClick={() => navigate(`/users/students/${student.id}`)}>
      {
        !loading &&
        <div className={styles.info}>
          <img className={styles.picture} src={picture} alt="" onError={() => setPicture(UserPlaceholder)} />
          <div className={styles.left}>
            <div className={styles.name}>
              {bySurname ?
                <>
                  {student.surname} {student.name}
                </>
                :
                <>
                  {student.name} {student.surname}</>
              }
            </div>
            <div className={styles.email}>{student.email}</div>
          </div>
          <div className={styles.actions}>
            <ChatIcon className={styles.chat}
              onClick={(e) => {
                e.stopPropagation()
                const base64 = Buffer.from(`${student.id}_${student.name}_${student.surname}`, "utf-8").toString("base64")
                navigate(`/messages?chat=${base64}`)
              }}
            />
            <ChevronIcon className={styles.chevron} />
          </div>
        </div>
      }

      {
        loading &&
        <div className={styles.info}>
          <Skeleton type="circle" width="72px" height="72px" />
          <div className={styles.left}>
            <Skeleton type="rect" width="72px" height="20px" borderRadius={"8px"} />
            <Skeleton type="rect" width="144px" height="14px" borderRadius={"8px"} />
          </div>
          <div className={styles.actions}>
            <Skeleton type="circle" width={"24px"} height={"24px"} />
            <ChevronIcon className={styles.chevron} />
          </div>
        </div>
      }

    </div>
  )
}

export default StudentCardItem
