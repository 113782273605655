import { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import ReactPlayer from "react-player"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-pencil.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { ReactComponent as ThumbDownIcon } from "../assets/images/icons/ic-thumb-down.svg"
import { ReactComponent as ThumbUpIcon } from "../assets/images/icons/ic-thumb-up.svg"
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import Tag from "../components/Tag"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Video.module.css"
import VideoCard from "../components/cards/VideoCard"
import ReactMarkdown from "react-markdown";
import AlertDialog from "../components/dialogs/AlertDialog"
import { DialogStatus } from "../enums"
import Back from "../components/Back"

const Video = () => {
    const navigate = useNavigate()

    const { video_id } = useParams()
    const [video, setVideo] = useState(null)
    const [fullDescription, setFullDescription] = useState(false)
    const [showDialog, setShowDialog] = useState(null)
    const [deleting, setDeleting] = useState(DialogStatus.Default)

    useEffect(() => {

        const getVideo = async () => {
            try {
                const video = await api.get(`/admin/videos/${video_id}`)
                setVideo(video)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!video) {
            getVideo()
        }

    }, [video_id])


    const deleteVideo = async () => {
        setDeleting(DialogStatus.Loading)
        try {
            await api.delete(`/admin/videos/${video_id}`)
            setDeleting(DialogStatus.Success)
        } catch (e) {
            setDeleting(DialogStatus.Error)
        }
    }

    const onClose = () => {
        if (deleting === DialogStatus.Success) {
            navigate("/videos")
        } else {
            setDeleting(DialogStatus.Default)
            setShowDialog(false)
        }
    }


    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>{video ? video.title : 'Video'}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {video &&
                    <>
                        <div className={styles.section}>
                            <div className={styles.sectionInner}>
                                <Back onClick={() => navigate("/videos")} />
                                <div className={styles.playerWrapper}>
                                    <ReactPlayer
                                        url={video.url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                        playIcon={<PlayIcon />}
                                        light={video.thumbnail}
                                        config={
                                            {
                                                youtube: {
                                                    playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className={styles.header}>
                                    <div className={typo.subtitle}>{video.title}</div>
                                    <div className={styles.headerActions}>
                                        <div className={styles.rating}>
                                            <div className={styles.like}>
                                                {video.likes}
                                                <ThumbUpIcon />
                                            </div>
                                            <div className={styles.dislike}>
                                                {video.dislikes}
                                                <ThumbDownIcon />
                                            </div>
                                        </div>
                                        <Button
                                            style={{ padding: "0.75rem 1.5rem" }}
                                            onClick={() => navigate(`/videos/${video.id}/editor`)}

                                        >
                                            <EditIcon />
                                            MODIFICA
                                        </Button>
                                        <Button
                                            accentColor={"var(--sf-red)"}
                                            style={{ padding: "0.75rem 1.5rem" }}
                                            onClick={() => setShowDialog(true)}

                                        >
                                            <DeleteIcon />
                                            ELIMINA
                                        </Button>
                                    </div>
                                </div>
                                <div className={`${styles.description} ${fullDescription ? styles.full : ''}`}>
                                    <div className={styles.descriptionInner}>
                                        <ReactMarkdown children={video.description} className={"inline-markdown"} />
                                    </div>
                                    <div className={styles.gradient}>
                                    </div>
                                </div>
                                <Button appearance="text" style={{ padding: 0, width: 'fit-content' }} onClick={() => {
                                    setFullDescription(!fullDescription)
                                }}> {fullDescription && 'Mostra meno'} {!fullDescription && 'Mostra altro'}</Button>
                                <div className={styles.tags}>
                                    <Each of={video.tags} render={(tag) => {
                                        return (
                                            <Tag tag={tag} />
                                        )
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.sectionInner}>
                                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>Correlati</div>
                                <div className={styles.videos}>
                                    <Each of={video.related} render={(v) => {
                                        return (
                                            <VideoCard video={v} />
                                        )
                                    }} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                <AlertDialog
                    open={showDialog}
                    title="Elimina video"
                    onClose={onClose}
                    text="Stai per eliminare questo video. Sei sicuro di voler continuare?"
                    actions={[
                        {
                            label: "ANNULLA",
                            onClick: () => setShowDialog(false)
                        },
                        {
                            label: "SI, ELIMINA",
                            onClick: deleteVideo
                        }
                    ]}
                    status={deleting}
                />
            </div>
        </HeaderFooterLayout>
    )
}

export default Video
