import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { ButtonStatus, FileType } from '../common/constants';
import Back from '../components/Back';
import Button from "../components/Button";
import ArticleCard from '../components/cards/ArticleCard';
import Card from '../components/cards/Card';
import HeaderFooterLayout from '../components/layouts/HeaderFooterLayout';
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import Uploader from '../components/Uploader';
import typo from "../typography.module.css";
import styles from "./ArticleEditor.module.css";

const ArticleEditor = ({ }) => {
  const { article_id } = useParams()
  const navigate = useNavigate()
  const [article, setArticle] = useState(null)
  const [name, setName] = useState("")
  const [abstract, setAbstract] = useState("")
  const [readingTime, setReadingTime] = useState(null)
  const [tags, setTags] = useState([])
  const [optionsTags, setOptionsTags] = useState([])

  const [thumbnail, setThumbnail] = useState(null)
  const [uploadReset, setUploadReset] = useState(false)
  const [url, setUrl] = useState("")

  const [publishing, setPublishing] = useState(null)

  const mdRef = useRef(null)

  useEffect(() => {
    const getTags = async () => {
      try {
        const tags = await api.get("/admin/tags?type=general")
        setOptionsTags(tags)
      }
      catch (e) {
        console.error(e)
      }
    }

    const getArticle = async () => {
      try {
        const article = await api.get(`/admin/articles/${article_id}`)
        setArticle(article)
      }
      catch (e) {
        console.error(e)
      }
    }
    getTags()

    if (article_id !== "create") {
      getArticle()
    }
  }, []);

  useEffect(() => {
    if (article) {
      setName(article.name)
      setAbstract(article.abstract)
      setReadingTime(article.reading_time)
      mdRef.current?.setMarkdown(article.abstract)
      setUrl(article.url)
      setThumbnail(article.thumbnail)
      setTags(article.tags)
      setOptionsTags(ot => ot?.filter(t => article.tags.map(tt => tt.id).includes(t.id) === false))
    }

  }, [article])

  const saveArticle = async () => {
    try {
      setPublishing(ButtonStatus.Loading)
      const body = {
        name,
        abstract,
        url,
        reading_time: readingTime,
        tags: tags?.length > 0 ? JSON.stringify(tags.map(t => t.id)) : null,
        thumbnail,
      }

      const res = article_id === "create" ?
        await api.post("/admin/articles", body) :
        await api.put(`/admin/articles/${article_id}`, body)

      setPublishing(ButtonStatus.Success)
      setUploadReset(!uploadReset)
      if (article_id === "create") {
        navigate(`/articles`)
      } else {
        setArticle(res)
      }
    } catch (e) {
      setPublishing(ButtonStatus.Error)

      console.error(e)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Editor Articolo</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {article_id === "create" ? "Aggiungi articolo" : "Modifica articolo"}
              </div>
            </div>
            <ArticleCard
              article={{
                name,
                abstract,
                tags,
                thumbnail,
                url,
                reading_time: readingTime
              }}
              interactive={false}
            />
            <Card>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.left}>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TITOLO</div>
                      <TextInput
                        type="text"
                        value={name}
                        placeholder={"Titolo"}
                        onKeyUp={v => setName(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TEMPO DI LETTURA</div>
                      <TextInput
                        type="number"
                        value={readingTime}
                        placeholder={"Tempo di lettura"}
                        onKeyUp={(v) => setReadingTime(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TAG</div>
                      <TagSelector
                        selected={tags}
                        options={optionsTags}
                        placeholder={(tags.length === 0 && "Seleziona un tag") || ""}
                        style={{ fontSize: "1rem" }}
                        onAdd={(item) => {
                          setTags(tags => [...tags, item])
                          setOptionsTags(tags => tags.filter(t => t.id !== item.id))
                        }}
                        onRemove={(item) => {
                          setTags(t => {
                            t = t.filter((tt) => tt.id !== item.id)
                            return [...t]
                          })
                        }}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL THUMBNAIL"}</div>
                      <TextInput
                        type="text"
                        value={thumbnail}
                        placeholder={"Url thumbnail"}
                        onKeyUp={(v) => setThumbnail(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL ARTICOLO"}</div>
                      <TextInput
                        type="text"
                        value={url}
                        placeholder={"Url articolo"}
                        onKeyUp={(v) => setUrl(v)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.mediaContainer}>

                      <Uploader
                        preview={thumbnail}
                        reset={uploadReset}
                        multiple={false}
                        type={FileType.Image}
                        onComplete={uploads => setThumbnail(uploads[0].url)}
                        onDelete={() => setThumbnail(null)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.editor}>
                  <MDXEditor
                    ref={mdRef}
                    contentEditableClassName={styles.markdownEditor}
                    markdown={abstract}
                    placeholder="Abstract"
                    onChange={(value) => { setAbstract(value) }}
                    plugins={[
                      headingsPlugin(),
                      listsPlugin(),
                      quotePlugin(),
                      thematicBreakPlugin(),
                      linkDialogPlugin(),
                      diffSourcePlugin(),
                      tablePlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <Separator />
                            <BoldItalicUnderlineToggles />
                            <Separator />
                            <ListsToggle />
                            <CreateLink />
                            <InsertTable />
                            <Separator />
                            <BlockTypeSelect />
                            <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                          </>
                        )
                      })
                    ]}
                  />
                </div>
                <div className={styles.action}>
                  <Button
                    status={publishing}
                    disabled={!name || !abstract || !thumbnail || !url}
                    style={{ padding: "0.75rem 2rem" }}
                    onClick={saveArticle}
                  >
                    {article_id === "create" ? "PUBBLICA" : "SALVA"}
                  </Button>
                </div>
              </div>

            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default ArticleEditor
