import { isToday, isTomorrow, isYesterday } from "date-fns";
import { palette, RowType, SortDirection } from "./common/constants";
import { CorrectionType, TestResultStatus, TestType } from "./enums";
import { v4 } from "uuid";

export function capitalize(string) {
    // Verifica se la stringa è vuota
    if (!string) {
        return "";
    }

    // Dividi la stringa in un array di parole
    const words = string.split(' ');

    // Capitalizza ogni parola nell'array
    const capitalizedWords = words.map(word => {
        // Capitalizza la prima lettera di ogni parola
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Unisci le parole capitalizzate in una stringa e restituiscila
    return capitalizedWords.join(' ');
}

export function filterArray(arr, str) {
    return arr.filter(item => {
        const words = str
            .toLowerCase()
            .split(' ')
            .filter(word => word.length > 2); // Dividi la stringa in parole lunghe più di 3 lettere
        return words.every(word => item.toLowerCase().includes(word)); // Controlla se tutte le parole sono incluse nella stringa
    });
}

export function sortFilteredArray(arr, str) {
    return arr.sort((a, b) => {
        const startsWithStrA = a.toLowerCase().startsWith(str.toLowerCase());
        const startsWithStrB = b.toLowerCase().startsWith(str.toLowerCase());

        // Se entrambi iniziano con la stringa specificata, li ordina normalmente
        if (startsWithStrA && startsWithStrB) {
            return a.localeCompare(b);
        }
        // Se solo uno inizia con la stringa, lo mette prima
        else if (startsWithStrA) {
            return -1;
        } else if (startsWithStrB) {
            return 1;
        }
        // Altrimenti, li ordina normalmente
        else {
            return a.localeCompare(b);
        }
    });
}

// Funzione principale
export function filterAndSortArray(arr, str) {
    const filteredArr = filterArray(arr, str);
    return sortFilteredArray(filteredArr, str);
}

export function formatPrice(num) {
    if (!num) {
        return "00,00"
    }
    // Converti il numero in una stringa e sostituisci il punto decimale con la virgola
    let formattedNum = num.toString().replace('.', ',');

    // Se il numero contiene una virgola
    if (formattedNum.includes(',')) {
        // Dividi la parte intera dalla parte decimale
        let parts = formattedNum.split(',');

        // Formatta la parte intera aggiungendo i punti come separatore ogni 3 cifre
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Unisci le due parti e restituisci il risultato
        return parts.join(',');
    } else {
        // Se non ci sono decimali, aggiungi ",00" alla fine
        formattedNum += ',00';
    }

    // Se la parte intera ha più di tre cifre, aggiungi i punti come separatore ogni 3 cifre
    if (formattedNum.length > 3) {
        formattedNum = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return formattedNum;
}

export function splitArray(array, nParts) {
    var subArrays = []
    for (let i = 0; i < nParts; i++) {
        subArrays.push([])
    }
    for (let j = 0; j < array.length; j++) {
        const index = j % subArrays.length; // Calcola l'indice del colore utilizzando l'operatore modulo
        subArrays[index].push(array[j])
    }

    return subArrays
}

export function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

export function hexAlpha(color, alpha) {
    // Converti la percentuale alpha in un valore compreso tra 0 e 1
    const _alpha = alpha / 100;

    // Calcola il valore alpha in esadecimale
    const alphaHex = Math.round(_alpha * 255).toString(16).toUpperCase();

    // Assicurati che il valore esadecimale abbia due cifre
    const alphaPadded = alphaHex.padStart(2, '0');

    // Restituisci il colore esadecimale con alpha
    return color + alphaPadded;
}

export function formatDate(dateString, compact = false, includeWeekDay = true) {
    let date = new Date(dateString)
    // Array dei nomi dei giorni della settimana
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

    // Array dei nomi dei mesi
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

    // Ottieni il giorno della settimana, il giorno del mese e il mese dalla data
    const dayOfWeek = compact ? daysOfWeek[date.getDay()].substring(0, 3) : daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = compact ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];

    if (isToday(date)) {
        return 'Oggi'
    }
    else if (isTomorrow(date)) {
        return 'Domani'
    }
    else if (isYesterday(date)) {
        return 'Ieri'
    }

    // Formatta la data nel formato desiderato
    const formattedDate = includeWeekDay ? `${dayOfWeek} ${dayOfMonth} ${month}` : `${dayOfMonth} ${month}`

    return formattedDate;
}

export function formatDateV2(timestamp, options, lng = "it") {
    const date = new Date(timestamp)
    const offset = date.getTimezoneOffset()
    const userDate = new Date(date.getTime() - (offset * 60 * 1000));

    // if (isToday(userDate)) {
    //     return 'Oggi'
    // }
    // else if (isTomorrow(userDate)) {
    //     return 'Domani'
    // }
    // else if (isYesterday(userDate)) {
    //     return 'Ieri'
    // }

    var baseOptions = { month: 'long', day: 'numeric' };
    baseOptions = options ? options : baseOptions
    return userDate.toLocaleDateString(lng, baseOptions)
}

export function formatTimeV2(timestamp, options, lng = "it", applyTz = true) {
    const date = new Date(timestamp)
    var userDate = date
    if (applyTz) {
        const offset = date.getTimezoneOffset()
        userDate = new Date(date.getTime() - (offset * 60 * 1000))
    }
    var baseOptions = { hour: '2-digit', minute: '2-digit' };
    baseOptions = options ? options : baseOptions
    return userDate.toLocaleTimeString(lng, baseOptions)
}

export function formatTime(dateString) {
    let date = new Date(dateString)
    // Ottieni le ore e i minuti dalla data
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Aggiungi uno zero iniziale se i minuti sono inferiore a 10
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Formatta l'ora nel formato HH:mm
    const formattedTime = `${hours}:${formattedMinutes}`;

    return formattedTime;
}

export function getMonthName(dateString, compact = false) {
    const date = new Date(dateString)
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    return compact ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];
}

export function getDayName(dateString, compact = false) {
    const date = new Date(dateString)
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    return compact ? daysOfWeek[date.getDay()].substring(0, 3) : daysOfWeek[date.getDay()];
}

export function hourDiff(dateString1, dateString2) {
    // Converti le stringhe in oggetti Date
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    // Calcola la differenza in millisecondi
    const msDiff = Math.abs(date1 - date2);

    // Converti la differenza in ore
    const hDiff = Math.floor(msDiff / (1000 * 60 * 60));

    return hDiff;
}

export function calcLastUpdate(dateString, compact) {
    let now = new Date();
    let date = new Date(dateString);

    // let offset = date.getTimezoneOffset();

    // Aggiungi l'offset al timestamp della data specificata
    // let localDate = new Date(date.getTime() - (offset * 60 * 1000));

    const msDiff = Math.abs(now - date);

    const sDiff = parseInt(msDiff / (1000))
    const mDiff = parseInt(msDiff / (1000 * 60))
    const hDiff = parseInt(msDiff / (1000 * 60 * 60))
    const dDiff = parseInt(msDiff / (1000 * 60 * 60 * 24))
    const wDiff = parseInt(msDiff / (1000 * 60 * 60 * 24 * 7))

    var result = compact ? `${wDiff}s` : `${wDiff} ${wDiff === 1 ? 'settimana' : 'settimane'} fa`

    if (wDiff < 1) {
        result = compact ? `${dDiff}g` : `${dDiff} ${dDiff === 1 ? 'giorno' : 'giorni'} fa`
        if (hDiff < 24) {
            result = compact ? `${hDiff}h` : `${hDiff} ${hDiff === 1 ? 'ora' : 'ore'} fa`
            if (hDiff < 1) {
                result = compact ? `${mDiff}m` : `${mDiff} ${mDiff === 1 ? 'minuto' : 'minuti'} fa`
                if (mDiff < 1) {
                    result = compact ? `${sDiff}sec` : `${sDiff} ${sDiff === 1 ? 'secondo' : 'secondi'} fa`
                }
            }
        }
    }

    return result;
}

/**
 * This function removes the duplicates from an array checking the id field.
 * @param {*} array 
 * @returns the array with no duplicates objects.
 */
export const dropDuplicates = (array) => {
    const uniqueObjects = {};
    const result = [];

    // Itera sull'array originale
    array.forEach(obj => {
        // Verifica se l'oggetto con lo stesso id è già presente nell'oggetto uniqueObjects
        if (!uniqueObjects[obj.id]) {
            // Se non è presente, aggiungi l'oggetto all'oggetto uniqueObjects e al risultato
            uniqueObjects[obj.id] = true;
            result.push(obj);
        }
    });

    return result;
};

export function formatFileSize(byteSize) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let size = byteSize;
    let i = 0;

    while (size >= 1000 && i < units.length - 1) {
        size /= 1000;
        i++;
    }

    return size.toFixed(2) + ' ' + units[i];
}

export function getLocaleISOString() {
    const currentDate = new Date().toLocaleDateString()
    const currentTime = new Date().toLocaleTimeString()
    const components = currentDate.split('/')
    const isoString = `${components[2]}-${components[1].padStart(2, '0')}-${components[0].padStart(2, '0')}T${currentTime}`
    return isoString
}

export function toLetter(value) {
    return (value + 9).toString(36)
}

export function emptyOrNull(string) {
    return string === null || string.trim() === ''
}

export function getDefaultSection() {
    return {
        name: "",
        position: 1,
        rows: [
            {
                content: "",
                type: RowType.Text,
                style: {}
            }
        ]
    }
}

export function getDefaultRow() {
    return {
        content: "",
        type: RowType.Text,
        style: {}
    }
}


export function hasProperty(object, property, value) {
    return object && object[property] && object[property] === value
}

export function secondsToHours(seconds, compact = false) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let s = `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`;
    if (!compact) {
        s += ` ${String(remainingSeconds).padStart(2, '0')}s`
    }
    return s;
}

export async function checkImage(url) {
    const res = await fetch(url, { mode: "no-cors" });
    const buff = await res.blob();
    return buff.type.startsWith('image/')

}

export function UTCtoLocal(dateString) {
    let date = new Date(dateString)
    const offset = date.getTimezoneOffset()
    return new Date(date.getTime() - (offset * 60 * 1000))
}


export function getDateArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate)

    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}

export function fillEmptyDays(data) {
    // si presuppone che data abbia un campo "date" (ISOString) e che sia ordinato per date
    if (data.length === 0 || data.length === 1) {
        return data
    }

    const min = data[0].date
    const max = data[data.length - 1].date

    const dateArrays = getDateArray(min, max).map(d => d.toISOString().split("T")[0])
    const result = [data[0]]
    let j = 1;
    for (let i = 1; i < dateArrays.length - 1 && j < data.length - 1; i++) {
        if (dateArrays[i] === data[j].date) {
            result.push(data[j])
            j++
        } else {
            result.push({ ...result[i - 1], date: dateArrays[i] })
        }
    }
    result.push(data[data.length - 1])
    return result
}

export function getMonthsArrays(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    currentDate.setDate(15)
    endDate = new Date(endDate)

    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return dateArray;
}

export function fillEmptyMonths(data) {
    // si presuppone che data abbia un campo "date" (ISOString) e che sia ordinato per date
    if (data.length === 0 || data.length === 1) {
        return data
    }

    const min = data[0].date
    const max = data[data.length - 1].date

    const dateArrays = getMonthsArrays(min, max).map(d => d.toISOString().split("T")[0])
    const result = [data[0]]
    let j = 1;

    const getMonth = (s) => {
        return s.split("-").slice(0, 2).join("-")
    }

    for (let i = 1; i < dateArrays.length - 1 && j < data.length - 1; i++) {
        if (getMonth(dateArrays[i]) === getMonth(data[j].date)) {
            result.push(data[j])
            j++
        } else {
            result.push({ ...result[i - 1], date: dateArrays[i] })
        }
    }
    result.push(data[data.length - 1])
    return result
}


/* -------------------------------------------------------------------------- */
/*                                 TEST UTILS                                 */
/* -------------------------------------------------------------------------- */
export function isTestValid(name, module, lesson, innerTests) {
    let areInnerTestValid = true
    const isModuleValid = module && module.id > 0
    const isLessonValid = true

    for (const innerTest of innerTests) {
        if (innerTest.isValid === false) {
            areInnerTestValid = false
            break;
        }
    }

    return {
        isValid: name !== null && name !== "" && areInnerTestValid && isModuleValid && isLessonValid,
        isNameEmpty: !name,
        areInnerTestValid,
        isModuleValid,
        isLessonValid
    }
}

export function isTestChoiceValid(text, answers) {
    let atLeastOneIsCorrect = false
    let atLeastOneIsIncorrect = false
    let isAnswerTextValid = true

    for (const a of answers) {
        if (a.isCorrect) {
            atLeastOneIsCorrect = true
        } else if (!a.isCorrect) {
            atLeastOneIsIncorrect = true
        }

        if (!a.text) {
            isAnswerTextValid = false
        }
    }

    return {
        isValid: text !== "" && answers.length > 0 && atLeastOneIsCorrect && atLeastOneIsIncorrect && isAnswerTextValid,
        noQuestion: text === "",
        noAnswers: !answers || answers.length === 0,
        atLeastOneIsCorrect,
        atLeastOneIsIncorrect,
        isAnswerTextValid
    }
}

export function isTestTrueFalseValid(answers) {
    let everyAnswerHasSolution = true
    let isAnswerTextValid = true

    for (const a of answers) {
        if (!a.text) {
            isAnswerTextValid = false
        }

        if (a.value === null || a.value === undefined) {
            everyAnswerHasSolution = false
        }
    }

    return {
        isValid: everyAnswerHasSolution && answers.length > 0 && isAnswerTextValid,
        everyAnswerHasSolution,
        isAnswerTextValid
    }
}

export function isTestTextCompletionValid(words) {
    let atLeastOneHiddenWord = false

    for (const w of words) {
        if (w.hidden === true) {
            atLeastOneHiddenWord = true
        }
    }

    return {
        isValid: words.length > 0 && atLeastOneHiddenWord,
        atLeastOneHiddenWord,
        emptyText: words.length === 0
    }
}


export function getDefaultTest() {
    return {
        name: "",
        description: "",
        module: null,
        lesson: null,
        tags: [],
        can_be_retried: false,
        expires_at: null,
        correction_type: CorrectionType.Solutions,
        success_threshold: 1,
        content: {
            innerTests: [
                {
                    testType: TestType.SingleChoice,
                    text: "",
                    tags: [],
                    answers: [{
                        id: v4(),
                        text: "",
                        isCorrect: false
                    }, {
                        id: v4(),
                        text: "",
                        isCorrect: false
                    }]
                }
            ]
        }
    }
}


export function isTestCompleted(innerTests) {
    let completed = 0
    for (const it of innerTests) {
        for (const r of it.responses) {
            if (r.value) {
                completed += 1
                break
            }
        }
    }
    if (completed === innerTests.length) {
        return TestResultStatus.Passed
    } else if (completed > 0 && completed < innerTests.length) {
        return TestResultStatus.Partial
    } else {
        return TestResultStatus.NotCompleted
    }
}

export function getSingleWords(text) {
    return text.split(/[\s\n]+/)
        .filter(w => w !== " " && w !== "")
        .map(s => {
            const markdown = s.slice()
            const clean = s.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]|<\/?u>|x20;/g, '')
            return {
                text: clean,
                markdown,
                hidden: false,
            }
        })
}

export function wrapWordsWithAsterisks(text) {
    // Regex per trovare il testo tra * o ** asterischi
    return text.replace(/(\*+)(.*?)\1/g, (match, asterisks, content) => {
        // Wrappare ogni parola con lo stesso numero di asterischi
        const wrappedWords = content.split(' ').map(word => `${asterisks}${word}${asterisks}`).join(' ');
        return wrappedWords;
    });
}

export function getInitials(input) {
    const words = input.split(' ');
    const initials = words.map(word => word.charAt(0)).join('');
    return initials;
}

export function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * palette.length);
    return palette[randomIndex];
}


export const sortUser = (a, b, orderBy, sortDirection) => {
    const { value } = orderBy;
    
    const fieldA = a[value];
    const fieldB = b[value];
    if (value === 'created_at') {
        const dateA = new Date(fieldA);
        const dateB = new Date(fieldB);
        return sortDirection === SortDirection.Ascending
            ? dateA - dateB
            : dateB - dateA;
    }
    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
        return sortDirection === SortDirection.Ascending
            ? fieldA.localeCompare(fieldB) // Crescente
            : fieldB.localeCompare(fieldA); // Decrescente
    }
    if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        return sortDirection === SortDirection.Ascending
            ? fieldA - fieldB
            : fieldB - fieldA;
    }
    return 0;
};

export function range(min, max, step = 1) {
    const result = [];
    for (let i = min; i <= max; i += step) {
        result.push(i);
    }
    return result;
}

export function formatSeconds(seconds) {
    if (seconds <= 0) {
        return '0s'
    }

    const secs = seconds % 60;
    const totalMinutes = Math.floor(seconds / 60);
    const mins = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hrs = totalHours % 24;
    const days = Math.floor(totalHours / 24);

    if (days > 0) {
        return `${days}g ${hrs}h ${mins}m ${secs}s`;
    } else if (hrs > 0) {
        return `${hrs}h ${mins}m ${secs}s`;
    } else if (mins > 0) {
        return `${mins}m ${secs}s`;
    } else {
        return `${secs}s`;
    }
}