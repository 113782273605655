
import { useNavigate } from "react-router-dom"
import Back from "../components/Back"
import styles from "./TeachingReports.module.css"
import { Each } from "../common/Each"
import TabItem from "../components/TabItem"
import typo from "../typography.module.css"
import { capitalize, formatPrice, secondsToHours } from "../utils"
import { TimeFilter, TimeGroup } from "../common/constants"
import { useCallback, useEffect, useMemo, useState } from "react"
import StatisticsCard from "../components/cards/StatisticCard"
import api from "../api"
import SimpleCollapsable from "../components/SimpleCollapsable"
import Button from "../components/Button"
import CourseBadge from "../components/CourseBadge"

const TeachingReports = () => {

    const navigate = useNavigate()

    const [lessons, setLessons] = useState({
        completedFilter: null,
        scheduledFilter: null,
        completed: { count: 0, duration: 0, avgDuration: 0 },
        scheduled: { count: 0, duration: 0 },
        watchTime: {
            live: { min: 0, max: 0, sum: 0, avg: 0 },
            recorded: { min: 0, max: 0, sum: 0, avg: 0 },
        }
    })
    const [courses, setCourses] = useState({
        filter: null,
        group: TimeGroup.Daily,
        loading: false,
        expanded: [],
        data: [],
    })
    const filterTabs = useMemo(() => {
        return [
            { id: TimeFilter.Today, text: "Oggi", color: "var(--primary-rgb)", tag: "all" },
            { id: TimeFilter.CurrentMonth, text: "Questo mese", color: "var(--primary-rgb)", tag: "all" },
            { id: TimeFilter.Last7Days, text: "Ultimi 7 giorni", color: "var(--primary-rgb)", tag: "past" },
            { id: TimeFilter.Last30Days, text: "Ultimi 30 giorni", color: "var(--primary-rgb)", tag: "past" },
            { id: TimeFilter.Next7Days, text: "Prossimi 7 giorni", color: "var(--primary-rgb)", tag: "future" },
            { id: TimeFilter.Next30Days, text: "Prossimi 30 giorni", color: "var(--primary-rgb)", tag: "future" },
        ]
    }, [])

    useEffect(() => {
        getWatchTime()
    }, [])

    useEffect(() => {
        getCompletedLessons()
    }, [lessons.completedFilter])

    useEffect(() => {
        getScheduledLessons()
    }, [lessons.scheduledFilter])

    useEffect(() => {
        getCoursesStatistics()
    }, [courses.filter, courses.group])

    const getCoursesStatistics = useCallback(async () => {
        setCourses(c => ({ ...c, loading: true }))
        try {
            const response = await api.get(`/admin/courses/statistics?group=${courses.group}${courses.filter ? `&filter=${courses.filter}` : ""}`)
            const { users_per_course, profits_per_course, profits_trend } = response

            for (const [index, course] of users_per_course.entries()) {
                const profit = profits_per_course.find(p => p.id === course.id)
                const trend = profits_trend.filter(p => p.id === course.id)
                users_per_course[index].profit = profit?.amount ?? 0
                users_per_course[index].trend = trend
            }
            setCourses(c => ({ ...c, data: users_per_course }))
        } catch (e) {
            console.error(e)
        }
        setCourses(c => ({ ...c, loading: false }))
    }, [courses.filter, courses.group])

    const getScheduledLessons = useCallback(async () => {
        try {
            const scheduled = await api.get(`/admin/lessons/statistics/scheduled${lessons.scheduledFilter ? `?period=${lessons.scheduledFilter}` : ""}`)
            setLessons(l => {
                return {
                    ...l,
                    scheduled
                }
            })
        } catch (e) {
            console.error(e)
        }
    }, [lessons.scheduledFilter])

    const getCompletedLessons = useCallback(async () => {
        try {
            const response = await api.get(`/admin/lessons/statistics/completed${lessons.completedFilter ? `?filter=${lessons.completedFilter}` : ""}`)
            setLessons(l => {
                return {
                    ...l,
                    completed: { ...response.completed, avgDuration: response.duration.avg }
                }
            })
        } catch (e) {
            console.error(e)
        }
    }, [lessons.completedFilter])

    const getWatchTime = useCallback(async () => {
        try {
            const watchTime = await api.get(`/admin/lessons/statistics/watch_time`)

            let liveData = { min: 0, max: 0, sum: 0, avg: 0 }
            let recordedData = { min: 0, max: 0, sum: 0, avg: 0 }
            if (watchTime.find(w => w.mode === 'recorded')) {
                const { mode: m, ...recorded } = watchTime.find(w => w.mode === 'recorded')
                recordedData = recorded
            }
            if (watchTime.find(w => w.mode === 'live')) {
                const { mode, ...live } = watchTime.find(w => w.mode === 'live')
                liveData = live
            }
            console.log(recordedData, liveData)
            setLessons(l => {
                return {
                    ...l,
                    watchTime: { live: liveData, recorded: recordedData }
                }
            })
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        console.log(lessons)
    }, [lessons])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.sectionInner}>
                    <Back onClick={() => {
                        navigate('/reports')
                    }} />
                    <div className={typo.title}>{'Report > Didattica'}</div>
                    <div className={styles.sectionCard}>
                        <div className={styles.column} style={{ gap: "2rem" }}>
                            <div className={styles.column}>
                                <div className={styles.header}>
                                    <div className={typo.subheadline}>Lezioni completate</div>
                                    <div className={styles.filter}>
                                        <Each
                                            of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                                            render={(item) => (
                                                <TabItem
                                                    selected={lessons.completedFilter === item.id}
                                                    text={capitalize(item.text)}
                                                    onClick={() => {
                                                        setLessons(l => {
                                                            if (l.completedFilter === item.id) {
                                                                return { ...l, completedFilter: null }
                                                            }
                                                            return { ...l, completedFilter: item.id }
                                                        })
                                                    }}
                                                    color={item.color}
                                                    style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                                                    defaultBackground="var(--background-secondary-color)"
                                                />
                                            )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={styles.cards}>
                                    <StatisticsCard
                                        label="Totale"
                                        value={lessons.completed.count}
                                    />
                                    <StatisticsCard
                                        label="Ore lezioni svolte"
                                        value={lessons.completed.duration}
                                        format={(v) => secondsToHours(v * 60, true)}
                                    />
                                    <StatisticsCard
                                        label="Media ore di lezioni svolte"
                                        value={lessons.completed.avgDuration}
                                        format={(v) => secondsToHours(v * 60, true)}
                                    />
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.column}>
                                    <div className={styles.header}>
                                        <div className={typo.subheadline}>Lezioni programmate</div>
                                        <div className={styles.filter}>
                                            <Each
                                                of={filterTabs.filter(f => f.tag === "all" || f.tag === "future")}
                                                render={(item) => (
                                                    <TabItem
                                                        selected={lessons.scheduledFilter === item.id}
                                                        text={capitalize(item.text)}
                                                        onClick={() => {
                                                            setLessons(l => {
                                                                if (l.scheduledFilter === item.id) {
                                                                    return { ...l, scheduledFilter: null }
                                                                }
                                                                return { ...l, scheduledFilter: item.id }
                                                            })
                                                        }}
                                                        color={item.color}
                                                        style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                                                        defaultBackground="var(--background-secondary-color)"
                                                    />
                                                )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.cards}>
                                        <StatisticsCard
                                            label="Totale"
                                            value={lessons.scheduled.count}
                                        />
                                        <StatisticsCard
                                            label="Ore lezioni programmate"
                                            value={lessons.scheduled.duration}
                                            format={(v) => secondsToHours(v * 60, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div className={typo.subheadline}>Watch time medio</div>
                                <div className={styles.cards}>
                                    <StatisticsCard
                                        label="Live"
                                        value={lessons.watchTime.live?.avg}
                                        format={(v) => secondsToHours(v, true)}
                                    />
                                    <StatisticsCard
                                        label="Registrazioni"
                                        value={lessons.watchTime.recorded?.avg}
                                        format={(v) => secondsToHours(v, true)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sectionInner} style={{ paddingTop: '2rem' }}>
                    <div className={styles.sectionCard}>
                        <div className={styles.header}>
                            <div className={typo.subtitle}>Corsi</div>
                            <div className={styles.filter}>
                                <Each
                                    of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                                    render={(item) => (
                                        <TabItem
                                            selected={courses.filter === item.id}
                                            text={capitalize(item.text)}
                                            onClick={() => {
                                                setCourses(c => {
                                                    if (c.filter === item.id) {
                                                        return { ...c, filter: null }
                                                    }
                                                    return { ...c, filter: item.id }
                                                })
                                            }}
                                            color={item.color}
                                            style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                                            defaultBackground="var(--background-secondary-color)"
                                        />
                                    )
                                    }
                                />
                            </div>
                        </div>
                        <Each
                            of={courses.data}
                            render={(c, index) => (
                                <div className={styles.courseCard} onClick={() => {
                                    navigate(`/reports/teaching/courses/${c.id}`)
                                }}>
                                    <div className={styles.courseTop}>
                                        <div className={styles.left}>
                                            <div className={styles.thumbnail}>
                                                <img src={c.thumbnail} alt="" />
                                            </div>
                                            <div className={styles.courseInfo}>
                                                <CourseBadge type={c.type} />
                                                <div className={styles.courseName}>{c.name}</div>
                                            </div>
                                        </div>
                                        <div className={styles.courseStats}>

                                            <div className={styles.labeled}>
                                                <div className={styles.label}>Attivi</div>
                                                <div className={styles.value}>{c.in_progress}</div>
                                            </div>
                                            <div className={styles.labeled}>
                                                <div className={styles.label}>Non iscritti</div>
                                                <div className={styles.value}>{c.to_start}</div>
                                            </div>
                                            <div className={styles.labeled}>
                                                <div className={styles.label}>Completati</div>
                                                <div className={styles.value}>{c.completed}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TeachingReports