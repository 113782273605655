import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg";
import { ReactComponent as AdminsIcon } from "../assets/images/icons/ic-admins.svg";
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students-active.svg";
import { ReactComponent as TeachersIcon } from "../assets/images/icons/ic-teachers-active.svg";
import { ReactComponent as TutorsIcon } from "../assets/images/icons/ic-tutor-active.svg";
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-sort-arrow.svg";
import { Role, SortDirection, UserPages } from "../common/constants";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import Admins from "../components/Admins";
import Button from "../components/Button";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Select from "../components/Select";
import Students from "../components/Students";
import Teachers from "../components/Teachers";
import typo from "../typography.module.css";
import { capitalize } from './../utils';
import styles from "./Users.module.css";

const Users = ({ defaultSelectedTab = UserPages.Students }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending)
  const tabs = useMemo(() => {
    return [
      { id: UserPages.Students, icon: StudentsIcon, text: "Studenti", color: "var(--sf-light-green)", colorRgb: "var(--sf-light-green-rgb)", route: `/users/students`, value: null },
      { id: UserPages.Teachers, icon: TeachersIcon, text: "Docenti", color: "var(--tertiary)", colorRgb: "var(--tertiary-rgb)", route: `/users/teachers`, value: null },
      { id: UserPages.Tutors, icon: TutorsIcon, text: "Tutor", color: "var(--sf-purple)", colorRgb: "var(--sf-purple-rgb)", route: `/users/tutors`, value: null },
      { id: UserPages.Admins, icon: AdminsIcon, text: "Admin", color: "var(--secondary)", colorRgb: "var(--secondary-rgb)", route: `/users/admins`, value: null },
    ]
  }, [])

  const orderByOptions = useMemo(() => {
    return [
      { id: 0, name: "Data iscrizione", value: "created_at" },
      { id: 1, name: "Nome", value: "name" },
      { id: 2, name: "Cognome", value: "surname" },
    ]
  }, [])
  const [orderBy, setOrderBy] = useState(orderByOptions[0])

  const studentsRef = useRef(null)
  const teachersRef = useRef(null)
  const tutorsRef = useRef(null)
  const adminsRef = useRef(null)

  useEffect(() => {
    setSelectedTab(defaultSelectedTab)
  }, [defaultSelectedTab])


  const handleAddClick = () => {
    switch (selectedTab) {
      case UserPages.Students: studentsRef.current?.onAddClick(); break;
      case UserPages.Teachers: teachersRef.current?.onAddClick(); break;
      case UserPages.Tutors: tutorsRef.current?.onAddClick(); break;
      case UserPages.Admins: adminsRef.current?.onAddClick(); break;
      default: break;
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            {capitalize(tabs.find(t => t.id === selectedTab).text)}
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
              Utenti
              <Button style={{ padding: '0.7rem 1rem' }} onClick={handleAddClick}>
                AGGIUNGI
                <AddIcon />
              </Button>
            </div>
            <div className={styles.header}>

              <div className={styles.tabs}>
                <Each
                  of={tabs}
                  render={(tab) => (
                    <div
                      className={selectedTab === tab.id ? `${styles.tab} ${styles.selected}` : styles.tab}
                      style={{
                        "--accent-color": tab.color,
                        "--accent-color-rgb": tab.colorRgb
                      }}
                      onClick={() => {
                        setSelectedTab(tab.id)
                        navigate(tab.route, { replace: true })
                      }}
                    >
                      <tab.icon />
                      {tab.text}
                    </div>
                  )}
                />
              </div>
              <div className={styles.orderSelector}>
                <div className={styles.orderLabel}>
                  Ordina per:
                </div>
                <Select
                  style={{ minWidth: "172px" }}
                  options={orderByOptions}
                  selected={orderBy}
                  onSelect={(o) => setOrderBy(o)}
                />
                <div className={styles.sortDirection} onClick={() => setSortDirection(sd => sd === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending)}>
                  <ArrowIcon
                    className={sortDirection === SortDirection.Ascending ? styles.up : styles.down} />
                </div>
              </div>
            </div>
            {selectedTab === UserPages.Students && <Students ref={studentsRef} orderBy={orderBy} sortDirection={sortDirection} />}
            {selectedTab === UserPages.Teachers && <Teachers ref={teachersRef} role={Role.Teacher} orderBy={orderBy} sortDirection={sortDirection} />}
            {selectedTab === UserPages.Tutors && <Teachers ref={tutorsRef} role={Role.Tutor} orderBy={orderBy} sortDirection={sortDirection} />}
            {selectedTab === UserPages.Admins && <Admins ref={adminsRef} orderBy={orderBy} sortDirection={sortDirection} />}
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
};

export default Users;
