import { useEffect, useMemo, useState } from "react"
import { useNavigate } from 'react-router-dom'
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { OrderStatus } from "../../common/constants"
import { Each } from "../../common/Each"
import SimpleCollapsable from "../../components/SimpleCollapsable"
import TextInput from "../../components/TextInput"
import Checkbox from "../../components/Checkbox"
import TooltipWrapper from "../../components/TooltipWrapper"
import typo from "../../typography.module.css"
import { formatPrice } from "../../utils"
import Button from "../Button"
import Skeleton from "../Skeleton"
import StatusBadge from "../StatusBadge"
import Card from "./Card"
import styles from "./OrderCard.module.css"
import { DialogStatus } from "../../enums"
import AlertDialog from "../dialogs/AlertDialog"
import api from "../../api"

const OrderCard = ({
    order,
    loading = false,
    onRefund = () => { }
}) => {

    const [open, setOpen] = useState(false)
    const [picture, setPicture] = useState(order?.user?.picture ?? UserPlaceholder)
    const [refundMode, setRefundMode] = useState(false)
    const [refundingProducts, setRefundingProducts] = useState({})

    const [openAlert, setOpenAlert] = useState(false)
    const [refundStatus, setRefundStatus] = useState(DialogStatus.Default)

    const navigate = useNavigate()

    const status = useMemo(() => {
        if (!order) {
            return null;
        }

        const { status } = order
        switch (status) {
            case OrderStatus.Pending:
                return {
                    label: "In Attesa",
                    color: "var(--sf-gray)"
                }
            case OrderStatus.Processing:
                return {
                    label: "In elaborazione",
                    color: "var(--sf-yellow)"
                }
            case OrderStatus.Succeeded:
                return {
                    label: "Completato",
                    color: "var(--sf-light-green)"
                }
            case OrderStatus.Failed:
                return {
                    label: "Errore",
                    color: "var(--sf-red)"
                }
            case OrderStatus.Refunded:
                return {
                    label: "Rimborsato",
                    color: "var(--sf-blue)"
                }
            case OrderStatus.WaitingForRefund:
                return {
                    label: "In attesa del rimborso",
                    color: "var(--sf-gray)"
                }
            case OrderStatus.PartiallyRefunded:
                return {
                    label: "Parzialmente rimborsato",
                    color: "var(--sf-orange)"
                }
            case OrderStatus.RefundFailed:
                return {
                    label: "Rimborso fallito",
                    color: "var(--sf-red)"
                }
            default: return null
        }
    }, [order])

    const canBeRefunded = useMemo(() => {
        return [
            OrderStatus.Succeeded,
            OrderStatus.RefundFailed,
            OrderStatus.PartiallyRefunded,
        ].includes(order?.status)
    }, [order])

    const refundAmount = useMemo(() => {
        return Object.values(refundingProducts)
            .filter(rp => rp.enabled)
            .map(rp => rp.amount)
            .reduce((a, c) => a += c, 0)
    }, [refundingProducts])

    const refundingProductsNumber = useMemo(() => {
        return Object.values(refundingProducts).filter(rp => rp.enabled).length
    }, [refundingProducts])

    const subscriptionDeleted = useMemo(() => { // TODO DECIDERE SE TENERLA
        return Object.values(refundingProducts).filter(rp => rp.deleteSubscription).length
    }, [refundingProducts])

    const hasRefunds = useMemo(() => {
        if (!order) return
        return [
            OrderStatus.Refunded,
            OrderStatus.PartiallyRefunded,
            OrderStatus.Processing,
            OrderStatus.Pending,
        ].includes(order.status)
    }, [order])

    const totalRefundAmount = useMemo(() => {
        if (!order) return
        return order.products.map(
            p => p.refunds.filter(r => r.status === "succeeded")
                .map(r => r.amount)
                .reduce((a, c) => a += c, 0)
        ).reduce((a, c) => a += c, 0)
    }, [order])

    useEffect(() => {
        if (refundMode && order) {
            const refundingProducts = {}
            for (const product of order.products) {
                const refundedAmount = product.refunds.filter(r => r.status === "succeeded")
                    .map(r => r.amount)
                    .reduce((a, c) => a += c, 0)

                const refundableAmount = Math.round((product.discounted_price - refundedAmount) * 100) / 100
                refundingProducts[product.id] = {
                    amount: refundableAmount,
                    enabled: refundableAmount > 0,
                    canBeRefunded: refundableAmount > 0,
                    deleteSubscription: false,
                    product,
                    refundableAmount,
                }
            }
            setRefundingProducts(refundingProducts)
        } else {
            setRefundingProducts({})
        }
    }, [refundMode])

    const onChangeRefundAmount = (value, productId) => {
        const product = order.products.find(o => o.id === productId)
        setRefundingProducts(rp => {
            if (product.discounted_price < value) {
                rp[productId].amount = product.discounted_price
            } else if (value < 0) {
                rp[productId].amount = 0
            } else {
                rp[productId].amount = value
            }
            return { ...rp }
        })
    }

    const refund = async () => {
        setRefundStatus(DialogStatus.Loading)
        try {
            const product_refunds = Object.values(refundingProducts).filter(p => p.enabled).map(p => ({
                product_id: p.product.id,
                amount: p.amount,
                cancel_subscription: p.deleteSubscription
            }))
            await api.post(`/admin/orders/${order.id}/refund`, product_refunds)
            setRefundStatus(DialogStatus.Success)
            onRefund()
        } catch (e) {
            console.error(e)
            setRefundStatus(DialogStatus.Error)
        }
    }

    const onDialogClose = () => {
        setOpenAlert(false)
        setRefundStatus(DialogStatus.Default)
    }

    return (
        <Card>
            <div className={styles.container}>
                {
                    loading === false &&
                    <>
                        <div className={styles.header}>
                            <div className={styles.ids}>
                                <div className={typo.caption}>{new Date(order.completed_at ?? order.created_at).toLocaleString("it-IT")}</div>
                                <div className={typo.caption}>{order.order_no}</div>
                                <div className={typo.caption}>stripe id: {order.intent_id}</div>
                            </div>
                            <StatusBadge {...status} />
                        </div>
                        <div className={styles.content}>
                            <div className={styles.student}
                                onClick={() => navigate(`/users/students/${order.user.id}`)}
                            >
                                <img className={styles.picture} src={picture} alt="" onError={() => setPicture(UserPlaceholder)} />
                                <div className={styles.info}>
                                    <div className={styles.name}>{order.user.name} {order.user.surname}</div>
                                    <div className={styles.email}>{order.user.email}</div>
                                </div>
                            </div>
                            <div> {order.products.length} {order.products.length === 0 || order.products.length > 1 ? "Prodotti" : "Prodotto"} </div>
                            {/* <div> Totale {formatPrice(order.products.map(p => p.discounted_price).reduce((a, c) => a += c, 0))} €</div> */}
                            <div> Totale {formatPrice(order.amount / 100)} €</div>
                            <Button
                                appearance="text"
                                onClick={() => setOpen(o => !o)}
                            >
                                {open ? "Nascondi dettagli" : "Mostra dettagli"}
                            </Button>
                        </div>
                        <SimpleCollapsable
                            expanded={open}
                        >
                            <div>
                                <div className={styles.headers}>
                                    {
                                        refundMode &&
                                        <div className={`${styles.includeRefund} ${styles.productHeaderSmall}`}>Includi nel rimborso</div>
                                    }
                                    <div className={`${styles.productIndex} ${styles.productHeader}`}>N.</div>
                                    <div className={`${styles.productName} ${styles.productHeader}`}>Prodotto</div>
                                    <div className={`${styles.productPrice} ${styles.productHeader}`}>Prezzo</div>
                                    <div className={`${styles.productDiscount} ${styles.productHeader}`}>Sconto</div>
                                    <div className={`${styles.productDiscountedPrice} ${styles.productHeader}`}>Totale</div>
                                    {
                                        hasRefunds &&
                                        <div className={`${styles.productRefundPrice} ${styles.productHeader}`}>Rimborsato</div>
                                    }
                                    {
                                        refundMode &&
                                        <>
                                            <div className={`${styles.refundAmount} ${styles.productHeader}`}>Rimborso</div>
                                            {/* <TooltipWrapper // TODO DECIDERE SE TENERLA
                                                className={styles.productHeaderSmall}
                                                style={{ background: "var(--background-color)" }}
                                                text={"Eliminando la sottoscrizione, l'utente non potrà più accedere al prodotto precedentemente acquistato."}
                                            >
                                                <div className={`${styles.deleteSubscription} ${styles.productHeaderSmall}`}>Elimina sottoscrizione</div>
                                            </TooltipWrapper> */}
                                        </>
                                    }
                                </div>
                                <div style={{ borderTop: "1px solid rgba(var(--text-color-rgb), 42%)", margin: "4px 0" }} />
                                <div>
                                    <Each
                                        of={order.products}
                                        render={(product, index) => (
                                            <div className={styles.product}>
                                                {
                                                    refundMode &&
                                                    <div className={styles.includeRefund}>
                                                        <Checkbox
                                                            size={"1.5rem"}
                                                            value={refundingProducts[product.id]?.enabled}
                                                            disabled={!refundingProducts[product.id]?.canBeRefunded}
                                                            onChange={v => setRefundingProducts(rf => {
                                                                rf[product.id].enabled = v
                                                                rf[product.id].deleteSubscription = v && rf[product.id].deleteSubscription
                                                                return { ...rf }
                                                            })}
                                                        />
                                                    </div>
                                                }
                                                <div className={styles.productIndex}>{index + 1}.</div>
                                                <div className={styles.productName}>{product.name}</div>
                                                <div className={styles.productPrice}>{formatPrice(product.price)}</div>
                                                <div className={styles.productDiscount}>{Math.round((product.price - product.discounted_price) / product.price * 100)}%</div>
                                                <div
                                                    className={styles.productDiscountedPrice}
                                                // style={{
                                                //     textDecoration: product.refunds.filter(r => r.status === "succeeded").length > 0 && !refundMode ? "line-through" : "none"
                                                // }}
                                                >
                                                    {formatPrice(product.discounted_price)}
                                                </div>
                                                {
                                                    hasRefunds &&
                                                    <div className={styles.productRefundPrice}>
                                                        {
                                                            formatPrice(product.refunds
                                                                .filter(r => r.status === "succeeded")
                                                                .map(r => r.amount)
                                                                .reduce((a, c) => a += c, 0))
                                                        }
                                                    </div>
                                                }
                                                {
                                                    refundMode &&
                                                    <>
                                                        <div className={styles.refundAmount}>
                                                            <TextInput
                                                                onKeyUp={(v) => onChangeRefundAmount(v, product.id)}
                                                                type="number"
                                                                numberType="float"
                                                                value={refundingProducts[product.id]?.amount}
                                                                min={0}
                                                                max={refundingProducts[product.id]?.refundableAmount}
                                                                style={{
                                                                    padding: ".2rem 0.5rem",
                                                                    maxWidth: "96px"
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <TooltipWrapper // TODO DECIDERE SE TENERLA
                                                            style={{ background: "var(--background-color)" }}
                                                            text={
                                                                !refundingProducts[product.id]?.enabled ?
                                                                    "Includi il prodotto nei rimborsi per poter eliminare la sottoscrizione" : ""
                                                            }
                                                        >
                                                            <div className={styles.deleteSubscription}>
                                                                <Checkbox
                                                                    disabled={!refundingProducts[product.id]?.enabled}
                                                                    value={refundingProducts[product.id]?.deleteSubscription}
                                                                    onChange={v => setRefundingProducts(rf => {
                                                                        rf[product.id].deleteSubscription = v
                                                                        return { ...rf }
                                                                    })}
                                                                />
                                                            </div>
                                                        </TooltipWrapper> */}
                                                    </>
                                                }
                                            </div>
                                        )}
                                    />
                                </div>
                                <div style={{ borderTop: "1px solid rgba(var(--text-color-rgb), 42%)", margin: "4px 0" }} />

                                <div className={styles.totalContainer}>
                                    <div className={styles.refundActions}>
                                        {
                                            canBeRefunded &&
                                            <Button
                                                style={{ padding: ".7rem 1rem" }}
                                                accentColor={refundMode ? "var(--sf-red)" : "var(--sf-yellow)"}
                                                inverse={refundMode}
                                                onClick={() => setRefundMode(r => !r)}
                                            >
                                                {!refundMode && "AVVIA RIMBORSO"}
                                                {refundMode && "ANNULLA"}
                                            </Button>
                                        }
                                        {
                                            refundMode &&
                                            <Button
                                                disabled={Object.values(refundingProducts).filter(p => p.enabled)?.length === 0}
                                                style={{ padding: ".7rem 1rem" }}
                                                onClick={() => setOpenAlert(true)}
                                            >
                                                RIMBORSA
                                            </Button>
                                        }
                                        {
                                            refundMode &&
                                            <div className={styles.totalContent}>
                                                <div className={styles.totalHeaders}>
                                                    <div>Prodotti da rimborsare</div>
                                                    {/* <div>Sottoscrizioni eliminate</div> // TODO DECIDERE SE TENERLA*/}
                                                    <div>Totale rimborso</div>
                                                </div>
                                                <div className={styles.totalValues} style={{ textAlign: "start" }}>
                                                    <div>{refundingProductsNumber}</div>
                                                    {/* <div>{subscriptionDeleted}</div> // TODO DECIDERE SE TENERLA*/}
                                                    <div>{formatPrice(refundAmount)} €</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className={styles.totalContent}>
                                        <div className={styles.totalHeaders}>
                                            <div>Subtotale</div>
                                            <div>Sconti</div>
                                            <div>Totale</div>
                                            {
                                                totalRefundAmount > 0 &&
                                                <div>Rimborsato</div>
                                            }
                                        </div>
                                        <div className={styles.totalValues}>
                                            <div>{formatPrice(order.products.map(p => p.price).reduce((a, c) => a += c, 0))} €</div>
                                            <div>{formatPrice(order.products.reduce((a, c) => {
                                                const { price, discounted_price } = c
                                                a += price - discounted_price
                                                return a
                                            }, 0))} €
                                            </div>
                                            <div>{formatPrice(order.products.map(p => p.discounted_price).reduce((a, c) => a += c, 0))} €</div>
                                            {
                                                totalRefundAmount > 0 &&
                                                <div>{formatPrice(totalRefundAmount)} €</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SimpleCollapsable>
                    </>
                }
                {
                    loading &&
                    <>
                        <div className={styles.header}>
                            <div className={styles.ids}>
                                <Skeleton height={"13px"} width={"96px"} borderRadius={"8px"} />
                                <Skeleton height={"13px"} width={"124px"} borderRadius={"8px"} />
                                <Skeleton height={"13px"} width={"196px"} borderRadius={"8px"} />
                            </div>
                            <div className={styles.statusContainer}>
                                <Skeleton height={"23px"} width={"100px"} borderRadius={"8px"} />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.student}>
                                <Skeleton height={"48px"} width={"48px"} type="circle" />
                                <div className={styles.info} style={{ gap: ".5rem" }}>
                                    <Skeleton height={"18px"} width={"190px"} borderRadius={"8px"} />
                                    <Skeleton height={"12px"} width={"164px"} borderRadius={"8px"} />
                                </div>
                            </div>
                            <Skeleton width={"78px"} height={"22px"} borderRadius={"8px"} />
                            <Skeleton width={"115px"} height={"22px"} borderRadius={"8px"} />
                            <Skeleton width={"120px"} height={"20px"} borderRadius={"8px"} />
                        </div>
                    </>
                }
                {
                    openAlert &&
                    <AlertDialog
                        dialogProps={{
                            contentMaxHeight: "80dvh",
                            style: { maxWidth: "60dvw" }
                        }}
                        open={openAlert}
                        title={"Rimborsa prodotti"}
                        text={
                            (
                                <div className={styles.refundRecap}>
                                    Sei sicuro di voler rimborsare i seguenti prodotti?
                                    <div className={styles.headers}>
                                        <div className={`${styles.productIndex} ${styles.productHeader}`}>N.</div>
                                        <div className={`${styles.productName} ${styles.productHeader}`}>Prodotto</div>
                                        <div className={`${styles.productPrice} ${styles.productHeader}`}>Prezzo</div>
                                        <div className={`${styles.productPrice} ${styles.productHeader}`}>Rimborso</div>
                                    </div>
                                    <div style={{ borderTop: "1px solid rgba(var(--text-color-rgb), 42%)", margin: "4px 0" }} />
                                    <div className={styles.products}>
                                        <Each
                                            of={Object.values(refundingProducts).filter(p => p.enabled)}
                                            render={(refundProduct, index) => (
                                                <div className={styles.product}>
                                                    <div className={styles.productIndex}>{index + 1}.</div>
                                                    <div className={styles.productName}>{refundProduct.product.name}</div>
                                                    <div className={styles.productPrice}>{formatPrice(refundProduct.product.discounted_price)}</div>
                                                    <div className={styles.productPrice}>{formatPrice(refundProduct.amount)}</div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.totalContent} style={{ marginTop: "1rem" }}>
                                        <div className={styles.totalHeaders}>
                                            <div>Prodotti</div>
                                            {/* <div>Sottoscrizioni eliminate</div> */}
                                            <div>Totale rimborso</div>
                                        </div>
                                        <div className={styles.totalValues} style={{ textAlign: "start" }}>
                                            <div>{refundingProductsNumber}</div>
                                            {/* <div>{subscriptionDeleted}</div> */}
                                            <div>{formatPrice(refundAmount)} €</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        status={refundStatus}
                        onClose={onDialogClose}
                        actions={[
                            {
                                label: refundStatus === DialogStatus.Success ? "OK" : "SI, RIMBORSA",
                                onClick: refundStatus === DialogStatus.Success ? onDialogClose : refund,
                            }
                        ]}
                    />
                }
            </div>
        </Card>
    )

}

export default OrderCard
