import { useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/icons/ic-support.svg";
import { ReactComponent as RatingStarIcon } from "../../assets/images/icons/ic-trustpilot-star.svg";
import { ReactComponent as TrustpilotLogo } from "../../assets/images/trustpilot.svg";
import { Each } from "../../common/Each";
import VideoPlaceholder from "../../assets/images/placeholders/video-placeholder.png"
import MainContext from "../../common/MainContext";
import { CourseType, TagType, palette } from "../../common/constants";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import AutoplayCarousel from "../AutoplayCarousel";
import Button from "../Button";
import CourseBadge from "../CourseBadge";
import FAQAccordions from "../FAQAccordions";
import ProgramTimeline from "../ProgramTimeline";
import ReviewsCarousel from "../ReviewsCarousel";
import TeachersBadge from "../TeachersBadge";
import TeachersCarousel from '../TeachersCarousel';
import CourseInfoCard from "../cards/CourseInfoCard";
import EditionLessonsCard from "../cards/EditionLessonsCard";
import typo from "../../typography.module.css";
import { formatPrice } from "../../utils";
import styles from "./CoursePreview.module.css";

const CoursePreview = ({ course, teachers, partners, editions }) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const context = useContext(MainContext)

    const [discount, setDiscount] = useState(null)
    const [overviewRef, isInView] = useInView({
        triggerOnce: false,
        rootMargin: '-70px 0px',
    })

    useEffect(() => {
        if (course) {
            if (course.product) {
                let discount = course.product.discounted_price > 0 ? Math.round((course.product.price - course.product.discounted_price) / course.product.price * 100) : null
                setDiscount(discount)
            }
        }
    }, [course])

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{course ? course.name : 'Corso'}</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {course &&
                    <>
                        <div className={styles.section} ref={overviewRef} style={{ paddingBottom: '0' }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.overview}>
                                    <div className={styles.playerWrapper}>
                                        {
                                            course.video_url &&
                                            <ReactPlayer
                                                url={course.video_url}
                                                controls
                                                width="100%"
                                                height="100%"
                                                className={styles.player}
                                            />
                                        }
                                        {
                                            !course.video_url &&
                                            <img src={VideoPlaceholder} alt="video placeholder" style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                borderRadius: "12px"
                                            }} />
                                        }

                                    </div>
                                    <CourseBadge type={course.type}></CourseBadge>
                                    <div className={typo.subtitle}>{course.name}</div>
                                    <div className={typo.body}>{course.description}</div>
                                    <div className={styles.featureRow}>
                                        <div className={styles.feature}>
                                            <ClockIcon></ClockIcon> {course.duration} ore
                                        </div>
                                        <Each of={course.tags} render={(tag, index) => {
                                            if (tag.type === TagType.Feature) {
                                                return (
                                                    <div className={styles.feature}>
                                                        <img src={tag.icon} alt={tag.name} /> {tag.name}
                                                    </div>
                                                )
                                            }
                                        }} />
                                    </div>
                                    <div className={styles.priceRow}>
                                        {discount &&
                                            <>
                                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.discounted_price)}€</div>
                                                <div className={styles.discount}><div>{formatPrice(course.product.price)}€</div> {t('masterclass.discountOf')} {discount}%</div>
                                            </>
                                        }
                                        {!discount &&
                                            <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.price)}€</div>
                                        }
                                    </div>
                                    <div className={styles.actionWrapper}>
                                        <div className={styles.actionRow}>
                                            <div>
                                                <Button
                                                    style={{ fontSize: '1.15rem', width: '100%', padding: '.5rem' }}
                                                    onClick={() => {

                                                    }}>
                                                    <CartIcon></CartIcon>
                                                    ACQUISTA
                                                </Button>
                                            </div>
                                            <div>
                                                <Button additionalClass={styles.supportButton} style={{ fontSize: '1.15rem', width: '100%' }}>
                                                    <SupportIcon></SupportIcon>{t('courses.contact').toUpperCase()}
                                                </Button>
                                            </div>
                                        </div>
                                        {teachers &&
                                            <TeachersBadge teachers={teachers} showQualification
                                                style={{ justifyContent: width > 768 ? 'flex-end' : 'flex-start', width: 'fit-content' }}
                                                pictureStyle={{
                                                    width: "56px",
                                                    height: "56px",
                                                    backgroundColor: "var(--background-color)",
                                                }}
                                                nameStyle={{ fontSize: "1.15rem" }}
                                            />
                                        }
                                    </div>

                                    <div className={styles.classesRow}>
                                        <div className={typo.headline} style={{ fontSize: '1rem', textTransform: 'uppercase', paddingTop: '.5rem' }}>date disponibili</div>
                                        {editions && editions.length > 0 &&
                                            <div className={typo.headline} style={{ fontWeight: 600 }}>
                                                L'acquisto è valido per una delle opzioni di seguito. Potrai selezionare le date e iscriverti dalla sezione 'Il mio percorso' subito dopo l'acquisto.
                                            </div>
                                        }

                                        <div className={styles.groups}>
                                            {editions &&
                                                <Each of={editions.reverse()} render={(edition) => {
                                                    return (
                                                        <>
                                                            {edition.modules.flatMap(module => module.lessons).length > 0 &&
                                                                <div className={styles.groupCard}>
                                                                    <EditionLessonsCard edition={edition} active={false} style={{ cursor: 'default' }} />
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                }} />
                                            }
                                            {editions && editions.length === 0 &&
                                                <div className={typo.headline}>Al momento non ci sono edizioni a cui puoi iscriverti. Torna a trovarci, presto ne arriveranno altre!</div>
                                            }

                                        </div>
                                    </div>
                                    {partners && partners.length > 0 &&
                                        <AutoplayCarousel elements={partners.map(p => p.logo)} style={{ height: '100px', borderTop: '1px solid rgba(var(--text-color-rgb), 12%)' }} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.stickyBar} ${isInView ? styles.hidden : ''}`}>
                            {!isInView &&
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {width >= 1024 &&
                                            <CourseBadge type={course.type} style={{ fontSize: '.5rem' }}></CourseBadge>
                                        }
                                        <div className={typo.subtitle} style={{ fontSize: '1rem' }}>{course.name}</div>

                                    </div>
                                    <div>
                                        <Button style={{
                                            fontSize: width >= 768 ? '1rem' : '0.7rem',
                                            padding: width >= 768 ? '.6rem 1rem' : '.3rem .6rem',
                                            gap: width >= 768 ? '.5rem' : '.2rem'
                                        }}
                                            onClick={() => {

                                            }}
                                        >
                                            <CartIcon style={{ width: width >= 768 ? '20px' : '16px' }} />
                                            ACQUISTA
                                            <div style={{ paddingLeft: '2px' }}>{formatPrice(course.product.discounted_price ? course.product.discounted_price : course.product.price)}€</div>
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>

                        {/* {!isInView &&
                            <div className={styles.floatingSupport}>
                                <Button additionalClass={styles.floatingSupportButton} style={{ fontSize: '1.15rem', width: '100%', padding: '.5rem 2rem', fontWeight: '600' }}>
                                    {width > 768 && t('masterclass.questions')}
                                    <SupportIcon></SupportIcon>
                                </Button>
                            </div>
                        } */}

                        <div className={styles.section} style={{ background: 'transparent', marginTop: width > 768 ? '-80px' : '-60px' }}>
                            <div className={styles.sectionInner}>
                                <div className={typo.title} style={{ fontSize: '1.15rem' }}>
                                    {t('masterclass.whyPartecipate')}
                                </div>
                                <div id="info-cards" className={styles.infoCards}>
                                    <Each of={course.info} render={(info, index) => {
                                        const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                                        if (!info.color) {
                                            info.color = palette[colorIndex];
                                        }
                                        return (
                                            <div
                                                className={styles.infoCard}>
                                                <CourseInfoCard info={info} />
                                            </div>
                                        )
                                    }} />
                                </div>
                            </div>
                        </div>

                        {course.program &&
                            <div className={styles.section} style={{ background: 'transparent' }}>
                                <div className={styles.sectionInner}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem', width: '100%' }}>
                                        {t('masterclass.program')}
                                    </div>
                                    <div className={styles.program}>
                                        <ProgramTimeline hideTimeline={course.type === CourseType.Masterclass} program={course.program} />
                                    </div>
                                </div>
                            </div>
                        }

                        {course.reviews && course.reviews.length > 0 &&
                            <div className={`${styles.section} ${styles.reviews}`}>
                                <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem', textAlign: 'center' }}>
                                        {t('professional.reviews')}
                                    </div>
                                    <div className={styles.reviewSubtitle}>
                                        <div>
                                            TrustScore di <strong>4.8</strong> su 5.0
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '2px' }}>
                                            <RatingStarIcon style={{ color: 'var(--primary)', height: '20px', width: '20px' }} />
                                            <TrustpilotLogo style={{ transform: 'translateY(2px)' }} />
                                        </div>
                                    </div>
                                    <div className={styles.reviewsCards}>
                                        <ReviewsCarousel reviews={course.reviews} />
                                    </div>
                                </div>
                            </div>
                        }

                        {teachers && teachers.length === 1 &&
                            <div className={styles.section} style={{ background: 'transparent' }}>
                                <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem' }}>
                                        {t('masterclass.teacher')}
                                    </div>
                                    <div className={styles.teachersDetail}>
                                        <img className={styles.teacherDetailPicture} src={teachers[0].picture} alt={`${teachers[0].surname}`} />
                                        <div className={styles.teacherDetailInfo}>
                                            <div className={styles.teacherDetailName}>
                                                {teachers[0].name} {teachers[0].surname}
                                            </div>
                                            <div className={styles.teacherDetailQualification}>
                                                {teachers[0].qualification}
                                            </div>
                                            <div className={styles.teacherDetailTags}>
                                                <Each of={teachers[0].tags} render={(tag, index) => {
                                                    return (
                                                        <div className={styles.teacherDetailTag} style={{ color: tag.color, backgroundColor: `${tag.color}33` }}>
                                                            {t(`tags.${tag.name}`).toUpperCase()}
                                                        </div>
                                                    )
                                                }} />
                                            </div>
                                            <div className={styles.teacherDetailDescription}>
                                                {teachers[0].description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {teachers && teachers.length > 1 &&
                            <div className={`${styles.section} ${styles.teachers}`} style={{ backgroundColor: 'var(--background-secondary-color)' }}>
                                <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem', textAlign: 'center' }}>
                                        {t('professional.teachers')}
                                    </div>
                                    <div className={typo.body} style={{ fontSize: '1rem', textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}>
                                        {t('professional.teachersDesc')}
                                    </div>

                                    <div className={styles.teachersCards}>
                                        <TeachersCarousel teachers={teachers} />
                                    </div>

                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <Button accentColor="var(--tertiary)">SCOPRI DI PIÙ</Button>
                                    </div>
                                </div>
                            </div>
                        }

                        {course.faq && course.faq.length > 0 &&
                            <div className={styles.section}>
                                <div className={styles.sectionInner}>
                                    <div className={typo.title} style={{ fontSize: '1.15rem', width: '100%', textAlign: 'center' }}>
                                        FAQs
                                    </div>
                                    <div className={styles.program}>
                                        <FAQAccordions faq={course.faq} />
                                    </div>
                                </div>
                            </div>
                        }

                    </>
                }
            </div>
        </>
    )
}

export default CoursePreview
