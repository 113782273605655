import { useEffect, useMemo, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import ReactMarkdown from "react-markdown"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { ReactComponent as AgeIcon } from "../assets/images/icons/ic-age.svg"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CityIcon } from "../assets/images/icons/ic-city.svg"
import { ReactComponent as EmailIcon } from "../assets/images/icons/ic-email.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import { SurveyType } from "../common/constants"
import { Each } from "../common/Each"
import Back from "../components/Back"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import { range } from "../utils"
import styles from "./StudentSurvey.module.css"

const StudentSurvey = ({ }) => {

    const { survey_id, students_id } = useParams()
    const [loading, setLoading] = useState(false)
    const [survey, setSurvey] = useState(null)
    const navigate = useNavigate()

    const groups = useMemo(() => {
        if (!survey) {
            return []
        }

        const g = {}
        const { content, userAnswers } = survey

        for (const question of content) {
            if (!g[question.group]) {
                g[question.group] = []
            }

            const userAnswer = userAnswers.find(ua => ua.id === question.id)
            if (!userAnswer) {
                continue
            }

            switch (question.type) {
                case SurveyType.Open:
                    question.userAnswer = userAnswer.value; break
                case SurveyType.SingleChoice:
                    question.options[userAnswer.answerId].selected = true;
                    question.options[userAnswer.answerId].answerValue = userAnswer.answer.answerValue;
                    question.options[userAnswer.answerId].type = userAnswer.answer.type;
                    break
                case SurveyType.Numeric:
                    question.userAnswer = { id: userAnswer.answerId, value: userAnswer.answer }; break
                case SurveyType.MultipleChoice:
                    for (let i = 0; i < question.options.length; i++) {
                        const ua = userAnswer.answers.find(ua => ua.id === i)
                        if (ua) {
                            question.options[i].selected = true
                            question.options[i].answerValue = ua.answerValue;
                            question.options[i].type = ua.type;
                        }
                    }
                    break
                default: break
            }
            g[question.group].push(question)
        }

        return g
    }, [survey])

    useEffect(() => {
        const getSurvey = async () => {
            setLoading(true)
            try {
                const survey = await api.get(`/admin/surveys/${survey_id}/users/${students_id}`)
                setSurvey(survey)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        getSurvey()
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Sondaggio</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.back}>
                            <Back onClick={() => navigate(`/surveys/${survey_id}`)} />
                        </div>
                        {
                            loading &&
                            <button className={styles.user} >
                                <Skeleton type="circle" width="128px" height="128px" />
                                <div className={styles.userInfo} style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Skeleton type="rect" width="256px" height="32px" borderRadius="8px" />
                                    <Skeleton type="rect" width="192px" height="16px" borderRadius="8px" />
                                    <Skeleton type="rect" width="192px" height="16px" borderRadius="8px" />
                                    <Skeleton type="rect" width="192px" height="16px" borderRadius="8px" />
                                    <Skeleton type="rect" width="192px" height="16px" borderRadius="8px" />
                                </div>
                            </button>
                        }
                        {
                            !loading &&
                            <button className={styles.user} onClick={() => navigate(`/users/students/${survey?.user?.id}`)}>
                                {
                                    survey?.user.picture &&
                                    <img className={styles.picture} src={survey?.user?.picture} alt='' onError={({ currentTarget }) => currentTarget.src = UserPlaceholder} />
                                }
                                <div className={styles.userInfo}>
                                    <div className={styles.username}>{survey?.user?.name} {survey?.user?.surname}</div>
                                    <div className={styles.attribute}>
                                        <div className={styles.label}>
                                            <EmailIcon className={styles.icon} />
                                            email
                                        </div>
                                        <div className={styles.attributeValue}>{survey?.user?.email}</div>
                                    </div>
                                    <div className={styles.attribute}>
                                        <div className={styles.label}>
                                            <CalendarIcon className={styles.icon} />
                                            data creazione
                                        </div>
                                        <div className={styles.attributeValue}>{new Date(survey?.user?.created_at).toLocaleString("it-IT")}</div>
                                    </div>
                                    <div className={styles.attribute}>
                                        <div className={styles.label}>
                                            <AgeIcon className={styles.icon} />
                                            età
                                        </div>
                                        <div className={styles.attributeValue}>{survey?.user?.age}</div>
                                    </div>
                                    <div className={styles.attribute}>
                                        <div className={styles.label}>
                                            <CityIcon className={styles.icon} />
                                            città
                                        </div>
                                        <div className={styles.attributeValue}>{survey?.user?.city}</div>
                                    </div>
                                </div>
                            </button>
                        }
                    </div>
                    <div className={styles.sectionInner}>
                        <Each
                            of={Object.keys(groups)}
                            render={(group) => (
                                <div className={styles.group}>
                                    <div className={styles.groupName}>{group}</div>
                                    <Each
                                        of={groups[group]}
                                        render={(question) => (
                                            <div className={styles.groupContent}>
                                                <div className={styles.title}>{question.text}</div>
                                                {
                                                    question.type === SurveyType.SingleChoice &&
                                                    <Each
                                                        of={question.options}
                                                        render={(option, index) => (
                                                            <div className={styles.answerWrapper}>
                                                                <div className={styles.answerContainer}>
                                                                    <div className={styles.answerSection}>
                                                                        <div className={
                                                                            option.selected ?
                                                                                `${styles.answer} ${styles.selected}` :
                                                                                styles.answer
                                                                        }>
                                                                            <ReactMarkdown>{`${option.value}`}</ReactMarkdown>
                                                                            <div>
                                                                                {option.type === "open" && option.answerValue}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                }
                                                {
                                                    question.type === SurveyType.MultipleChoice &&
                                                    <Each
                                                        of={question.options}
                                                        render={(option, index) => (
                                                            <div className={styles.answerWrapper}>
                                                                <div className={styles.answerContainer}>
                                                                    <div className={styles.answerSection}>
                                                                        <div className={
                                                                            option.selected ?
                                                                                `${styles.answer} ${styles.selected}` :
                                                                                styles.answer
                                                                        }>
                                                                            <ReactMarkdown>{`${option.value}`}</ReactMarkdown>
                                                                            <div>
                                                                                {option.type === "open" && option.answerValue}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                }
                                                {
                                                    question.type === SurveyType.Open &&
                                                    <div className={styles.openAnswer}>
                                                        {question.userAnswer}
                                                    </div>
                                                }

                                                {
                                                    question.type === SurveyType.Numeric &&
                                                    <div className={styles.numeric}>
                                                        <Each
                                                            of={range(question.min, question.max, question.step).map(i => ({ value: i, selected: (question.userAnswer.id === i) }))}
                                                            render={(option, optionIdx) => (
                                                                <div className={
                                                                    option.selected ?
                                                                        `${styles.numericOption} ${styles.selected}` :
                                                                        styles.numericOption
                                                                }>
                                                                    {option.value}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    />

                                </div>
                            )}
                        />
                    </div>
                </div>
            </div >
        </HeaderFooterLayout >
    )

}

export default StudentSurvey