import { useCallback, useEffect, useState } from "react"
import Button from "../Button"
import DropdownSelection from "../DropdownSelection"
import Dialog from "./Dialog"
import styles from "./UserCourseDialog.module.css"
import api from "../../api"
import Spinner from "../Spinner"
import { DialogStatus } from "../../enums"
import AlertDialog from "./AlertDialog"

const UserCourseDialog = ({ user, courses, onClose, course_id }) => {

    const [coursesOptions, setCoursesOptions] = useState( Array.from(
        courses.reduce((map, c) => {
          if (!map.has(c.id)) {
            map.set(c.id, { id: c.id, label: c.name });
          }
          return map;
        }, new Map()).values()
      ))
    const [editions, setEditions] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedEdition, setSelectedEdition] = useState(null)
    const [loadingEditions, setLoadingEditions] = useState(false)
    const [alert, setAlert] = useState({
        open: false,
        title: "",
        message: "",
        status: DialogStatus.Default,
        actions: []
    })
    const [dialogTimeout, setDialogTimeout] = useState(null)

    useEffect(() => {
        return () => {
            if (dialogTimeout) {
                clearTimeout(dialogTimeout)
            }
        }
    }, [])

    useEffect(() => {
        setCoursesOptions(
            Array.from(
              courses.reduce((map, c) => {
                if (!map.has(c.id)) {
                  map.set(c.id, { id: c.id, label: c.name });
                }
                return map;
              }, new Map()).values()
            )
          );
    }, [courses])

    useEffect(() => {
        if(course_id){
            setSelectedCourse(courses.find(c => c.id === course_id))
        }
    }, [course_id, courses])

    const getEditions = async (course_id) => {
        setLoadingEditions(true)
        try {
            let editions = await api.get(`/admin/courses/${course_id}/editions`)
            setEditions(editions
                .filter(e => {
                    return !user.courses.map(c => c.edition?.id).includes(e.id) && e.status === "public"
                })
                .sort((a, b) => a.created_at < b.created_at)
            )
        } catch (e) {
            console.error(e)
        }
        setLoadingEditions(false)
    }

    const subscribe = async () => {
        try {
            selectedEdition ?
                await api.post(`/admin/users/${user.id}/courses/${selectedCourse.id}/editions/${selectedEdition.id}${course_id ? `?updating_edition=${selectedCourse.edition.id}` : ''}`) :
                await api.post(`/admin/users/${user.id}/courses/${selectedCourse.id}`)

            setSelectedCourse(null)
            setSelectedEdition(null)
            setAlert(a => {
                return {
                    ...a,
                    actions: [],
                    status: DialogStatus.Success,
                }
            })
            setDialogTimeout(setTimeout(() => {
                resetAlert()
                onClose()
            }, 2000))
        } catch (e) {
            console.error(e)
        }
    }

    const resetAlert = useCallback(() => {
        setAlert({
            open: false,
            title: "",
            message: "",
            status: DialogStatus.Default,
            actions: []
        })
    }, [])

    const openConfirmDialog = (selectedCourse, selectedEdition) => {
        setAlert({
            open: true,
            title: `Confermi l'iscrizione ?`,
            message: !selectedEdition ? `Confermi di voler iscrivere ${user.name} ${user.surname} al corso di ${selectedCourse.name}?` : `Confermi di voler iscrivere ${user.name} ${user.surname} al corso di ${selectedCourse.name}, ${selectedEdition.name}?` ,
            status: DialogStatus.Default,
            actions: [
                {
                    label: 'ANNULLA',
                    onClick: () => {
                        setAlert((prev) => ({...prev, open: false}))
                        onClose()
                    }
                },
                {
                    label: 'CONFERMA',
                    onClick: () => {
                        subscribe()
                    }
                }
            ]
        })
    }

    return (
        <>
            <Dialog
                open={true}
                title={"Iscrivi ad un corso"}
                style={{ maxWidth: "512px", minHeight: "400px" }}
                overflow="visible"
                onClose={onClose}
                action={
                    <Button onClick={() => {
                        openConfirmDialog(selectedCourse, selectedEdition)
                    }} disabled={!selectedCourse}>
                        {!selectedEdition ? 'ISCRIVI AL CORSO' : `ISCRIVI ALL'EDIZIONE`}
                    </Button>
                }
            >
                <div className={styles.dialog}>
                    <DropdownSelection
                        disabled={courses.length === 0 || course_id}
                        placeholder={"Scegli il corso"}
                        defaultOption={course_id ? coursesOptions.find(c => c.id === course_id)?.id : null}
                        required={course_id}
                        options={coursesOptions}
                        appereance="transparent"
                        onSelect={(id) => {
                            setSelectedCourse(courses.find(c => course_id ? c.id === course_id : c.id === id))
                            setSelectedEdition(null)
                            setEditions([])
                            if (courses.find(c => c.id === id)) {
                                getEditions(id)
                            }
                        }} />

                    {selectedCourse && editions.length > 0 &&
                        <DropdownSelection
                            disabled={!selectedCourse || loadingEditions}
                            placeholder={"Scegli un'edizione"}
                            defaultOption={null}
                            options={editions.map(e => { return { id: e.id, label: e.name } })}
                            appereance="transparent"
                            onSelect={(id) => {
                                setSelectedEdition(editions.find(e => e.id === id))
                            }} />
                    }
                    {loadingEditions &&
                        <div className={styles.spinnerContainer}>
                            <Spinner color="black" /> Caricando le edizioni.
                        </div>
                    }
                    {selectedCourse && !loadingEditions && editions.length === 0 &&
                        <div className={styles.spinnerContainer}>
                            Nessuna edizione disponibile.
                        </div>
                    }
                </div>
            </Dialog>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.message}
                onClose={alert.onClose ?? resetAlert}
                actions={alert.actions}
                status={alert.status}
            />
        </>

    )
}

export default UserCourseDialog