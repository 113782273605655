import { useNavigate, useParams } from "react-router-dom"
import Back from "../components/Back"
import styles from "./CourseReports.module.css"
import typo from "../typography.module.css"
import { useEffect, useState } from "react"
import api from "../api"

const CourseReports = () => {

    const navigate = useNavigate()
    const { courseId } = useParams()
    const [course, setCourse] = useState(null)

    useEffect(() => {

        const getCourse = async () => {
            try {
                let course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        if(courseId && !course){
            getCourse()
        }

    }, [courseId])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                {course &&
                    <div className={styles.sectionInner}>
                        <Back onClick={() => {
                            navigate('/reports/teaching')
                        }} />
                        <div className={typo.title}>{`Report > Didattica > ${course.name}`}</div>

                    </div>
                }
            </div>
        </div>
    )

}

export default CourseReports