import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import api from "../api";
import { TimeFilter, TimeGroup } from "../common/constants";
import { Each } from "../common/Each";
import MainContext from "../common/MainContext";
import StatisticsCard from "../components/cards/StatisticCard";
import UsersChart from "../components/charts/UsersChart";
import CourseBadge from "../components/CourseBadge";
import SimpleCollapsable from "../components/SimpleCollapsable";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import TabItem from "../components/TabItem";
import typo from "../typography.module.css";
import { capitalize, formatPrice, secondsToHours } from "../utils";
import styles from "./Reports.module.css";
import Button from "../components/Button";
import CourseProfitChart from "../components/charts/CourseProfitChart";
import OrdersChart from "../components/charts/OrdersChart";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const { t } = useTranslation();
  const context = useContext(MainContext)
  const navigate = useNavigate()

  const [users, setUsers] = useState({
    loading: false,
    filter: null,
    group: TimeGroup.Monthly,
    total: 0,
    trend: [],
    buyers: 0,
    buyersTrend: [],
    registeredToEdition: 0,
    registeredToEditionTrend: [],
    percentageOfConversion: 0,
  })

  const [orders, setOrders] = useState({
    loading: false,
    total: 0,
    completed: 0,
    pending: 0,
    failed: 0,
    amount: 0,
    processing: 0,
    refunded: 0,
    waiting_for_refund: 0,
    partially_refunded: 0,
    refund_failed: 0,
    trend: [],
    succeededTrend: [],
    filter: null,
    group: TimeGroup.Daily
  })

  const usersGroupTabs = useMemo(() => {
    return [
      { id: TimeGroup.Daily, text: "Giornaliero", color: "var(--tertiary-rgb)", onClick: () => setUsers(u => ({ ...u, group: TimeGroup.Daily })) },
      { id: TimeGroup.Monthly, text: "Mensile", color: "var(--tertiary-rgb)", onClick: () => setUsers(u => ({ ...u, group: TimeGroup.Monthly })), isVisible: () => users.filter === null },
    ]
  }, [users.filter])

  const ordersGroupTabs = useMemo(() => {
    return [
      { id: TimeGroup.Daily, text: "Giornaliero", color: "var(--tertiary-rgb)", onClick: () => setOrders(o => ({ ...o, group: TimeGroup.Daily })) },
      { id: TimeGroup.Monthly, text: "Mensile", color: "var(--tertiary-rgb)", onClick: () => setOrders(o => ({ ...o, group: TimeGroup.Monthly })), isVisible: () => orders.filter === null },
    ]
  }, [orders.filter])

  const filterTabs = useMemo(() => {
    return [
      { id: TimeFilter.Today, text: "Oggi", color: "var(--primary-rgb)", tag: "all" },
      { id: TimeFilter.CurrentMonth, text: "Questo mese", color: "var(--primary-rgb)", tag: "all" },
      { id: TimeFilter.Last7Days, text: "Ultimi 7 giorni", color: "var(--primary-rgb)", tag: "past" },
      { id: TimeFilter.Last30Days, text: "Ultimi 30 giorni", color: "var(--primary-rgb)", tag: "past" },
      { id: TimeFilter.Next7Days, text: "Prossimi 7 giorni", color: "var(--primary-rgb)", tag: "future" },
      { id: TimeFilter.Next30Days, text: "Prossimi 30 giorni", color: "var(--primary-rgb)", tag: "future" },
    ]
  }, [])

  useEffect(() => {
    getUserStatistics()
  }, [users.filter, users.group])

  useEffect(() => {
    getOrders()
  }, [orders.filter, orders.group])

  const getUserStatistics = useCallback(async () => {
    setUsers(u => ({ ...u, loading: true }))
    try {
      const response = await api.get(`/admin/users/statistics?group=${users.group}${users.filter ? `&filter=${users.filter}` : ""}`)
      setUsers((u) => (
        {
          ...u,
          ...response,
          percentageOfConversion: Math.round(response.buyers.count / response.total.count * 100 * 100) / 100
        }
      ))
    } catch (e) {
      console.error(e)
    }
    setUsers(u => ({ ...u, loading: false }))
  }, [users.group, users.filter])

  const getOrders = useCallback(async () => {
    setOrders(o => ({ ...o, loading: true }))
    try {
      const response = await api.get(`/admin/orders/statistics?group=${orders.group}${orders.filter ? `&filter=${orders.filter}` : ""}`)
      const { pending, succeeded, processing, failed } = response.orders
      setOrders(o => ({
        ...o,
        ...response.orders,
        total: pending + succeeded + processing + failed,
        amount: response.amount.sum,
        trend: response.trend,
        succeededTrend: response.succeeded_trend
      }))
    } catch (e) {
      console.error(e)
    }
    setOrders(o => ({ ...o, loading: false }))
  }, [orders.group, orders.filter])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            Report
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Report</div>
            <div className={styles.sectionCard}>
              <div className={styles.header}>
                <div className={typo.subheadline}>Utenti</div>
                <div className={styles.filter}>
                  <Each
                    of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                    render={(item) => (
                      <TabItem
                        selected={users.filter === item.id}
                        text={capitalize(item.text)}
                        onClick={() => {
                          setUsers(u => {
                            if (u.filter === item.id) {
                              return { ...u, filter: null }
                            }
                            return { ...u, filter: item.id, group: TimeGroup.Daily }
                          })
                        }}
                        color={item.color}
                        style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                        defaultBackground="var(--background-secondary-color)"
                      />
                    )
                    }
                  />
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.cards}>
                  <StatisticsCard
                    label="Utenti"
                    value={users.total.count}
                  />
                  <StatisticsCard
                    label="Acquirenti"
                    value={users.buyers.count}
                  />
                  <StatisticsCard
                    label="Iscritti a corsi"
                    value={users.registeredToEdition.count}
                  />
                  <StatisticsCard
                    label="Tasso di conversione"
                    value={isNaN(users.percentageOfConversion) ? 0 : users.percentageOfConversion}
                    format={(v) => `${v}%`}
                  />
                </div>
                <div className={styles.column} style={{ position: "relative" }}>
                  <div className={styles.group}>
                    <Each
                      of={usersGroupTabs.filter(t => (!t.isVisible || t.isVisible()))}
                      render={(item) => (
                        <TabItem
                          selected={users.group === item.id}
                          text={capitalize(item.text)}
                          onClick={item.onClick}
                          color={item.color}
                          style={{ padding: ".5rem", fontSize: ".875rem" }}
                          defaultBackground="var(--background-secondary-color)"
                        />
                      )
                      }
                    />
                  </div>
                  <UsersChart data={{ total: users.trend, buyers: users.buyersTrend, registeredToEdition: users.registeredToEditionTrend }} group={users.group} loading={users.loading} />
                </div>
              </div>
            </div>
            {/* <div className={styles.sectionCard}>
              <div className={styles.header}>
                <div className={typo.subheadline}>Ordini</div>
                <div className={styles.filter}>
                  <Each
                    of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                    render={(item) => (
                      <TabItem
                        selected={orders.filter === item.id}
                        text={capitalize(item.text)}
                        onClick={() => {
                          setOrders(o => {
                            if (o.filter === item.id) {
                              return { ...o, filter: null }
                            }
                            return { ...o, filter: item.id, group: TimeGroup.Daily }
                          })
                        }}
                        color={item.color}
                        style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                        defaultBackground="var(--background-secondary-color)"
                      />
                    )
                    }
                  />
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.cards}>
                  <StatisticsCard
                    label="Totale"
                    value={orders.total}
                  />
                  <StatisticsCard
                    label="In attesa"
                    value={orders.pending}
                  />
                  <StatisticsCard
                    label="Completati"
                    value={orders.succeeded}
                  />
                  <StatisticsCard
                    label="Falliti"
                    value={orders.failed}
                  />
                </div>
                <div className={styles.cards}>
                  <StatisticsCard
                    label="Rimborsati"
                    value={orders.refunded}
                  />
                  <StatisticsCard
                    label="In attesa di rimborso"
                    value={orders.waiting_for_refund}
                  />
                  <StatisticsCard
                    label="Parzialmente Rimborsati"
                    value={orders.partially_refunded}
                  />
                  <StatisticsCard
                    label="Rimborsi Falliti"
                    value={orders.refund_failed}
                  />
                </div>
                <div className={styles.cards}>

                  <StatisticsCard
                    label="Profitto"
                    value={orders.amount}
                    format={v => `${formatPrice(Math.floor(v / 100))}€`}
                  />
                  <StatisticsCard
                    label="Acquisto medio"
                    value={Math.floor(orders.amount / orders.total)}
                    format={v => `${formatPrice(Math.floor(v / 100))}€`}
                  />
                  <StatisticsCard
                    label="Tasso di conversione"
                    value={Math.floor(orders.succeeded / orders.total * 100 * 100) / 100}
                    format={v => `${isNaN(v) ? 0 : v}%`}
                  />
                </div>
                <div className={styles.column} style={{ position: "relative" }}>
                  <div className={styles.group}>
                    <Each
                      of={ordersGroupTabs.filter(t => (!t.isVisible || t.isVisible()))}
                      render={(item) => (
                        <TabItem
                          selected={orders.group === item.id}
                          text={capitalize(item.text)}
                          onClick={item.onClick}
                          color={item.color}
                          style={{ padding: ".5rem", fontSize: ".875rem" }}
                          defaultBackground="var(--background-secondary-color)"
                        />
                      )
                      }
                    />
                  </div>
                  <OrdersChart data={{ total: orders.trend, succeeded: orders.succeededTrend }} group={orders.group} loading={orders.loading} />
                </div>
              </div>
            </div> */}

            {/* <div className={styles.sectionCard}>
              <div className={styles.column} style={{ gap: "2rem" }}>
                <div className={styles.column}>
                  <div className={styles.header}>
                    <div className={typo.subheadline}>Lezioni completate</div>
                    <div className={styles.filter}>
                      <Each
                        of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                        render={(item) => (
                          <TabItem
                            selected={lessons.completedFilter === item.id}
                            text={capitalize(item.text)}
                            onClick={() => {
                              setLessons(l => {
                                if (l.completedFilter === item.id) {
                                  return { ...l, completedFilter: null }
                                }
                                return { ...l, completedFilter: item.id }
                              })
                            }}
                            color={item.color}
                            style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                            defaultBackground="var(--background-secondary-color)"
                          />
                        )
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.cards}>
                    <StatisticsCard
                      label="Totale"
                      value={lessons.completed.count}
                    />
                    <StatisticsCard
                      label="Ore lezioni svolte"
                      value={lessons.completed.duration}
                      format={(v) => secondsToHours(v * 60, true)}
                    />
                    <StatisticsCard
                      label="Media ore di lezioni svolte"
                      value={lessons.completed.avgDuration}
                      format={(v) => secondsToHours(v * 60, true)}
                    />
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.column}>
                    <div className={styles.header}>
                      <div className={typo.subheadline}>Lezioni programmate</div>
                      <div className={styles.filter}>
                        <Each
                          of={filterTabs.filter(f => f.tag === "all" || f.tag === "future")}
                          render={(item) => (
                            <TabItem
                              selected={lessons.scheduledFilter === item.id}
                              text={capitalize(item.text)}
                              onClick={() => {
                                setLessons(l => {
                                  if (l.scheduledFilter === item.id) {
                                    return { ...l, scheduledFilter: null }
                                  }
                                  return { ...l, scheduledFilter: item.id }
                                })
                              }}
                              color={item.color}
                              style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                              defaultBackground="var(--background-secondary-color)"
                            />
                          )
                          }
                        />
                      </div>
                    </div>
                    <div className={styles.cards}>
                      <StatisticsCard
                        label="Totale"
                        value={lessons.scheduled.count}
                      />
                      <StatisticsCard
                        label="Ore lezioni programmate"
                        value={lessons.scheduled.duration}
                        format={(v) => secondsToHours(v * 60, true)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={typo.subheadline}>Watch time medio</div>
                  <div className={styles.cards}>
                    <StatisticsCard
                      label="Live"
                      value={lessons.watchTime.live.avg}
                      format={(v) => secondsToHours(v, true)}
                    />
                    <StatisticsCard
                      label="Registrazioni"
                      value={lessons.watchTime.recorded.avg}
                      format={(v) => secondsToHours(v, true)}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className={styles.sectionCard}>
              <div className={styles.header}>
                <div className={typo.subheadline}>Corsi</div>
                <div className={styles.filter}>
                  <Each
                    of={filterTabs.filter(f => f.tag === "all" || f.tag === "past")}
                    render={(item) => (
                      <TabItem
                        selected={courses.filter === item.id}
                        text={capitalize(item.text)}
                        onClick={() => {
                          setCourses(c => {
                            if (c.filter === item.id) {
                              return { ...c, filter: null }
                            }
                            return { ...c, filter: item.id }
                          })
                        }}
                        color={item.color}
                        style={{ padding: ".5rem 1rem", fontWeight: 600, fontSize: ".875rem" }}
                        defaultBackground="var(--background-secondary-color)"
                      />
                    )
                    }
                  />
                </div>
              </div>
              <Each
                of={courses.data}
                render={(c, index) => (
                  <div className={styles.courseCard}>
                    <div className={styles.courseTop}>
                      <div className={styles.left}>
                        <div className={styles.thumbnail}>
                          <img src={c.thumbnail} alt="" />
                        </div>
                        <div className={styles.courseInfo}>
                          <CourseBadge type={c.type} />
                          <div className={styles.courseName}>{c.name}</div>
                        </div>
                      </div>
                      <div className={styles.courseStats}>
                        <div className={styles.labeled}>
                          <div className={styles.label}>Acquirenti</div>
                          <div className={styles.value}>{c.in_progress + c.to_start + c.completed}</div>
                        </div>
                      </div>
                      <div className={styles.courseStats}>

                        <div className={styles.labeled}>
                          <div className={styles.label}>Attivi</div>
                          <div className={styles.value}>{c.in_progress}</div>
                        </div>
                        <div className={styles.labeled}>
                          <div className={styles.label}>Non iscritti</div>
                          <div className={styles.value}>{c.to_start}</div>
                        </div>
                        <div className={styles.labeled}>
                          <div className={styles.label}>Completati</div>
                          <div className={styles.value}>{c.completed}</div>
                        </div>
                      </div>
                      <div className={styles.courseStats} style={{ flexBasis: "12%", gap: ".5rem" }}>
                        <div className={styles.labeled}>
                          <div className={styles.label}>Profitti</div>
                          <div className={styles.value}>{formatPrice(c.profit)}€</div>
                        </div>
                        <Button
                          appearance="text"
                          onClick={() => setCourses(cc => {
                            if (cc.expanded.includes(c.id)) {
                              cc.expanded = cc.expanded.filter(e => e !== c.id)
                            } else {
                              cc.expanded.push(c.id)
                            }
                            return { ...cc }
                          })}
                        >
                          {courses.expanded.includes(c.id) ? "Nascondi andamento" : "Vedi andamento"}
                        </Button>
                      </div>
                    </div>
                    <SimpleCollapsable
                      expanded={courses.expanded.includes(c.id)}
                    >
                      <div className={styles.profitChart}>
                        {
                          courses.expanded.includes(c.id) &&
                          <>
                            <div className={styles.group}>
                              <Each
                                of={coursesGroupTabs.filter(t => (!t.isVisible || t.isVisible()))}
                                render={(item) => (
                                  <TabItem
                                    selected={courses.group === item.id}
                                    text={capitalize(item.text)}
                                    onClick={item.onClick}
                                    color={item.color}
                                    style={{ padding: ".5rem", fontSize: ".875rem" }}
                                    defaultBackground="var(--background-secondary-color)"
                                  />
                                )
                                }
                              />
                            </div>
                            <CourseProfitChart data={c.trend} loading={courses.loading} group={courses.group} />
                          </>
                        }
                      </div>
                    </SimpleCollapsable>
                  </div>
                )}
              />
            </div> */}

            <div className={styles.reportSectionRow}>
              <div className={styles.reportSectionCard}>
                <div className={styles.reportCardTitle}>
                  COMMERCIALE
                </div>
              </div>
              <div className={styles.reportSectionCard} onClick={() => {
                navigate('/reports/teaching')
              }}>
                <div className={styles.reportCardTitle}>
                  DIDATTICA
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </HeaderFooterLayout>

  );
};

export default Reports;
