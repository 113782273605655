import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as SearchIcon } from "../assets/images/icons/ic-search.svg"
import { filterAndSortArray, filterArray } from "../utils"
import styles from "./TextInput.module.css"
import typo from "../typography.module.css"

const TextInput = ({
    type,
    defaultValue,
    value,
    placeholder,
    onKeyUp = () => { },
    autocomplete,
    suggestions,
    style = {},
    disabled = false,
    numberType = "int",
    decimals = 2,
    min = null,
    max = null,
}) => {

    const [inputType, setInputType] = useState(type ?? 'text')
    const [icon, setIcon] = useState(faEye)
    const [innerValue, setInnerValue] = useState(() => {
        if (type === "date" && value) {
            let date = new Date(value)
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset * 60 * 1000))
            return date
        } else {
            return value ?? ""
        }
    })
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [helperText, setHelperText] = useState("")
    const [helperTextTimeout, setHelperTextTimeout] = useState(null)

    const ref = useRef(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (type === "date") {
            if (value) {
                let date = new Date(value)
                setInnerValue(date)
            }
        } else {
            setInnerValue(value ?? "")
        }
    }, [value])

    useEffect(() => {
        if (type === "date" && defaultValue) {
            let date = new Date(defaultValue)
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset * 60 * 1000))
            setInnerValue(date)
        }
    }, [])

    useEffect(() => {
        if (helperText) {
            if (helperTextTimeout) {
                clearTimeout(helperText)
            }
            setHelperTextTimeout(
                setTimeout(() => setHelperText(""), 5000)
            )
        }

        return () => {
            if (helperTextTimeout) {
                clearTimeout(helperText)
            }
        }
    }, [helperText])

    const debounceTimeout = useRef(null);

    const handleOnKeyUp = async (event) => {
        setInnerValue(event.target.value)

        if (event.target.value.length > 0) {
            setShowSuggestions(true)
        }
        else {
            setShowSuggestions(false)
        }

        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(async () => {
            if (onKeyUp && typeof onKeyUp === 'function') {
                await onKeyUp(event.target.value);
            }
        }, 300);

    }

    const handleOnKeyUpNumeric = (event) => {
        const rawValue = event.target.value;
        const sanitizedValue = rawValue.replace(',', '.').replace(/[^0-9.]/g, '');
        let numericValue = numberType === "int"
            ? parseInt(sanitizedValue)
            : parseFloat(sanitizedValue);

        if (numberType === "float" && !isNaN(numericValue)) {
            numericValue = parseFloat(numericValue.toFixed(decimals));
        }

        if (min && numericValue < min) {
            numericValue = min
            setHelperText(`Valore minimo: ${min}`)
        } else if (max && numericValue > max) {
            numericValue = max
            setHelperText(`Valore massimo: ${max}`)
        } else {
            setHelperText("")
        }
        setInnerValue(rawValue);
        onKeyUp(isNaN(numericValue) ? 0 : numericValue);
    }

    const showPassword = () => {
        if (inputType === 'password') {
            setInputType('text')
            setIcon(faEyeSlash)
        }
        else {
            setInputType('password')
            setIcon(faEye)
        }
    }

    function matchSuggestion() {
        return filterArray(suggestions, innerValue).length > 0
    }


    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setShowSuggestions(false)
        }
    }

    const handleChangeDate = (d) => {
        const localDate = new Date(d)
        // const offset = localDate.getTimezoneOffset()
        // const noTimezoneDate = new Date(localDate.getTime() + (offset * 60 * 1000))
        setInnerValue(localDate)
        if (onKeyUp && typeof onKeyUp === 'function') {
            onKeyUp(localDate)
        }
    }

    return (
        <>
            {type === 'password' &&
                <div className={styles.container}>
                    <input value={innerValue ?? ''} type={inputType} className={[styles.roundedInput, styles.passwordInput].join((' '))} placeholder={placeholder} onChange={handleOnKeyUp} onKeyUp={handleOnKeyUp} autoComplete={autocomplete}></input>
                    <FontAwesomeIcon icon={icon} className={styles.showPassword} onClick={showPassword} />
                </div>
            }
            {type === 'text' &&
                <div className={styles.container}>
                    <input
                        value={innerValue}
                        type={inputType}
                        className={styles.roundedInput}
                        placeholder={placeholder}
                        onChange={handleOnKeyUp}
                        onKeyUp={handleOnKeyUp}
                        autoComplete={autocomplete}
                        onFocus={() => { setIsFocused(true) }}
                        onBlur={() => { setIsFocused(false) }}
                        style={{ ...style, zIndex: isFocused ? 100 : 0 }}></input>
                    {showSuggestions && suggestions && matchSuggestion() &&
                        <div className={styles.suggestions} ref={ref}>
                            {filterAndSortArray(suggestions, innerValue)
                                .map(sugg => {
                                    return (
                                        <button key={sugg} className={styles.suggestion}
                                            onClick={() => { setInnerValue(sugg); setShowSuggestions(false) }}>{sugg}</button>
                                    )
                                })}
                        </div>
                    }
                </div>
            }
            {type === 'search' &&
                <div className={styles.container}>
                    <div className={styles.searchContainer} style={style}>
                        <input value={innerValue ?? ''} type={inputType} className={styles.searchInput} placeholder={placeholder} onChange={handleOnKeyUp} autoComplete={autocomplete}></input>
                    </div>
                    <SearchIcon className={styles.searchIcon} />
                </div>
            }
            {type === 'number' &&
                <>
                    <input style={style} value={innerValue ?? '0'} inputMode={numberType === "float" ? "decimal" : "numeric"} type='text' className={styles.roundedInput} placeholder={placeholder} onChange={handleOnKeyUpNumeric} onKeyUp={handleOnKeyUpNumeric} autoComplete={autocomplete} />
                    {
                        helperText &&
                        <div className={typo.caption} style={{ color: "var(--sf-red)" }}>{helperText}</div>
                    }
                </>
            }
            {
                type === "date" &&
                <div className={styles.container} style={style}>
                    {
                        placeholder &&
                        <div className={styles.date}>
                            <div className={styles.placeholder}>{placeholder} </div>
                            <DateTimePicker
                                disabled={disabled}
                                className={styles.dateTimePicker}
                                onChange={handleChangeDate}
                                value={innerValue}
                                calendarIcon={<CalendarIcon />}
                                locale="it-IT"
                            />
                        </div>

                    }

                    {
                        !placeholder &&
                        <DateTimePicker
                            ref={ref}
                            disabled={disabled}
                            className={styles.dateTimePicker}
                            onChange={handleChangeDate}
                            value={innerValue}
                            calendarIcon={<CalendarIcon />}
                            locale="it-IT"
                        />
                    }
                </div>
            }

        </>
    )

}

export default TextInput
