import { useEffect, useMemo, useState } from "react"
import { formatDateV2, formatSeconds, formatTimeV2 } from "../utils"
import styles from "./StudentProgress.module.css"
import { ReactComponent as BookIcon } from "../assets/images/icons/ic-book-inactive.svg"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import Button from "../components/Button"
import typo from "../typography.module.css"
import { Each } from "../common/Each"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import TooltipWrapper from "./TooltipWrapper"

const StudentProgress = ({ student }) => {


    const [lessonsNumber, setLessonsNumber] = useState(0)
    const [totalLessons, setTotalLessons] = useState(0)
    const [liveDuration, setLiveDuration] = useState(0)
    const [recordedDuration, setRecordedDuration] = useState(0)
    const [viewDetails, setViewDetails] = useState(false)
    const [innerStudent, setInnerStudent] = useState(null)

    const getViewedLessons = () => {
        if (!innerStudent.modules || !Array.isArray(innerStudent.modules)) {
            return 0
        }

        return innerStudent.modules.reduce((total, module) => {
            if (module.lessons && Array.isArray(module.lessons)) {
                const validLessons = module.lessons.filter(
                    lesson => lesson.live_duration > lesson.duration * 0.8 || lesson.recorded_duration > lesson.duration * 0.8
                );
                return total + validLessons.length;
            }
            return total;
        }, 0);
    }

    const sumDurations = (mode) => {
        if (!innerStudent.modules || !Array.isArray(innerStudent.modules)) {
            throw new Error("L'oggetto deve contenere un campo 'modules' che è un array.");
        }

        return innerStudent.modules.reduce((total, module) => {
            if (module.lessons && Array.isArray(module.lessons)) {
                const durations = module.lessons.reduce((sum, lesson) => sum + (mode === 'live' ? lesson.live_duration : lesson.recorded_duration || 0), 0);
                return total + durations;
            }
            return total;
        }, 0);

    }

    function countTotalLessons() {
        if (!innerStudent.modules || !Array.isArray(innerStudent.modules)) {
            throw new Error("L'oggetto deve contenere un campo 'modules' che è un array.");
        }

        return innerStudent.modules.reduce((total, module) => {
            if (module.lessons && Array.isArray(module.lessons)) {
                return total + module.lessons.length;
            }
            return total;
        }, 0);
    }

    useEffect(() => {
        let studentClone = { ...student }
        studentClone.modules = studentClone.modules.map(m => {
            m.collapsed = true
            return m
        })
        setInnerStudent(studentClone)
    }, [student])

    useEffect(() => {
        if (innerStudent) {
            let lessonsNumber = getViewedLessons()
            setLessonsNumber(lessonsNumber)
            let liveDuration = sumDurations('live')
            let recordedDuration = sumDurations('recorded')
            setLiveDuration(liveDuration)
            setRecordedDuration(recordedDuration)
            let totalLessons = countTotalLessons()
            setTotalLessons(totalLessons)
        }
    }, [innerStudent])

    const percentage = useMemo(() => {
        if (!innerStudent) {
            return 0
        }
        const p = Math.round((innerStudent.edition_seconds_progress / 3600 * 100) / innerStudent.edition_duration) > 100 ? 100 : Math.round((innerStudent.edition_seconds_progress / 3600 * 100) / innerStudent.edition_duration)
        return isNaN(p) ? 0 : p
    }, [innerStudent])

    return (
        <>
            {innerStudent &&
                <div className={styles.container}>
                    <div className={styles.student}>
                        <img className={styles.picture} src={innerStudent.picture ?? UserPlaceholder} alt="" />
                        <div className={styles.stats}>
                            <div className={styles.statsHeader}>
                                <div className={styles.name}>{innerStudent.surname} {innerStudent.name}</div>
                                <Button appearance="text" onClick={() => { setViewDetails(!viewDetails) }}>{viewDetails ? 'NASCONDI DETTAGLI' : 'MOSTRA DETTAGLI'}</Button>
                            </div>
                            <div className={styles.statList}>
                                <div className={styles.statBox}>
                                    <div className={styles.boxTitle}>
                                        LEZIONI
                                    </div>
                                    <div className={styles.boxContent}>
                                        {lessonsNumber}/{totalLessons}
                                    </div>
                                </div>
                                <div className={styles.statBox}>
                                    <div className={styles.boxTitle}>
                                        TEMPO VALIDO
                                    </div>
                                    <div className={styles.boxContent}>
                                        {formatSeconds(innerStudent.edition_seconds_progress)}
                                    </div>
                                </div>
                                <div className={styles.statBox}>
                                    <div className={styles.boxTitle}>
                                        TEMPO LIVE
                                    </div>
                                    <div className={styles.boxContent}>
                                        {formatSeconds(liveDuration)}
                                    </div>
                                </div>
                                <div className={styles.statBox}>
                                    <div className={styles.boxTitle}>
                                        TEMPO REGISTRAZIONI
                                    </div>
                                    <div className={styles.boxContent}>
                                        {formatSeconds(recordedDuration)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.progressContainer}>
                                <div className={styles.progressInfo}>
                                    <div className={styles.numbers}>
                                        <div className={styles.number}>
                                            <BookIcon />
                                            <div>
                                                {innerStudent.edition_module_progress}<span style={{ opacity: .3 }}>/{innerStudent.modules.length}</span>
                                            </div>
                                        </div>
                                        <div className={styles.number}>
                                            <ClockIcon />
                                            <div>
                                                {Math.round((innerStudent.edition_seconds_progress / 3600))}<span style={{ opacity: .3 }}>/{innerStudent.edition_duration}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.percentage}>{percentage}%</div>
                                </div>
                                <div className={styles.progressBar}>
                                    <div className={styles.progressFill} style={{ width: `${Math.round((innerStudent.edition_seconds_progress / 3600 * 100) / innerStudent.edition_duration) > 100 ? 100 : Math.round((innerStudent.edition_seconds_progress / 3600 * 100) / innerStudent.edition_duration)}%` }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.details} ${viewDetails ? styles.open : ''}`}>
                        <div className={typo.headline}>DETTAGLI</div>
                        <Each of={innerStudent.modules.sort((a, b) => {
                            return a.position - b.position
                        })} render={(module, index) => {
                            return (
                                <div className={styles.module}>
                                    <div className={styles.moduleHeader} onClick={() => {
                                        let studentClone = { ...innerStudent }
                                        studentClone.modules = studentClone.modules.map(m => {
                                            if (m.id === module.id) {
                                                m.collapsed = !m.collapsed
                                            }
                                            return m
                                        })
                                        setInnerStudent(studentClone)
                                    }}>
                                        <div className={typo.subheadline}>{module.name}</div>
                                        <div className={typo.headline}>{module.duration}h</div>
                                    </div>
                                    <div className={`${styles.lessons} ${module.collapsed ? styles.collapsed : ''}`}>
                                        <Each of={module.lessons.sort((a, b) => {
                                            return new Date(b.starts_at) - new Date(a.starts_at)
                                        })} render={(lesson) => {
                                            const live_percentage = Math.round(lesson.live_duration * 100 / lesson.duration) > 100 ? 100 : Math.round(lesson.live_duration * 100 / lesson.duration)
                                            const recorded_percentage = Math.round(lesson.recorded_duration * 100 / lesson.video_duration) > 100 ? 100 : Math.round(lesson.recorded_duration * 100 / lesson.video_duration)
                                            return (
                                                <div className={styles.lesson}>
                                                    <div className={styles.lessonInfo}>
                                                        <div className={typo.subheadline}>{lesson.name}</div>
                                                        <div className={typo.headline}>
                                                            {formatDateV2(lesson.starts_at, { day: 'numeric', month: 'numeric', year: 'numeric' })}, {formatTimeV2(lesson.starts_at)}
                                                            {lesson.ended_at ?
                                                                ` -> ${formatDateV2(lesson.ended_at, { day: 'numeric', month: 'numeric', year: 'numeric' })}, ${formatTimeV2(lesson.ended_at)}`
                                                                :
                                                                ` -> ${formatDateV2(lesson.ends_at, { day: 'numeric', month: 'numeric', year: 'numeric' })}, ${formatTimeV2(lesson.ends_at)}`
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.progressWrapper}>
                                                        <div className={styles.progressInfo}>
                                                            <div className={typo.headline}>
                                                                LIVE {lesson.joined_at ? ` - Primo accesso alle ${formatTimeV2(lesson.joined_at)}` : ''}
                                                            </div>
                                                            <div className={styles.time}>
                                                                <div>{formatSeconds(lesson.live_duration)}<span style={{ opacity: .3 }}> / {formatSeconds(lesson.duration)}</span></div>
                                                                <div className={styles.percentage} style={{ color: live_percentage >= 80 ? 'var(--sf-green)' : 'var(--sf-red)' }}>{isNaN(live_percentage) ? 0 : live_percentage}%</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.progressBar}>
                                                            <Each of={lesson.sessions?.filter(s => new Date(s.ended_at) > new Date(lesson.starts_at))} render={(session) => {
                                                                if (new Date(session.joined_at) < new Date(lesson.starts_at)) {
                                                                    session.joined_at = lesson.starts_at
                                                                    session.duration = (new Date(session.ended_at) - new Date(lesson.starts_at)) / 1000
                                                                }
                                                                let shiftSeconds = ((new Date(session.joined_at)).getTime() - (new Date(lesson.starts_at)).getTime()) / 1000
                                                                let shiftPercentage = 0
                                                                shiftPercentage = (shiftSeconds * 100) / lesson.duration
                                                                return (
                                                                    <TooltipWrapper text={`${formatTimeV2(session.joined_at)} - ${formatTimeV2(session.ended_at)} (${formatSeconds(session.duration)})`}>
                                                                        <div className={styles.progressFill}
                                                                            style={{
                                                                                width: `${Math.round(session.duration * 100 / lesson.duration) > 100 ? 100 : Math.round(session.duration * 100 / lesson.duration)}%`,
                                                                                background: live_percentage >= 80 ? 'rgba(var(--sf-green-rgb),80%)' : 'rgba(var(--sf-red-rgb),80%)',
                                                                                left: `${shiftPercentage}%`
                                                                            }}
                                                                        />
                                                                    </TooltipWrapper>
                                                                )
                                                            }} />


                                                        </div>
                                                    </div>
                                                    <div className={styles.progressWrapper}>
                                                        <div className={styles.progressInfo}>
                                                            <div className={typo.headline}>
                                                                REGISTRAZIONI
                                                            </div>
                                                            <div className={styles.time}>
                                                                <div>{formatSeconds(lesson.recorded_duration)}<span style={{ opacity: .3 }}> / {formatSeconds(lesson.video_duration)}</span></div>
                                                                <div className={styles.percentage} style={{ color: recorded_percentage >= 90 ? 'var(--sf-green)' : 'var(--sf-red)' }}>{isNaN(recorded_percentage) ? 0 : recorded_percentage}%</div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.progressBar}>
                                                            <div className={styles.progressFill}
                                                                style={{
                                                                    width: `${Math.round(lesson.recorded_duration * 100 / lesson.video_duration) > 100 ? 100 : Math.round(lesson.recorded_duration * 100 / lesson.video_duration)}%`,
                                                                    background: recorded_percentage >= 90 ? 'rgba(var(--sf-green-rgb),80%)' : 'rgba(var(--sf-red-rgb),80%)'
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }} />
                                    </div>

                                </div>
                            )
                        }} />
                    </div>
                </div>
            }
        </>
    )

}

export default StudentProgress