import { useEffect, useMemo, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { v4 as uuidv4 } from 'uuid';
import api from "../api"
import { FileType } from "../common/constants"
import Button from "./Button"
import styles from "./Uploader.module.css"
import ReactPlayer from "react-player"
import { Each } from "../common/Each"
import FileUpload from "./FileUpload";
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg"
import ImageCropper from "./ImageCropper";
import { ReactComponent as UploadIcon } from "../assets/images/icons/ic-upload.svg"
import { ReactComponent as CropIcon } from "../assets/images/icons/ic-crop.svg"

const Uploader = ({
    type = FileType.All,
    label,
    message,
    icon,
    multiple = false,
    reset,
    preview,
    Preview,
    crop = false,
    shape = 'rect',
    aspect = 16 / 9,
    iconButtons = false,
    onProgress = () => { },
    onComplete = () => { },
    onDelete = () => { } }) => {

    const ref = useRef()
    const [uploads, setUploads] = useState([])
    const [totalProgress, setTotalProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [cropMode, setCropMode] = useState(false)
    const [cropImage, setCropImage] = useState(null)

    const extensions = useMemo(() => {
        switch (type) {
            case FileType.All:
                return {}
            case FileType.Video:
                return {
                    'video/mp4': ['.mp4', '.MP4'],
                    "video/mov": ['.mov'],
                    "video/m4v": ['.m4v'],
                    "video/mpeg": ['.mpeg'],
                }
            case FileType.Image:
                return {
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                    'image/webp': ['.webp'],
                    'image/heic': ['.heic']
                }
            default: return {}
        }
    }, [type])

    const msg = useMemo(() => {
        if (message) {
            return message
        }
        else {
            switch (type) {
                case FileType.All:
                    return multiple ? 'Trascina o seleziona i file.' : 'Trascina o seleziona il file'
                case FileType.Image:
                    return multiple ? 'Trascina o seleziona le immagini' : 'Trascina o seleziona l\'immagine'
                case FileType.Video:
                    return multiple ? 'Trascina o seleziona i video' : 'Trascina o seleziona il video'
                default: return 'Trascina o seleziona file'

            }
        }
    }, [type, message, multiple])

    const lbl = useMemo(() => {
        if (label) {
            return label
        }
        else {
            switch (type) {
                case FileType.All:
                    return 'SCEGLI FILE'
                case FileType.Image:
                    return multiple ? 'SCELI IMMAGINI' : 'SCEGLI IMMAGINE'
                case FileType.Video:
                    return 'SCEGLI VIDEO'
                default: return 'SCEGLI FILE'
            }
        }
    }, [type, label, multiple])

    useEffect(() => {
        return () => {
            for (const u of uploads) {
                if (u.interval) {
                    clearInterval(u.interval)
                }
            }
        }
    }, [])

    useEffect(() => {

        if (uploads && uploads.length > 0) {
            const sumProgress = uploads.reduce((acc, curr) => { return acc += curr.progress }, 0)
            const totalProgress = parseInt((sumProgress / (uploads.length * 100)) * 100)
            setTotalProgress(totalProgress)
            onProgress(totalProgress)
        }

        if (uploads && uploads.length > 0) {
            setUploads((prev) => {
                for (let p of prev) {
                    if (p.file && !p.interval && p.progress === 0) {
                        upload(p)
                        p.interval = setInterval(() => { getUploadProgress(p.uploadId) }, 2000)
                        return [...prev]
                    }
                }
                return prev
            })
        }

    }, [uploads])

    const getUploadProgress = async (upload_id) => {
        try {
            let upload = await api.get(`/admin/upload/${upload_id}`)
            setUploads((prev) => {
                for (let p of prev) {
                    if (p.uploadId === upload_id) {
                        p.progress = upload.progress
                        if (upload.progress >= 100) {
                            clearInterval(p.interval)
                            p.interval = null
                        }
                    }
                }
                return [...prev]
            })
            // return upload.progress
        }
        catch (e) {
            console.error(e)
            setUploads((prev) => {
                for (let p of prev) {
                    if (p.uploadId === upload_id && p.interval) {
                        clearInterval(p.interval)
                        p.interval = "stopped"
                    }
                }
                return [...prev]
            })
        }
    }

    const removeFile = async (url) => {
        try {
            let deleted = await api.delete(`/admin/upload?url=${url}`)
            if (deleted) {
                setUploads([...uploads.filter(u => u.url !== url)])
            }
        }
        catch (e) {
            console.error(e)
        }
        onDelete(uploads)
    }

    const upload = async (uploadData) => {
        setUploading(true)
        const formData = new FormData()
        formData.append("file", uploadData.file);
        formData.append("upload_id", uploadData.uploadId)
        try {
            let upload = await api.post('/admin/upload', formData, { timeout: 3_600_000 })
            if (uploads && uploads.length > 0) {
                setUploads((prev) => {
                    for (let p of prev) {
                        if (p.uploadId === uploadData.uploadId) {
                            p.url = upload.url
                        }
                    }
                    return [...prev]
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    const getUrlMediaType = (url) => {
        const parts = url.split('/');
        const fileName = parts[parts.length - 1]
        const file_parts = fileName.split('.')
        const extension = file_parts[file_parts.length - 1].toLowerCase()
        const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'heic', 'raw']
        const videoExtensions = ['mp4', 'mov', 'mkv', 'avi']
        if (imageExtensions.includes(extension)) {
            return 'image'
        }
        else if (videoExtensions.includes(extension) || type === FileType.Video) {
            return 'video'
        }
        else {
            return 'file'
        }
    }

    useEffect(() => {
        if (totalProgress > 0) {
            setUploading(true)
        }
        if (totalProgress >= 100) {
            setUploading(false)
            onComplete(uploads)
        }
    }, [totalProgress])

    useEffect(() => {
        setUploads([])
        setTotalProgress(0)
    }, [reset])

    const convertBlobToFile = async (blobUrl, fileName) => {
        // Recupera il Blob utilizzando fetch
        const response = await fetch(blobUrl);
        const blob = await response.blob();

        // Crea un oggetto File a partire dal Blob
        const file = new File([blob], fileName, {
            type: blob.type, // Mantieni il tipo MIME del blob
            lastModified: Date.now(), // Imposta una data di modifica
        });

        return file;
    };

    return (
        <Dropzone
            noClick={!!preview || cropMode}
            noKeyboard={!!preview || cropMode}
            ref={ref}
            multiple={multiple}
            onDrop={async (files) => {
                if (crop) {
                    for (let u of uploads) {
                        if (u.url) {
                            await removeFile(u.url)
                        }
                    }
                    setCropMode(true)
                    setCropImage(files[0])
                }
                else {
                    let _uploads = files.map(f => {
                        return {
                            file: f,
                            progress: 0,
                            uploadId: uuidv4(),
                            interval: null,
                            url: null
                        }
                    })
                    if (multiple) {
                        setUploads([...uploads, ..._uploads])
                    }
                    else {
                        for (let u of uploads) {
                            if (u.url) {
                                await removeFile(u.url)
                            }
                        }
                        setUploads([..._uploads])
                    }
                }
            }}
            accept={extensions}
            onError={(e) => {
                console.error(e)
            }}
        >

            {({ getRootProps, getInputProps }) => (
                <section {...getRootProps()} className={styles.dropzoneContainer}>
                    <input {...getInputProps()} />
                    <div className={styles.dropzone}>
                        {
                            uploads.length === 0 && !preview &&
                            <InsertDocumentIllustration />
                        }
                        <Each of={uploads} render={(upload) => {
                            return (
                                <FileUpload file={upload.file} url={upload.url} progress={upload.progress} onDelete={removeFile} />
                            )
                        }} />
                        {preview && !uploading && !multiple && !Preview && !cropMode &&
                            <>
                                {getUrlMediaType(preview) === FileType.Video &&
                                    <ReactPlayer
                                        url={preview}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                    />
                                }
                                {getUrlMediaType(preview) === FileType.Image &&
                                    <div className={styles.thumbnailWrapper}>
                                        <img src={preview} alt="" className={styles.thumbnail} />
                                    </div>
                                }
                            </>
                        }
                        {cropMode &&
                            <>
                                <ImageCropper
                                    shape={shape}
                                    aspect={aspect}
                                    src={cropImage ? URL.createObjectURL(cropImage) : preview}
                                    onSave={async (image) => {
                                        setCropMode(false)
                                        let name = preview ? preview.split('/').pop().split('.')[0] + '.png' : cropImage.name.split('.')[0] + '.png'
                                        let f = await convertBlobToFile(image, name)
                                        setUploads([
                                            {
                                                file: f,
                                                progress: 0,
                                                uploadId: uuidv4(),
                                                interval: null,
                                                url: null
                                            }
                                        ])
                                    }}
                                    onCancel={() => { setCropMode(false) }}
                                    compression={{ quality: 1, maxWidth: 1024, maxHeight: 1024 }}
                                >
                                </ImageCropper>
                            </>
                        }
                        {
                            preview && Preview && !uploading && !multiple && <Preview preview={preview} />
                        }
                        {!cropMode &&
                            <>
                                <div className={styles.dropzoneLabel}>
                                    {msg}
                                </div>
                                <div className={styles.dropzoneActions}>
                                    <Button
                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                        onClick={() => {
                                            if (ref && ref.current) {
                                                ref.current.open()
                                            }
                                        }}
                                    >
                                        {!iconButtons && lbl} <UploadIcon />
                                    </Button>
                                    {crop &&
                                        <Button
                                            style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                            onClick={() => {
                                                setCropMode(true)
                                            }}
                                        >
                                            {!iconButtons && 'RITAGLIA'} <CropIcon />
                                        </Button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </section>
            )}
        </Dropzone>
    )

}

export default Uploader