import { useEffect, useMemo, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import { SurveyType } from "../common/constants"
import { Each } from "../common/Each"
import SurveyQuestion from "../components/cards/SurveyQuestion"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./Survey.module.css"
import Back from "../components/Back"

const Survey = ({ }) => {

    const { survey_id } = useParams()
    const [survey, setSurvey] = useState(null)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const navigate = useNavigate()

    const groups = useMemo(() => {
        if (!survey) {
            return []
        }

        const questions = {}
        const originalContent = survey.content

        if (survey.users_survey.length === 0) {
            for (const question of survey.content) {
                questions[question.id] = question
            }
        } else {
            const userSurveys = survey.users_survey.filter(s => s.content && s.content.length > 0)
            for (const userSurvey of userSurveys) { // per ogni utente che ha risposto
                const { content, user } = userSurvey
                for (const question of content.filter(c => c.type !== SurveyType.Open)) {
                    if (!questions[question.id]) {
                        const { answer, answers, value, ...others } = question
                        const questionData = originalContent.find(c => c.id === question.id)
                        questions[question.id] = {
                            ...questionData,
                            answers: []
                        }
                    }
                    let answer = null
                    switch (question.type) {
                        case SurveyType.SingleChoice:
                            answer = { id: question.answerId, value: question.answer.value, answerValue: question.answer?.answerValue }; break
                        case SurveyType.MultipleChoice:
                            answer = { answers: question.answers }; break
                        case SurveyType.Numeric:
                            answer = { id: question.answerId, value: question.answer }; break
                        case SurveyType.Open:
                            continue
                        default: break
                    }
                    questions[question.id].answers.push({ ...answer, ...{ userId: user.id, name: user.name, surname: user.surname, picture: user.picture } })
                }
            }
        }

        const g = {}
        for (const question of Object.values(questions)) {
            if (!g[question.group]) {
                g[question.group] = []
            }
            g[question.group].push(question)
        }

        return g
    }, [survey])

    useEffect(() => {
        const getSurvey = async () => {
            try {
                const survey = await api.get(`/admin/surveys/${survey_id}`)
                setSurvey(survey)
            } catch (e) {
                console.error(e)
            }

        }
        getSurvey()
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Sondaggio</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.back}>
                            <Back onClick={() => navigate(`/surveys`)} />
                        </div>
                        <div className={typo.title}>
                            {survey?.description}
                        </div>
                        <Each
                            of={Object.keys(groups)}
                            render={(group) => (
                                <div className={styles.group}>
                                    <div className={styles.groupName}>{group}</div>
                                    <Each
                                        of={groups[group]}
                                        render={(question) => (
                                            <SurveyQuestion question={question} surveyId={survey_id} />
                                        )}
                                    />

                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Survey