import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import TextareaAutosize from 'react-textarea-autosize'
import { v4 } from "uuid"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add.svg"
import { ReactComponent as ArchiveIcon } from "../assets/images/icons/ic-archive.svg"
import { ReactComponent as SubIcon } from "../assets/images/icons/ic-min.svg"
import { ButtonStatus } from "../common/constants"
import { Each } from "../common/Each"
import Back from "../components/Back"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import Checkbox from "../components/Checkbox"
import DropdownSelection from "../components/DropdownSelection"
import TestForm from "../components/tests/TestForm"
import TextInput from "../components/TextInput"
import { CorrectionType, TestStatus, TestType } from "../enums"
import typo from "../typography.module.css"
import { getDefaultTest, isTestValid } from "../utils"
import styles from "./Test.module.css"

const Test = ({
    onBack = () => { },
    onSave = () => { },
    test = getDefaultTest()
}) => {
    const { t } = useTranslation()

    // test 
    const [name, setName] = useState(test.name)
    const [description, setDescription] = useState(test.description)

    const [courses, setCourses] = useState([])
    const [editions, setEditions] = useState([])
    const [modules, setModules] = useState([])
    const [lessons, setLessons] = useState([])
    const [course, setCourse] = useState(null)
    const [edition, setEdition] = useState(null)
    const [module, setModule] = useState(null)
    const [lesson, setLesson] = useState(null)

    const [tags, setTags] = useState(test.tags)
    const [canBeRetried, setCanBeRetried] = useState(test.can_be_retried)
    const [expiresAt, setExpiresAt] = useState(test.expires_at ? new Date(test.expires_at) : null)
    const [correctionType, setCorrectionType] = useState(test.correction_type)
    const [successThreshold, setSuccessThreshold] = useState(test.success_threshold ?? 1)
    const [numberOfTests, setNumberOfTest] = useState(0)
    const [innerTests, setInnerTests] = useState([...test.content.innerTests])

    const [isValid, setIsValid] = useState(false)
    const [validationError, setValidationError] = useState("")
    const [isDraftable, setIsDraftable] = useState(test.name !== null && test.name !== "")
    const [loading, setLoading] = useState(false)
    const [draftButtonStatus, setDraftButtonStatus] = useState(null)
    const [publishButtonStatus, setPublishButtonStatus] = useState(null)

    const correctionTypes = useMemo(() => {
        return [
            { id: CorrectionType.Errors, label: t("tests.correctionType.errors") },
            { id: CorrectionType.NumberOfErrors, label: t("tests.correctionType.nErrors") },
            { id: CorrectionType.Solutions, label: t("tests.correctionType.solutions") },
        ]
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                    HANDLER COURSE EDITION MODULE LESSON                    */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        if (course) {
            getEditions()
            if (course.id !== test.course?.id) {
                // setTest(c => ({ ...c, course, edition: null, module: null, lesson: null }))
                // setEditions([])
                // setModules([])
                // setLessons([])
                // setEdition(null)
                // setModule(null)
                // setLesson(null)
            }
        } else {
            setEditions([])
            setModules([])
            setLessons([])
        }
    }, [course])

    useEffect(() => {
        if (edition) {
            setModules(edition.modules.map(m => ({ ...m, label: m.name })))
        } else {
            setModules([])
            setLessons([])
        }
    }, [edition])

    useEffect(() => {
        if (module) {
            const { lessons } = module
            const l = lessons.map((l) => ({ ...l, label: l.name }))
            l.unshift({ id: null, label: t("tests.lesson.empty") })
            setLessons(l)
        } else {
            setLessons([])
        }
    }, [module])

    useEffect(() => {
        if (editions.length > 0 && test && !edition) {
            const e = editions.find(ee => ee.id === test.edition?.id)
            setEdition(e)
        }
    }, [editions])

    useEffect(() => {
        if (modules.length > 0 && test && !module) {
            const m = modules.find(mm => mm.id === test.module?.id)
            setModule(m)
        }
    }, [modules])

    useEffect(() => {
        if (lessons.length > 0 && test?.lesson && !lesson) {
            setLesson(lessons.find(l => l.id === test.lesson?.id))
        }
    }, [lessons])


    const getCourses = async () => {
        try {
            let courses = await api.get("/admin/courses?with_edition=true");
            courses = courses.map(c => ({ ...c, label: c.name }))
            setCourses(courses)
            if (test?.course) {
                const c = courses.find(cc => cc.id === test.course?.id)
                setCourse(c)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const getEditions = async () => {
        try {
            let editions = await api.get(`/admin/courses/${course.id}/editions`);
            editions = editions.filter(e => e.modules && e.modules.length > 0)
            setEditions(editions.map(e => ({ ...e, label: e.name })))
        } catch (e) {
            console.error(e)
        }
    }

    /* ----------------------------------------------------------------------------- */

    useEffect(() => {
        const getTags = async () => {
            try {
                const res = await api.get("/teacher/tags");
                setTags(res);
            } catch (e) {
                console.error(e);
            }
        };
        getCourses()
        getTags()
    }, []);


    useEffect(() => {
        const { isValid, isNameEmpty, areInnerTestValid, isModuleValid, isLessonValid } = isTestValid(name, module, lesson, innerTests)
        let validationError = ""
        if (isNameEmpty) {
            validationError = t("tests.validation.noName")
        } else if (!areInnerTestValid) {
            validationError = t("tests.validation.innerTestsError")
        } else if (!isModuleValid) {
            validationError = t("tests.validation.noModule")
        } else if (!isLessonValid) {
            validationError = ""
        }
        setValidationError(validationError)
        setIsValid(isValid)
    }, [innerTests, name, description, module, lesson, modules, lessons, expiresAt])

    useEffect(() => {
        let numberOfTests = 0
        for (const innerTest of innerTests) {
            const { testType } = innerTest
            switch (testType) {
                case TestType.SingleChoice:
                case TestType.MultipleChoice:
                    numberOfTests += 1
                    break
                case TestType.TrueFalse:
                    numberOfTests += innerTest.answers.length
                    break
                case TestType.TextCompletion:
                    if (innerTest.words) {
                        numberOfTests += innerTest.words.reduce((a, c) => {
                            if (c.hidden) {
                                a += 1
                            }
                            return a
                        }, 0)
                    }
                    break
                default: break;
            }
        }
        setNumberOfTest(numberOfTests)
    }, [innerTests])

    const addQuestion = useCallback(() => {
        innerTests.push({
            testType: TestType.SingleChoice, text: "",
            tags: [],
            answers: [{
                id: v4(),
                text: "",
                isCorrect: false
            }, {
                id: v4(),
                text: "",
                isCorrect: false
            }],
        })
        setInnerTests([...innerTests])
    }, [innerTests])

    const removeQuestion = useCallback((removedIndex) => {
        if (innerTests.length <= 1) {
            return;
        }
        innerTests.splice(removedIndex, 1)
        setInnerTests([...innerTests])
    }, [innerTests])

    const onTestFormChange = useCallback((changes, index) => {
        const { testType, innerTest } = changes

        setInnerTests((prev) => {
            prev[index] = { testType, ...innerTest }
            return [...innerTests]
        })
    }, [innerTests])

    const saveTest = async (status) => {
        setLoading(true)
        if (status === TestStatus.Draft) {
            setDraftButtonStatus(ButtonStatus.Loading)
        } else {
            setPublishButtonStatus(ButtonStatus.Loading)
        }
        const editing = test.name && test.id
        const it = innerTests.map((t) => {
            return {
                ...t,
                id: v4()
            }
        })
        try {
            const newTest = {
                name,
                description,
                can_be_retried: canBeRetried,
                status,
                correction_type: correctionType,
                expires_at: expiresAt ? new Date(expiresAt).toISOString() : null,
                success_threshold: successThreshold > it.length ? it : successThreshold,
                content: { innerTests: it },
                publishable: isValid,
            }
            if (module?.id !== null) {
                newTest.module_id = module.id
            }

            if (lesson !== null) {
                newTest.lesson_id = lesson.id
            }

            const response = editing ?
                await api.put(`/admin/tests/${test.id}`, newTest) :
                await api.post("/admin/tests", newTest)

            if (status === TestStatus.Draft) {
                setDraftButtonStatus(ButtonStatus.Success)
            } else {
                setPublishButtonStatus(ButtonStatus.Success)
            }
            onSave(test)
        } catch (e) {
            console.error(e)
            setValidationError(e.message)
            if (status === TestStatus.Draft) {
                setDraftButtonStatus(ButtonStatus.Error)
            } else {
                setPublishButtonStatus(ButtonStatus.Error)
            }
        }
        setLoading(false)

    }

    useEffect(() => {
        setIsDraftable(name !== null && name !== "")
    }, [name])

    return (
        <div className={styles.container}>
            <Back onClick={onBack} />
            <Card style={{ padding: 0, display: "flex", flexDirection: "column", backgroundColor: "rgba(var(37,37,37), 12%)" }}>
                <div className={styles.section}>
                    <div className={typo.subtitle} >{t("tests.info")}</div>
                    <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem" }} title={`${t("tests.name")}*`}>
                        <div className={styles.innerCard}>
                            <div className={styles.sectionTitle}></div>
                            <TextInput
                                type={"text"}
                                value={name}
                                onKeyUp={(value) => {
                                    setName(value)
                                }}
                                style={{
                                    padding: 0, border: "none", backgroundColor: "transparent",
                                    fontSize: "1rem", fontWeight: 700, width: "100%",
                                    borderRadius: 0
                                }} />
                        </div>
                    </Card>
                    <Card hover style={{ padding: ".5rem 1rem" }} title={t("tests.description")}>
                        <div className={styles.innerCard}>
                            <div className={styles.textAreaContainer}>
                                <TextareaAutosize
                                    value={description}
                                    minRows={3} maxRows={10}
                                    type="text"
                                    className={styles.textArea}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setDescription(value)
                                    }} />
                            </div>
                        </div>
                    </Card>
                    <div className={styles.testSettings}>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", overflow: "visible !important" }}
                                title={"Corso"}
                            >
                                <div className={styles.innerCard}>
                                    <div className={styles.sectionTitle}>{ }</div>
                                    <DropdownSelection
                                        disabled={courses.length === 0}
                                        placeholder={"Corso"}
                                        defaultOption={course?.id}
                                        options={courses}
                                        appereance="embedded"
                                        onSelect={(id) => {
                                            if (id && id !== course?.id) {
                                                setCourse(courses.find(c => c.id === id))
                                            }
                                        }} />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", overflow: "visible !important" }}
                                title={"Edizione"}
                            >
                                <div className={styles.innerCard}>
                                    <div className={styles.sectionTitle}>{ }</div>
                                    <DropdownSelection
                                        disabled={editions.length === 0}
                                        placeholder={"Edizione"}
                                        defaultOption={edition?.id}
                                        options={editions}
                                        appereance="embedded"
                                        onSelect={(id) => {
                                            if (id && id !== edition?.id) {
                                                setEdition(editions.find(e => e.id === id))
                                                setModule(null)
                                                setLesson(null)
                                            }
                                        }} />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", overflow: "visible !important" }}
                                title={t("tests.module.label")}
                            >
                                <div className={styles.innerCard}>
                                    <div className={styles.sectionTitle}>{ }</div>
                                    <DropdownSelection
                                        disabled={modules.length === 0}
                                        placeholder={"Modulo"}
                                        defaultOption={module?.id}
                                        options={modules}
                                        appereance="embedded"
                                        onSelect={(id) => {
                                            if (id && id !== module?.id) {
                                                setModule(modules.find(m => m.id === id))
                                                setLesson(null)
                                            }
                                        }} />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", overflow: "visible !important" }} title={t("tests.lesson.label")}>
                                <div className={styles.innerCard}>
                                    <DropdownSelection
                                        disabled={lessons.length === 0}
                                        placeholder={"Lezione"}
                                        defaultOption={lesson?.id}
                                        options={lessons}
                                        appereance="embedded"
                                        onSelect={(id) => {
                                            if (id && id !== lesson?.id) {
                                                setLesson(lessons.find(l => l.id === id))
                                            }
                                        }} />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", overflow: "visible !important" }} title={`${t("tests.correctionType.label")}*`}>
                                <div className={styles.innerCard}>
                                    <DropdownSelection
                                        defaultOption={correctionType}
                                        placeholder={t("tests.correctionType.placeholder")}
                                        options={correctionTypes}
                                        appereance="embedded"
                                        onSelect={(id) => {
                                            if (id) {
                                                const c = correctionTypes.find(c => c.id === id)
                                                setCorrectionType(c?.id)
                                            }
                                        }} />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", height: "100%" }} title={`${t("tests.successThreshold.label")}`}>
                                <div className={styles.innerCard}>
                                    <div className={styles.threshold}>
                                        <div className={styles.successButton} onClick={() => setSuccessThreshold(s => Math.max(1, s - 1))}><SubIcon /></div>
                                        <div className={styles.values}>
                                            <b>{successThreshold}</b> {t("tests.successThreshold.hint2")} {numberOfTests}  {t("tests.questions").toLowerCase()}
                                        </div>
                                        <div className={styles.successButton} onClick={() => setSuccessThreshold(s => Math.min(numberOfTests, s + 1))}><AddIcon /></div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", height: "100%" }}>
                                <div className={`${styles.innerCard2}`}>
                                    <div className={styles.cardLabel}>{t("tests.expiresAt")}</div>
                                    <TextInput
                                        type={"date"}
                                        onKeyUp={(date) => {
                                            setExpiresAt(date)
                                        }}
                                        value={expiresAt}
                                        defaultValue={test.expires_at ? new Date(test.expires_at) : null}
                                        style={{
                                            padding: 0, border: "none", backgroundColor: "transparent",
                                            fontSize: "1rem", fontWeight: 700, width: "fit-content",
                                            borderRadius: 0
                                        }}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className={styles.testSetting}>
                            <Card hover style={{ padding: ".5rem 1rem", height: "100%", minHeight: "66px" }} onClick={() => {
                                setCanBeRetried(!canBeRetried)
                            }}>
                                <div className={`${styles.innerCard2}`} style={{ cursor: 'pointer' }}>
                                    <div className={styles.cardLabel}>{t("tests.canBeRetried")}</div>
                                    <Checkbox value={canBeRetried} onChange={(isChecked) => {
                                        setCanBeRetried(isChecked)
                                    }} />
                                </div>
                            </Card>
                        </div>
                    </div>

                </div>
                <div className={styles.divider} />
                <div className={styles.section}>
                    <div className={typo.subtitle}>{t("tests.questions")}</div>
                    <Each
                        of={innerTests}
                        render={(test, index) => {
                            const { testType, ...innerTest } = test
                            return (
                                <TestForm
                                    innerTest={innerTest}
                                    tagList={tags}
                                    testType={testType}
                                    onRemove={() => removeQuestion(index)}
                                    onChange={(changes) => onTestFormChange(changes, index)}
                                    showRemoveButton={index > 0} />
                            )
                        }}
                    />

                    <div className={styles.addQuestion} onClick={addQuestion}>+ {t("tests.addQuestion").toUpperCase()}</div>
                    <div className={styles.actionsContainer}>
                        {
                            validationError &&
                            <div className={styles.validationError}>
                                {validationError}
                            </div>
                        }
                        {!validationError && test.lesson &&
                            <div style={{ padding: '0 1rem' }}>
                                I test associati alle lezioni possono essere salvati solo in bozza e pubblicati durante lo svolgimento della lezione direttamente dalla pagina della lezione stessa.
                            </div>
                        }
                        <div className={styles.actions}>
                            <Button
                                inverse
                                accentColor={"var(--primary)"}
                                onClick={() => saveTest(TestStatus.Draft)}
                                status={draftButtonStatus}
                                disabled={!isDraftable}
                                style={{
                                    padding: ".7rem 1.5rem",
                                    fontWeight: 600
                                }}
                            >
                                {t("tests.draft").toUpperCase()}
                                <ArchiveIcon />
                            </Button>
                            <Button
                                onClick={() => saveTest(TestStatus.Public)}
                                disabled={!isValid || lesson?.id}
                                status={publishButtonStatus}
                                style={{
                                    padding: ".7rem 1.5rem",
                                    fontWeight: 600
                                }}
                            >
                                {t("tests.publish").toUpperCase()}
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Test
