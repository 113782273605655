import Button from "../components/Button"
import Card from "../components/cards/Card"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import styles from "./Course.module.css"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import typo from "../typography.module.css"
import { useEffect, useState } from "react"
import api from "../api"
import { useNavigate, useParams } from "react-router-dom"
import CourseBadge from "../components/CourseBadge"
import { Each } from "../common/Each"
import { ReactComponent as BookIcon } from "../assets/images/icons/ic-book-active.svg"
import { ReactComponent as LessonIcon } from "../assets/images/icons/ic-lessons-active.svg"
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students-active.svg"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { formatDateV2 } from "../utils"
import Back from "../components/Back"
import useContextMenu from "../common/hooks/useContextMenu"
import { ReactComponent as CopyIcon } from "../assets/images/icons/ic-copy.svg"
import AlertDialog from "../components/dialogs/AlertDialog"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import EmptyContent from "../components/EmptyContent"
import TruncatedText from "../components/TruncatedText"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { useTranslation } from "react-i18next"

const Course = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const { courseId } = useParams();
    const [course, setCourse] = useState(null)
    const [editions, setEditions] = useState(null)
    const [loading, setLoading] = useState(true)
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' }
    const [alert, setAlert] = useState({ open: false, title: '', text: '' })

    const { showMenu } = useContextMenu()

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    useEffect(() => {

        const getCourse = async () => {
            setLoading(true)
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
                navigate("/404")
            }
            setLoading(false)
        }

        const getCourseEditions = async () => {
            try {
                const editions = await api.get(`/admin/courses/${courseId}/editions`)
                setEditions(editions)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!course && courseId !== 'create') {
            getCourse()
            getCourseEditions()
        }
    }, [courseId])

    const flatLesson = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons || [])
        return lessons
    }

    const getEditionStart = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons || [])
        if (lessons.length === 0) return undefined; // Se non ci sono lezioni, ritorna null
        const objectWithMinStartAt = lessons.reduce((min, current) => {
            return new Date(current.starts_at) < new Date(min.starts_at) ? current : min;
        }, lessons[0]);
        return objectWithMinStartAt?.starts_at
    }

    const getEditionEnd = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons || [])
        if (lessons.length === 0) return undefined; // Se non ci sono lezioni, ritorna null
        const objectWithMaxStartAt = lessons.reduce((max, current) => {
            return new Date(current.starts_at) > new Date(max.starts_at) ? current : max;
        }, lessons[0]);
        return objectWithMaxStartAt?.starts_at
    }

    const getStatusStyle = (status) => {
        switch (status) {
            case 'public':
                return styles.public
            case 'draft':
                return styles.draft
            case 'hidden':
                return styles.hidden
            case 'ended':
                return styles.ended
            default:
                return null
        }
    }

    const duplicateEdition = async (editionId, duplicateAll = false) => {
        try {
            const edition = await api.get(`/admin/editions/${editionId}/duplicate?duplicate_all=${duplicateAll}`)
            setEditions([...editions, edition])
        }
        catch (e) {
            console.error(e)
        }
    }

    const deleteEdition = async (editionId, duplicateAll = false) => {
        try {
            await api.delete(`/admin/editions/${editionId}`)
            setEditions(editions.filter(e => e.id !== editionId))
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <Back onClick={() => { navigate('/courses') }} />
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {course && course.name}
                        </div>
                        <Card>
                            <div className={styles.cardInner}>
                                <div className={styles.courses}>
                                    {loading &&
                                        <div className={styles.skeletonCourse}>
                                            <Skeleton type="rect" width={50} height={50} borderRadius="12px" />
                                            <div className={styles.skeletonInfo}>
                                                <Skeleton type="rect" width={150} height={20} borderRadius="4px" />
                                                <Skeleton type="rect" width={300} height={15} borderRadius="4px" />
                                            </div>
                                            <Skeleton type="rect" width={60} height={30} borderRadius="8px" />
                                            <ChevronIcon className={styles.chevron} />
                                        </div>
                                    }
                                    {!loading &&
                                        <div className={styles.course}>
                                            <img src={course.thumbnail} className={styles.thumbnail} alt="" />
                                            <div className={styles.info}>
                                                <CourseBadge type={course.type} />
                                                <div className={styles.title}>{course.name}</div>
                                                <TruncatedText
                                                    className={styles.description}
                                                    text={course.description}
                                                    maxLength={256}
                                                />
                                            </div>
                                            <div className={`${styles.status} ${getStatusStyle(course.status)}`}>{course.status}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Card>
                        <div className={typo.subtitle} style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                            Edizioni
                            <Button onClick={() => {
                                navigate(`/courses/${courseId}/editions/create`)
                            }}>
                                AGGIUNGI
                            </Button>
                        </div>
                        <div className={styles.editions}>
                            {loading &&
                                <Each of={Array(7).fill('')} render={(i) => {
                                    return (
                                        <div className={styles.edition}>
                                            <Skeleton type="rect" width="100%" height={25} borderRadius="8px" />
                                            <Skeleton type="rect" width="40%" height={25} borderRadius="8px" />
                                            <Skeleton type="rect" width="50%" height={25} borderRadius="8px" />
                                            <Skeleton type="rect" width="30%" height={25} borderRadius="8px" />
                                            <Skeleton type="rect" width="10%" height={25} borderRadius="8px" />
                                        </div>
                                    )
                                }} />
                            }
                            {!loading && editions && editions.length > 0 &&
                                <Each of={editions.sort((a, b) => a.created_at < b.created_at)} render={(edition) => {
                                    return (
                                        <div className={styles.edition}
                                            onContextMenu={(event) => {
                                                const actions = [
                                                    {
                                                        name: "Duplica",
                                                        icon: <CopyIcon />,
                                                        onClick: () => {
                                                            setAlert({
                                                                open: true,
                                                                title: `Duplicare ${edition.name}?`,
                                                                text: 'Scegli se vuoi duplicare solo l\'edizione o tutti i suoi dati inclusi moduli, docenti e lezioni.',
                                                                actions: [
                                                                    {
                                                                        label: 'SOLO EDIZIONE',
                                                                        onClick: async () => {
                                                                            await duplicateEdition(edition.id, false)
                                                                            setAlert((prev) => {
                                                                                prev.open = false
                                                                                return { ...prev }
                                                                            })
                                                                        }
                                                                    },
                                                                    {
                                                                        label: 'TUTTO',
                                                                        onClick: async () => {
                                                                            await duplicateEdition(edition.id, true)
                                                                            setAlert((prev) => {
                                                                                prev.open = false
                                                                                return { ...prev }
                                                                            })
                                                                        }
                                                                    }
                                                                ]
                                                            })
                                                        }
                                                    }
                                                ]
                                                if (edition.status === 'draft') {
                                                    actions.push(
                                                        {
                                                            name: "Elimina",
                                                            icon: <DeleteIcon />,
                                                            onClick: () => {
                                                                setAlert({
                                                                    open: true,
                                                                    title: `Eliminare ${edition.name}?`,
                                                                    text: 'L\'operazione non potrà essere annullata',
                                                                    actions: [
                                                                        {
                                                                            label: 'ELIMINA',
                                                                            onClick: async () => {
                                                                                await deleteEdition(edition.id)
                                                                                setAlert((prev) => {
                                                                                    prev.open = false
                                                                                    return { ...prev }
                                                                                })
                                                                            },
                                                                            color: 'var(--sf-red)'
                                                                        }
                                                                    ]
                                                                })
                                                            },
                                                            color: 'var(--sf-red)'
                                                        }
                                                    )
                                                }
                                                showMenu(event, actions)
                                            }}
                                            onClick={() => {
                                                navigate(`/courses/${courseId}/editions/${edition.id}`)
                                            }}>
                                            <div className={styles.editionName}>{edition.name}</div>
                                            <div className={styles.editionInfo}>
                                                <div className={styles.editionInfoRow}>
                                                    <CalendarIcon />
                                                    {getEditionStart(edition) !== undefined ? formatDateV2(getEditionStart(edition), dateOptions) : ''} - {getEditionEnd(edition) !== undefined ? formatDateV2(getEditionEnd(edition), dateOptions) : ''}
                                                </div>
                                                <div className={styles.editionInfoRow}>
                                                    <BookIcon />
                                                    {edition.modules.length}
                                                    {edition.modules.length === 1 ? ' modulo' : ' moduli'}
                                                </div>
                                                <div className={styles.editionInfoRow}>
                                                    <LessonIcon />
                                                    {flatLesson(edition).length}
                                                    {flatLesson(edition).length === 1 ? ' lezione' : ' lezioni'}
                                                </div>
                                                <div className={styles.editionInfoRow}>
                                                    <StudentsIcon />
                                                    {edition.students}
                                                    {edition.students === 1 ? ' studente' : ' studenti'}
                                                </div>
                                            </div>
                                            <div className={`${styles.editionStatus} ${getStatusStyle(edition.status)}`}>{t(`editions.status.${edition.status}`)}</div>
                                        </div>
                                    )
                                }} />
                            }
                            {
                                loading === false && (!editions || editions?.length === 0) &&
                                <EmptyContent
                                    style={{ width: "100%", display: "flex", justifyContent: "center" }}
                                    Illustration={EmptyIllustration}
                                    title={"Nessun edizione ancora creata"}
                                    message={
                                        <div style={{ display: "flex", alignItems: "center", }}>
                                            <Button
                                                appearance="text"
                                                onClick={() => navigate(`/courses/${courseId}/editions/create`)}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: ".2rem"
                                                }}
                                            >
                                                <AddIcon />
                                                Aggiungi
                                            </Button>
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AlertDialog open={alert.open} title={alert.title} text={alert.text} actions={alert.actions} onClose={() => {
                setAlert((prev) => {
                    prev.open = false
                    return { ...prev }
                })
            }} />
        </HeaderFooterLayout>
    )

}

export default Course
