import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import api from "../api";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import { ReactComponent as ModulePlaceholder } from "../assets/images/illustrations/il-module-placeholder.svg";
import { CourseType, FileType, TagType } from "../common/constants";
import { Each } from "../common/Each";
import Back from "../components/Back";
import Button from "../components/Button";
import Card from "../components/cards/Card";
import StudentCard from "../components/cards/StudentCard";
import AlertDialog from "../components/dialogs/AlertDialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Select from "../components/Select";
import Tag from "../components/Tag";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import styles from "./EditionEditor.module.css";
import Uploader from "../components/Uploader";
import StudentProgress from "../components/StudentProgress";
import Loader from "../components/Loader";
import { ReactSortable } from "react-sortablejs"
import MultiStateSwitch from "../components/MultiStateSwitch";

const EditionEditor = () => {

    const navigate = useNavigate()
    const [edition, setEdition] = useState(null)
    const { courseId, editionId } = useParams()
    const [course, setCourse] = useState(null)
    const [tags, setTags] = useState(null)
    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })
    const [loading, setLoading] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [uploaderReset, setUploaderReset] = useState(false)
    const [progresses, setProgresses] = useState(null)
    const [filterProgresses, setFilterProgresses] = useState([])
    const [loadingProgresses, setLoadingProgresses] = useState(false)
    const [studentsTab, setStudentsTab] = useState(0)

    const [status, setStatus] = useState(
        [
            { id: 0, name: 'Bozza', value: 'draft' },
            { id: 1, name: 'Pubblico', value: 'public' },
            { id: 2, name: 'Nascosto', value: 'hidden' },
            { id: 3, name: 'Terminato', value: 'ended' }
        ]
    )

    const initializeStatus = (status) => {
        switch (status) {
            case 'draft':
                setStatus([
                    { id: 0, name: 'Bozza', value: 'draft' },
                    { id: 1, name: 'Pubblico', value: 'public' },
                ])
                break
            case 'public':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' },
                    { id: 3, name: 'Terminato', value: 'ended' }
                ])
                break
            case 'hidden':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' },
                    { id: 3, name: 'Terminato', value: 'ended' }
                ])
                break
            case 'ended':
                setStatus([
                    { id: 2, name: 'Nascosto', value: 'hidden' },
                    { id: 3, name: 'Terminato', value: 'ended' }
                ])
                break
            default: return
        }
    }

    useEffect(() => {
        const getCourse = async () => {
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (courseId) {
            getCourse()
        }
    }, [courseId])

    useEffect(() => {
        const getEdition = async (edition_id) => {
            try {
                let edition = await api.get(`/admin/editions/${edition_id}`)
                setEdition(edition)
                initializeStatus(edition.status)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (editionId && editionId !== 'create') {
            getEdition(editionId)
        }

        if (editionId && editionId === 'create') {
            let now = new Date()
            setEdition({
                name: '',
                description: '',
                limit: 30,
                expired_at: new Date().toISOString(),
                locked_at: new Date(now.setFullYear(now.getFullYear() + 1)),
                thumbnail: null,
                status: 'draft',
                tags: [],
                students: [],
                modules: []
            })
        }
    }, [editionId])

    useEffect(() => {
        if (course && editionId === 'create') {
            setEdition((prev) => {
                prev.thumbnail = course.thumbnail
                return { ...prev }
            })
        }
    }, [course, editionId])

    useEffect(() => {
        const getStudentsProgresses = async () => {
            setLoadingProgresses(true)
            try {
                let progresses = await api.get(`/admin/editions/${edition.id}/progresses`)
                setProgresses(progresses.sort((a, b) => {
                    return a.surname - b.surname
                }))
                setFilterProgresses(progresses.sort((a, b) => {
                    return a.surname - b.surname
                }))
            }
            catch (e) {
                console.error(e)
            }
            setLoadingProgresses(false)
        }

        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=all")
                tags = tags.filter(t => {
                    return !edition.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (!tags && edition) {
            getTags()
        }
        if (edition) {
            if (edition.status === 'public') {
                setSaveDisabled(edition.name.trim() === '' || edition.description.trim() === '' || edition.thumbnail.trim() === '')
            }
            else {
                setSaveDisabled(edition.name.trim() === '')
            }
            if (editionId !== "create" && !progresses) {
                getStudentsProgresses()
            }
        }
    }, [edition])

    const onProgressTabChange = (tab) => {
        switch (tab) {
            case 0: setFilterProgresses(progresses)
                break
            case 1: setFilterProgresses(progresses.filter(p => p.has_profile))
                break
            case 2: setFilterProgresses(progresses.filter(p => !p.has_profile))
            default: return
        }
        setStudentsTab(tab)
    }

    const create = async () => {
        setLoading(true)
        try {
            const newEdition = {
                course_id: course.id,
                name: edition.name,
                description: edition.description,
                expired_at: edition.expired_at,
                locked_at: edition.locked_at,
                limit: edition.limit,
                status: edition.status,
                thumbnail: edition.thumbnail,
                tags: tags.length > 0 ? JSON.stringify(edition.tags.map(t => t.id)) : null
            }
            const created = await api.post(`/admin/editions`, newEdition)

            setEdition(created)
            initializeStatus(created.status)
            setUploaderReset(!uploaderReset)
            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                            navigate(`/courses/${courseId}/editions/${created.id}`)
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)
    }

    const update = async () => {
        setLoading(true)
        try {

            const updatedEdition = {
                name: edition.name,
                description: edition.description,
                expired_at: edition.expired_at,
                locked_at: edition.locked_at,
                limit: edition.limit,
                status: edition.status,
                thumbnail: edition.thumbnail,
                tags: tags.length > 0 ? JSON.stringify(edition.tags.map(t => t.id)) : null
            }
            const updated = await api.put(`/admin/editions/${edition.id}`, updatedEdition)
            setEdition(updated)
            initializeStatus(updated.status)
            setUploaderReset(!uploaderReset)
            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)
    }

    const save = async () => {
        if (editionId === 'create') {
            await create()
        }
        else {
            await updateModulePositions()
            await update()
        }
    }

    const updateModulePositions = async () => {
        let position = edition.modules.map((module, index) => ({
            module_id: module.id, // Usa l'ID del modulo
            position: index,      // Usa l'indice come posizione
        }));
        await api.post('/admin/modules/order', position)
    }

    const onStudentsSearch = (q) => {
        if (q.trim().length > 0) {
            setFilterProgresses(progresses.filter(p => `${p.name.toLowerCase()}${p.surname.toLowerCase()}`.includes(q.trim().toLowerCase().replace(' ', ''))))
        }
        else {
            setFilterProgresses(progresses)
        }
    }

    const setListModules = async (modules) => {
        let editionClone = { ...edition }
        editionClone.modules = modules.map((module, index) => ({
            ...module,
            position: index, // Imposta la posizione in base all'indice dell'array
        }));
        setEdition(editionClone)
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                {edition &&
                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <Back onClick={() => { navigate(`/courses/${courseId}`) }} />
                            {course && edition &&
                                <div className={`${typo.title} ${styles.navigation}`}>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}`)
                                    }}>{course.name}</div>
                                    <div>{">"}</div>
                                    <div>{edition.name}</div>
                                </div>
                            }
                            <Card>
                                <div className={styles.cardInner}>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>NOME</div>
                                                <TextInput type="text" value={edition.name} placeholder="Nome" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.name = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>STATO</div>
                                                <Select style={{ height: 'fit-content' }} selected={status.find(s => s.value === edition.status)} options={status} onSelect={(value) => {
                                                    setEdition((prev) => {
                                                        prev.status = value.value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>TAG</div>
                                                {tags &&
                                                    <TagSelector
                                                        style={{ fontSize: '1rem' }}
                                                        selected={edition.tags.filter(t => t.type === TagType.General)}
                                                        options={tags.filter(t => t.type === TagType.General)}
                                                        placeholder={"Cerca tag"}
                                                        onAdd={(item) => {
                                                            const newOptions = tags.filter(t => t.id !== item.id)
                                                            setTags([...newOptions])

                                                            setEdition((prev) => {
                                                                if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                                    prev.tags.push(item)
                                                                }
                                                                return { ...prev }
                                                            })
                                                        }}
                                                        onRemove={(item) => {
                                                            const newOptions = [...tags]
                                                            if (!newOptions.map(t => t.id).includes(item.id)) {
                                                                newOptions.push(item)
                                                            }
                                                            setTags([...newOptions])

                                                            setEdition((prev) => {
                                                                prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                                return { ...prev }
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>LIMITE STUDENTI</div>
                                                <TextInput value={edition.limit} placeholder="Limite Studenti" type="number" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.limit = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>SCADENZA ISCRIZIONI</div>
                                                <TextInput
                                                    type="date"
                                                    value={new Date(edition.expired_at)}
                                                    defaultValue={edition.expired_at ? new Date(edition.expired_at) : null}
                                                    placeholder="Scadenza Iscrizioni"
                                                    onKeyUp={(d) => {
                                                        setEdition((prev) => {
                                                            prev.expired_at = d.toISOString()
                                                            return { ...prev }
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>SCADENZA MATERIALE</div>
                                                <TextInput
                                                    type="date"
                                                    value={new Date(edition.locked_at)}
                                                    defaultValue={edition.locked_at ? new Date(edition.locked_at) : null}
                                                    placeholder="Scadenza Materiale"
                                                    onKeyUp={(d) => {
                                                        setEdition((prev) => {
                                                            prev.locked_at = d.toISOString()
                                                            return { ...prev }
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DESCRIZIONE</div>
                                                <TextareaAutosize
                                                    value={edition.description}
                                                    minRows={5}
                                                    maxRows={10}
                                                    type="text"
                                                    className={styles.textArea}
                                                    placeholder="Descrizione"
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        setEdition((prev) => {
                                                            prev.description = value
                                                            return { ...prev }
                                                        })
                                                    }} />
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>THUMBNAIL</div>
                                                <TextInput disabled={edition?.thumbnail?.startsWith('blob:')} type="text" value={edition?.thumbnail} placeholder="Thumbnail URL" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.thumbnail = value ? value : ''
                                                        return { ...prev }
                                                    })
                                                }} />
                                                <Uploader
                                                    crop={true}
                                                    preview={edition?.thumbnail}
                                                    reset={uploaderReset}
                                                    multiple={false}
                                                    type={FileType.Image}
                                                    onComplete={(uploads) => {
                                                        setEdition((prev) => {
                                                            prev.thumbnail = uploads[0].url
                                                            return { ...prev }
                                                        })
                                                    }}
                                                    onDelete={() => {
                                                        setEdition((prev) => {
                                                            prev.thumbnail = ""
                                                            return { ...prev }
                                                        })
                                                    }}
                                                />
                                                <div className={styles.duration}>
                                                    <div className={typo.caption}>DURATA</div>
                                                    <div className={typo.body}><strong>{edition.duration}h</strong></div>
                                                    <div className={typo.caption}>La durata dell'edizione è calcolata automaticamente sulla base della durata dei suoi moduli.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Card>

                            {editionId !== 'create' &&
                                <>
                                    <div className={`${typo.subtitle} ${styles.modulesHeader}`}>
                                        Moduli • {edition.modules.length}
                                        <Button onClick={() => {
                                            if (course.type === CourseType.Masterclass) {
                                                if (edition.modules.length === 0) {
                                                    navigate(`/courses/${courseId}/editions/${editionId}/modules/create`)
                                                } else {
                                                    setAlert({
                                                        open: true,
                                                        text: "Le masterclass possono contenere al massimo un modulo.",
                                                        status: DialogStatus.Error,
                                                        actions: [
                                                            {
                                                                label: 'OK',
                                                                onClick: () => {
                                                                    setAlert((prev) => {
                                                                        prev.open = false
                                                                        return { ...prev }
                                                                    })
                                                                }
                                                            }
                                                        ]
                                                    })
                                                }
                                            } else if (course.type === CourseType.Professional) {
                                                navigate(`/courses/${courseId}/editions/${editionId}/modules/create`)
                                            }
                                        }}>
                                            AGGIUNGI
                                        </Button>
                                    </div>
                                    <div className={styles.modules}>
                                        <ReactSortable list={edition.modules} setList={setListModules}>
                                            <Each of={edition.modules.sort((a, b) => a.position - b.position)} render={(module) => {
                                                return (
                                                    <div className={styles.module} onClick={() => {
                                                        navigate(`/courses/${courseId}/editions/${editionId}/modules/${module.id}`)
                                                    }}>
                                                        <div className={styles.moduleDuration}>
                                                            <ClockIcon />
                                                            {module.duration}h
                                                        </div>
                                                        {module.thumbnail &&
                                                            <img src={module.thumbnail} className={styles.moduleThumbnail} alt="" />
                                                        }
                                                        {!module.thumbnail &&
                                                            <ModulePlaceholder className={styles.moduleThumbnail} />
                                                        }
                                                        <div className={styles.moduleInfo}>
                                                            <div className={styles.moduleName}>
                                                                {module.name}
                                                            </div>
                                                            <div className={styles.moduleDescription}>
                                                                {module.description}
                                                            </div>
                                                            <div className={styles.moduleTags}>
                                                                <Each of={module.tags} render={(tag) => {
                                                                    return (
                                                                        <Tag tag={tag} />
                                                                    )
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }} />
                                        </ReactSortable>
                                    </div>

                                    <div className={styles.studentsHeader}>
                                        <div className={styles.studentsHeaderInner}>
                                            <div className={typo.subtitle}>
                                                Studenti
                                            </div>

                                            <MultiStateSwitch
                                                states={[
                                                    "TUTTI",
                                                    "REGISTRATI",
                                                    "NON REGISTRATI",
                                                ]}
                                                selected={studentsTab}
                                                onStateChange={onProgressTabChange} />
                                        </div>
                                        <div className={typo.title}>
                                            {filterProgresses.length}
                                        </div>
                                    </div>

                                    <TextInput
                                        onKeyUp={onStudentsSearch}
                                        type="search"
                                        placeholder={"Cerca..."}
                                        style={{
                                            backgroundColor: "var(--background-secondary-color)",
                                        }} />
                                    {loadingProgresses &&
                                        <Loader />
                                    }
                                    <div className={styles.students}>
                                        <Each of={filterProgresses.sort((a, b) => {
                                            return a.surname.localeCompare(b.surname);
                                        })} render={(student) => {
                                            return (
                                                <div className={styles.student}>
                                                    <StudentProgress student={student} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </div >
            <div className={styles.bottomBar}>
                <Button
                    disabled={loading || saveDisabled}
                    onClick={() => {
                        save()
                    }}>
                    {editionId === 'create' ? "SALVA" : "SALVA MODIFICHE"}
                </Button>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }
                } />
        </HeaderFooterLayout >
    )

}

export default EditionEditor
