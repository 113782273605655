import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-pencil.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import ThumbnailPlaceholder from "../../assets/images/placeholders/thumbnail-placeholder.jpg"
import useContextMenu from "../../common/hooks/useContextMenu"
import Button from "../Button"
import styles from "./ArticleCard.module.css"
import Card from "./Card"
import MeatBallsMenu from "../MeatBallsMenu"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import Skeleton from "../Skeleton"
import Markdown from "react-markdown"
import api from "../../api"
import { useState } from "react"
import { DialogStatus } from "../../enums"
import AlertDialog from "../dialogs/AlertDialog"
const ArticleCard = ({ article, onDelete = () => { }, onEdit = () => { }, interactive = true, loading = false }) => {

    const { showMenu } = useContextMenu()
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)

    const onContextMenu = (e) => {
        const actions = [
            { name: "Modifica", icon: <EditIcon className={styles.icon} style={{ color: "var(--sf-light-green)" }} />, onClick: onEdit },
            { name: "Elimina", icon: <DeleteIcon className={styles.icon} style={{ color: "var(--sf-red)" }} />, onClick: () => setOpenAlert(true) }
        ]
        showMenu(e, actions)
    }

    const deleteArticle = async () => {
        try {
            await api.delete(`/admin/articles/${article.id}`)
            onDelete()
        } catch (e) {
            console.error(e)
        }
    }

    const onDialogClose = () => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
    }

    return (
        <Card style={{ padding: '.5rem', width: '100%' }}>
            {
                !loading &&
                <div className={`${styles.container} ${styles.small}`}
                    onContextMenu={interactive ? onContextMenu : () => { }}
                >
                    <img src={article.thumbnail ?? ThumbnailPlaceholder}
                        alt={`${article.name}-thumbnail`}
                        className={`${styles.thumbnail} ${styles.small}`} />
                    <div className={`${styles.info} ${styles.small}`}>
                        <div className={`${styles.title} ${styles.small}`}>{article.name}</div>
                        <div className={`${styles.abstract} ${styles.small}`}>
                            <Markdown>
                                {article.abstract}
                            </Markdown>
                        </div>
                        <div className={styles.actionRow}>
                            {
                                article.reading_time &&
                                <div className={styles.readingTime}>
                                    <ClockIcon className={styles.clockIcon} />
                                    {article.reading_time} {article.reading_time > 1 ? 'minuti' : 'minuto'}
                                </div>
                            }
                            {
                                article.abstract &&
                                <Button
                                    disabled={!article.url}
                                    appearance="text"
                                    style={{ fontSize: '0.875rem' }}
                                    onClick={() => { window.open(article.url, "_blank") }}
                                >
                                    CONTINUA A LEGGERE
                                </Button>
                            }
                        </div>
                        <div className={styles.tags}>
                            <Each of={article.tags} render={t => <Tag tag={t} />} />
                        </div>
                        {
                            interactive &&
                            <div className={styles.menu}>
                                <MeatBallsMenu actions={[
                                    { label: "Modifica", icon: EditIcon, onClick: () => onEdit(), style: { color: "var(--sf-light-green)" } },
                                    { label: "Elimina", icon: DeleteIcon, onClick: () => setOpenAlert(true), style: { color: "var(--sf-red)" } },
                                ]}
                                />
                            </div>
                        }
                    </div>
                    {
                        openAlert &&
                        <AlertDialog
                            open={openAlert}
                            title={"Elimina articolo"}
                            text={`Sei sicuro di voler eliminare questo articolo?`}
                            status={alertStatus}
                            onClose={onDialogClose}
                            actions={[
                                {
                                    label: alertStatus === DialogStatus.Success ? "OK" : "SI, ELIMINA",
                                    onClick: alertStatus === DialogStatus.Success ? onDialogClose : deleteArticle,
                                }
                            ]}
                        />
                    }
                </div>
            }

            {
                loading &&
                <div className={`${styles.container} ${styles.small}`}>
                    <Skeleton type="rect" width="120px" height="96px" borderRadius="8px" />
                    <div className={`${styles.info} ${styles.small}`} style={{ width: "100%" }}>
                        <Skeleton type="rect" width="32%" height="19px" borderRadius="8px" />
                        <Skeleton type="rect" width="96%" height="12px" borderRadius="8px" />
                        <Skeleton type="rect" width="96%" height="12px" borderRadius="8px" />
                        <Skeleton type="rect" width="96px" height="12px" borderRadius="8px" />
                    </div>
                </div>
            }
        </Card>
    )
}

export default ArticleCard
