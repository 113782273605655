import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import MainContext from '../common/MainContext';
import { PermissionLevel } from '../common/constants';
import { auth } from "../firebase";

const ProtectedRoute = ({ component, permissionLevel = PermissionLevel.Administration }) => {

    const context = useContext(MainContext)

    return (
        <>
            {/* {auth.currentUser && context.user ? component : <Navigate to="/login" />} */}

            {!auth.currentUser && <Navigate to="/login" />}

            {
                auth.currentUser && context.user && (
                    <>
                        {permissionLevel.includes(context.user.role) && component}
                        {!permissionLevel.includes(context.user.role) && <Navigate to="/" />}
                    </>
                )
            }
        </>
    );
}

export default ProtectedRoute
