import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { Each } from "../common/Each"
import StudentCardItem from "./cards/StudentCardItem"
import Pagination from "./Pagination"
import TextInput from "./TextInput"
import styles from "./Students.module.css"
import Button from "./Button"
import { capitalize } from "../utils"
import { DialogStatus } from "../enums"
import Dialog from "./dialogs/Dialog"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ButtonStatus } from "../common/constants"
import Checkbox from "./Checkbox"
import { set } from "date-fns"

const Students = forwardRef(({orderBy, sortDirection}, ref) => {
    const { t } = useTranslation()

    const [loadingStudents, setLoadingStudents] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [students, setStudents] = useState([])
    const [count, setCount] = useState(null)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const [showPagination, setShowPagination] = useState(true)
    const [q, setQ] = useState(null)

    const [addStatus, setAddStatus] = useState(null)
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [mandatorySurvey, setMandatorySurvey] = useState(false)

    const getStudents = useCallback(async (q = null) => {

        setLoadingStudents(true)
        try {
            let route = q ?
                `/admin/users?q=${q}&orderBy=${sortDirection}${orderBy.value}` :
                `/admin/users?page=${page}&per=${per}&orderBy=${sortDirection}${orderBy.value}`
            
            const students = await api.get(route)
            const { users, count } = students
            setStudents(users)
            setCount(count)
        } catch (e) {
            console.error(e)
        }
        setLoadingStudents(false)
        if (loadingPage) {
            setLoadingPage(false)
        }
    }, [page, per, orderBy, sortDirection, loadingPage])

    useEffect(() => {
        getStudents(q)
    }, [page, per, orderBy, sortDirection])


    const onSearch = useCallback(async (text) => {
        if (text && text.length >= 3) {
            setShowPagination(false)
            getStudents(text)
            return;
        }

        if (!text) {
            setShowPagination(true)
            setPage(0)
            getStudents()
        }
    }, [getStudents])

    const addStudent = async () => {
        setAddStatus(ButtonStatus.Loading)
        try {
            const newUser = {
                name,
                surname,
                email,
                mandatory_profiler: mandatorySurvey
            }
            await api.post("/admin/users", newUser)
            setAddStatus(ButtonStatus.Success)
            resetDialog()
            getStudents()
        } catch (e) {
            console.error(e)
            setAddStatus(ButtonStatus.Error)
        }
    }

    const resetDialog = () => {
        setOpen(false)
        setName("")
        setSurname("")
        setEmail("")
        setMandatorySurvey(false)
    }

    const onAddClick = () => setOpen(true)

    useImperativeHandle(ref, () => {
        return {
            onAddClick
        }
    })

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.sectionInner}>
                    <TextInput
                        onKeyUp={(value) => {
                            setQ(value !== '' ? value : null)
                            onSearch(value)
                        }}
                        type="search"
                        placeholder={t("search")}
                        style={{
                            backgroundColor: "var(--background-secondary-color)",

                        }} />

                    <div className={styles.students}>
                        {
                            loadingStudents &&
                            <Each
                                of={Array(per).fill("")}
                                render={() => (
                                    <StudentCardItem student={null} loading />
                                )}
                            />
                        }
                        {
                            !loadingStudents &&
                            <Each
                                of={students}
                                render={(student) => (
                                    <StudentCardItem student={student} bySurname={orderBy.value.includes('surname')}/>
                                )}
                            />
                        }

                        {
                            !loadingStudents && students.length === 0 &&
                            <div className={styles.noData}>Non sono presenti studenti</div>
                        }
                    </div>
                    {
                        showPagination &&
                        <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
                    }
                </div>
            </div>
            <Dialog
                open={open}
                onClose={resetDialog}
                title={`Aggiungi studente`}
                style={{ maxWidth: "512px" }}
            >
                <div className={styles.dialog}>
                    <div className={styles.inputs}>
                        <TextInput value={name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                        <TextInput value={surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                        <TextInput value={email} type="text" placeholder={capitalize(t('profile.email'))} onKeyUp={v => setEmail(v)} />
                        <div className={styles.mandatorySurvey}>
                            <Checkbox
                                label={"Questionario obbligatorio?"}
                                onChange={v => setMandatorySurvey(v)}
                            />
                        </div>
                        <div className={styles.bottom}>
                            <Button
                                style={{ padding: '0.7rem 1rem' }}
                                accentColor={"var(--primary)"}
                                disabled={!name || !surname || !email || addStatus === DialogStatus.Loading || addStatus === DialogStatus.Success}
                                status={addStatus}
                                onClick={addStudent}
                            >
                                AGGIUNGI
                                {
                                    addStatus === null &&
                                    <AddIcon />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
})

export default Students
