import { useTranslation } from "react-i18next"
import Markdown from 'react-markdown'
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { LessonType } from "../../common/constants"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import { formatDateV2, formatTimeV2 } from "../../utils"
import Button from "../Button"
import TabItem from "../TabItem"
import Tag from "../Tag"
import styles from "./ModuleLessonCard.module.css"

const ModuleLessonCard = ({ lesson, onEdit = () => { } }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.name}>{lesson.name}</div>
                {
                    lesson.type === LessonType.Live &&
                    <div className={styles.time}><CalendarIcon />
                        {lesson.starts_at && lesson.ends_at &&
                            <>
                                {formatTimeV2(lesson.starts_at)} - {formatTimeV2(lesson.ends_at)}, {formatDateV2(lesson.starts_at)}
                            </>
                        }
                        {(!lesson.starts_at || !lesson.ends_at) &&
                            <>
                                Da Inserire
                            </>
                        }
                    </div>
                }
                {
                    lesson.type === LessonType.Recorded &&
                    <div className={styles.recordedInfo}>
                        <div className={styles.type}>
                            <TabItem
                                selected
                                text={"On demand"}
                                IconComponent={CameraIcon}
                                color={"var(--primary-rgb)"}
                                style={{ padding: ".5rem" }}
                            />
                        </div>
                        <div className={typo.subheadline}> {lesson.duration} min</div>
                    </div>

                }
                {lesson.description &&
                    <div className={styles.description}>
                        <Markdown>{lesson.description}</Markdown>
                    </div>
                }
                <div className={styles.tags}>
                    <Each
                        of={lesson.tags ?? []}
                        render={
                            (tag) => (
                                <Tag tag={tag} />
                            )
                        }
                    />
                </div>
                <Button inverse onClick={() => {
                    onEdit(lesson)
                }}>
                    <EditIcon /> {t(`lessons.edit`).toUpperCase()}
                </Button>
            </div>
            <div className={styles.right}>
                <img className={styles.thumbnail} src={lesson.thumbnail} alt="" />
            </div>


        </div>
    )
}

export default ModuleLessonCard
