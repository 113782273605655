import { useEffect, useState } from "react"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import styles from "./Collapsable.module.css"
import Markdown from "react-markdown"

const Collapsable = ({ title = "", description, items, expanded = false, onExpand }) => {

    const [exp, setExp] = useState(expanded)

    useEffect(() => {
        setExp(expanded ?? false)
    }, [expanded])

    const toggleExpand = () => {
        if (!exp && onExpand && typeof onExpand === 'function') {
            onExpand()
        }
        setExp(!exp)
    }

    return (
        <div className={styles.container}>
            <button className={`${styles.header} ${exp ? styles.expanded : ''}`} onClick={toggleExpand}>
                {title}
                <ChevronIcon className={`${styles.chevronIcon} ${exp ? styles.expanded : ''}`}></ChevronIcon>
            </button>
            {exp &&
                <div className={styles.divider}></div>
            }
            <div className={`${styles.content} ${exp ? styles.expanded : ''}`}>
                {items &&
                    <Each of={items} render={(item) => {
                        return (
                            <div className={`${styles.argument} ${exp ? styles.expanded : ''}`}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>•</div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    {item}
                                </div>
                            </div>
                        )
                    }} />
                }

                {description && <Markdown>{description}</Markdown>}
            </div>
        </div>
    )
}

export default Collapsable
