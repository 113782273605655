import { useEffect, useMemo, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import api from "../api"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Pagination from "../components/Pagination"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import styles from "./Materials.module.css"
import { Each } from "../common/Each"
import EmptyContent from "../components/EmptyContent"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-pencil.svg"
import { ReactComponent as DownloadIcon } from "../assets/images/icons/ic-download.svg"
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg"
import Card from "../components/cards/Card"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png";
import { formatDateV2, formatTimeV2 } from "../utils"
import { useNavigate } from "react-router-dom"
import Skeleton from "../components/Skeleton"
import Button from "../components/Button"
import DropdownSelection from "../components/DropdownSelection"
import Dialog from "../components/dialogs/Dialog"
import MaterialButton from "../components/MaterialButton"
import Dropzone from "react-dropzone"
import { v4 } from "uuid"
import Loader from "../components/Loader"
import MeatBallsMenu from "../components/MeatBallsMenu"
import { ButtonStatus } from "../common/constants"

const Materials = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [materials, setMaterials] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [page, setPage] = useState(0)
  const [per, setPer] = useState(10)
  const [count, setCount] = useState(null)
  const [showPagination, setShowPagination] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)
  const [courses, setCourses] = useState([])
  const [editions, setEditions] = useState([])
  const [modules, setModules] = useState([])
  const [lessons, setLessons] = useState([])
  const [course, setCourse] = useState(null)
  const [edition, setEdition] = useState(null)
  const [module, setModule] = useState(null)
  const [lesson, setLesson] = useState(null)
  const [files, setFiles] = useState([])
  const [currentMaterial, setCurrentMaterial] = useState(null)
  const [waitingAdd, setWaitingAdd] = useState(false)
  const [waitingEdit, setWaitingEdit] = useState(false)

  const dropzoneRef = useRef(null)

  useEffect(() => {
    getCourses()
  }, [])

  useEffect(() => {
    getMaterials();
  }, [page]);


  useEffect(() => {
    if (!openDialog) {
      onDialogClose()
    }
  }, [openDialog])

  useEffect(() => {
    if (course) {
      getEditions()
      if (course.id !== currentMaterial.course.id) {
        setCurrentMaterial(c => ({ ...c, course, edition: null, module: null, lesson: null }))
        setEditions([])
        setModules([])
        setLessons([])
        setEdition(null)
        setModule(null)
        setLesson(null)
      }
    } else {
      setEditions([])
      setModules([])
      setLessons([])
    }
  }, [course])

  useEffect(() => {
    if (edition) {
      setModules(edition.modules.map(m => ({ ...m, label: m.name })))
    } else {
      setModules([])
      setLessons([])
    }
  }, [edition])

  useEffect(() => {
    if (module) {
      setLessons(module.lessons.map(l => ({ ...l, label: l.name })))
    } else {
      setLessons([])
    }
  }, [module])

  useEffect(() => {
    if (currentMaterial) {
      const c = courses.find(cc => cc.id === currentMaterial.course.id)
      setCourse(c)
    }

  }, [currentMaterial])

  useEffect(() => {
    if (editions.length > 0 && currentMaterial && !edition) {
      const e = editions.find(ee => ee.id === currentMaterial.edition?.id)
      setEdition(e)
      setOpenDialog(true)
    }
  }, [editions])

  useEffect(() => {
    if (modules.length > 0 && currentMaterial && !module) {
      setModule(modules.find(m => m.id === currentMaterial.module?.id))
    }
  }, [modules])

  useEffect(() => {
    if (lessons.length > 0 && currentMaterial?.lesson && !lesson) {
      setLesson(lessons.find(l => l.id === currentMaterial.lesson?.id))
    }
  }, [lessons])

  const getMaterials = async (q = null) => {
    setLoading(true);
    try {
      const data = q ?
        await api.get(`/admin/materials?q=${q}`) :
        await api.get(`/admin/materials?page=${page}&per=${per}`);
      setMaterials(data.materials);
      setCount(data.count)
      setShowPagination(data.count > per)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    if (loadingPage) {
      setLoadingPage(false)
    }
  }

  const getCourses = async () => {
    try {
      const courses = await api.get("/admin/courses?with_edition=true");
      setCourses(courses.map(c => ({ ...c, label: c.name })))
    } catch (e) {
      console.error(e)
    }
  }

  const getEditions = async () => {
    try {
      let editions = await api.get(`/admin/courses/${course.id}/editions`);
      editions = editions.filter(e => e.modules && e.modules.length > 0)
      setEditions(editions.map(e => ({ ...e, label: e.name })))
    } catch (e) {
      console.error(e)
    }
  }

  const onSearch = (searchText) => {
    if (searchText) {
      getMaterials(searchText)
    } else {
      getMaterials()
    }
  }


  const onDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const save = () => {
    if (currentMaterial) {
      edit()
    } else {
      add()
    }
  }

  const edit = async () => {
    setWaitingEdit(ButtonStatus.Loading)
    const newMaterial = {
      module_id: module.id,
    }
    if (lesson !== null) {
      newMaterial.lesson_id = lesson.id
    }
    try {
      const res = await api.put(`/admin/materials/${currentMaterial.id}`, newMaterial);
      getMaterials()
      setOpenDialog(false)
    } catch (e) {
      console.error(e)
      setWaitingEdit(ButtonStatus.Error)
    }
  }

  const add = async () => {
    setWaitingAdd(true)
    const form = new FormData()
    if (lesson !== null) {
      form.append("lesson_id", lesson.id)
    }
    form.append("module_id", module.id)

    for (let i = 0; i < files.length; i++) {
      form.append(`files`, files[i])
    }
    try {
      const res = await api.post("/admin/materials", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      setPage(0)
    } catch (e) {
      console.error(e)
    }
    setWaitingAdd(false)
    setOpenDialog(false)
  }


  const deleteMaterial = async (id) => {
    try {
      await api.delete(`/admin/materials/${id}`)
      getMaterials()
    } catch (e) {
      console.error(e)
    }
  }

  const onDialogClose = () => {
    setCourse(null)
    setEdition(null)
    setModule(null)
    setLesson(null)
    setFiles([])
    setCurrentMaterial(null)
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Materiali</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={typo.title}>Materiali</div>
                <Button
                  style={{ padding: ".75rem 1rem" }}
                  onClick={() => setOpenDialog(true)}
                >
                  AGGIUNGI
                  <AddIcon />
                </Button>
              </div>
              <TextInput
                onKeyUp={onSearch}
                type="search"
                placeholder={"Ricerca"}
                style={{
                  backgroundColor: "var(--background-secondary-color)",
                }} />
              <div className={styles.materials}>
                {
                  loading === false && materials.length > 0 &&
                  <Each
                    of={materials}
                    render={material => (
                      <div className={styles.material}>
                        <Card>
                          <div className={styles.materialContainer}>
                            <div className={styles.materialContent}>
                              <div className={styles.teacher} onClick={() => navigate(`/users/teachers/${material.teacher.id}`)}>
                                <img className={styles.picture} src={material.teacher.picture ?? UserPlaceholder} alt="" />
                                <div className={styles.column}>
                                  <div className={styles.name}>
                                    {material.name}
                                  </div>
                                  <div className={styles.teacherName}>{material.teacher.name} {material.teacher.surname}</div>
                                </div>
                              </div>
                              <div className={styles.info}>
                                <div className={styles.labeled}>
                                  <div className={styles.value} style={{ maxWidth: "320px" }}>
                                    {`${material.course.name} - ${material.edition.name}`} - {material.module.name}
                                    {
                                      material.lesson &&
                                      ` - ${material.lesson?.name ?? "Nessuna lezione"}`
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className={styles.time}>
                                {formatTimeV2(material.created_at)}, {formatDateV2(material.created_at)} <ClockIcon className={styles.icon} />
                              </div>
                              <div className={styles.download} onClick={() => onDownload(material.url)}>
                                <DownloadIcon style={{ width: "18px" }} />
                              </div>
                              <MeatBallsMenu actions={[
                                { label: "Elimina", icon: DeleteIcon, onClick: () => deleteMaterial(material.id), style: { color: "var(--sf-red)" } },
                                { label: "Modifica", icon: EditIcon, onClick: () => setCurrentMaterial(material), style: { color: "var(--sf-light-green)" } }
                              ]} />
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  />
                }
                {
                  loading &&
                  <Each
                    of={[0, 1, 2]}
                    render={material => (
                      <div className={styles.material}>
                        <Card>
                          <div className={styles.materialContainer}>
                            <div className={styles.materialContent}>
                              <div className={styles.teacher} >
                                <Skeleton width="48px" height="48px" type="circle" />
                                <div className={styles.column}>
                                  <div className={styles.name}>
                                    <Skeleton width="148px" height="1rem" type="rect" borderRadius={"8px"} />
                                  </div>
                                  <Skeleton width="272px" height="1rem" type="rect" borderRadius={"8px"} />
                                </div>
                              </div>
                              <div className={styles.info}>
                                <div className={styles.labeled}>
                                  <div className={styles.value} style={{ maxWidth: "320px" }}>
                                    <Skeleton width="164px" height="1rem" type="rect" borderRadius={"8px"} />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.time}>
                                <Skeleton width="32px" height="32px" type="rect" borderRadius={"8px"} />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  />
                }

                {
                  loading === false && materials.length === 0 &&
                  <EmptyContent
                    Illustration={EmptyIllustration}
                    title={"Nessun materiale ancora creato"}
                    message={
                      <div style={{ display: "flex", alignItems: "center", }}>
                        <Button
                          appearance="text"
                          onClick={() => setOpenDialog(true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".2rem"
                          }}
                        >
                          <AddIcon />
                          Aggiungi
                        </Button>
                      </div>
                    }
                  />
                }
              </div>
              {
                showPagination &&
                <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
              }
            </div>
          </div>

          <Dialog
            open={openDialog}
            title={currentMaterial ? "Modifica materiale" : t("materials.addFile")}
            style={{ maxWidth: "512px" }}
            onClose={() => setOpenDialog(false)}
            action={
              <Button onClick={save} status={waitingEdit}>
                {currentMaterial ? "MODIFICA" : t("materials.add").toUpperCase()}
              </Button>
            }
          >
            <div className={styles.dialog}>
              <DropdownSelection
                disabled={courses.length === 0}
                placeholder={"Corso"}
                defaultOption={courses.find(c => c.id === course?.id)?.id}
                options={courses}
                appereance="transparent"
                onSelect={(id) => {
                  if (id) {
                    setCourse(courses.find(c => c.id === id))
                  }
                }} />

              <DropdownSelection
                disabled={editions.length === 0}
                placeholder={"Edizione"}
                defaultOption={editions.find(e => e.id === edition?.id)?.id}
                options={editions}
                appereance="transparent"
                onSelect={(id) => {
                  if (id) {
                    setEdition(editions.find(e => e.id === id))
                    setModule(null)
                    setLesson(null)
                  }
                }} />

              <DropdownSelection
                disabled={modules.length === 0}
                placeholder={"Modulo"}
                defaultOption={modules.find(m => m.id === module?.id)?.id}
                options={modules}
                appereance="transparent"
                onSelect={(id) => {
                  if (id) {
                    setModule(modules.find(e => e.id === id))
                    setLesson(null)
                  }
                }} />

              <DropdownSelection
                disabled={lessons.length === 0}
                placeholder={"Lezione"}
                defaultOption={lessons.find(l => l.id === lesson?.id)?.id}
                options={lessons}
                appereance="transparent"
                onSelect={(id) => {
                  if (id) {
                    setLesson(lessons.find(e => e.id === id))
                  }
                }} />
              {
                !currentMaterial &&
                <div className={styles.dropzoneContainer}>
                  <div className={styles.subtitle} >
                    Materiali
                    {files.length > 0 &&
                      <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                        if (dropzoneRef && dropzoneRef.current) {
                          dropzoneRef.current.click();
                        }
                      }} />
                    }
                  </div>
                  {
                    files.length > 0 && waitingAdd === false &&
                    <div className={styles.filesContainer}>
                      <div className={styles.files}>
                        <Each
                          of={files}
                          render={(file) => (
                            <div className={styles.file}>
                              <MaterialButton
                                material={file}
                                onClick={() => {
                                  setFiles((prevFiles) => {
                                    const idx = prevFiles.findIndex(f => f.id === file.id)
                                    if (idx > -1) prevFiles.splice(idx, 1)
                                    return [...prevFiles]
                                  })
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  }
                  <div style={{
                    display: files.length === 0 && waitingAdd === false ? "flex" : "none",
                  }}>
                    <Dropzone
                      onDrop={(newFiles) => {
                        newFiles.forEach(f => f.id = v4())
                        setFiles([...files, ...newFiles])
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                          <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                            <input {...getInputProps()} />
                            <div className={styles.dropzone}>
                              <InsertDocumentIllustration />
                              <div className={styles.dropzoneLabel}>
                                {t("materials.dragAndDrop")}
                              </div>
                              <Button
                                style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                additionalClass={styles.blueButton}
                                onClick={() => dropzoneRef.current?.click()}
                              >
                                {t("materials.selectFile").toUpperCase()}
                              </Button>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {
                    waitingAdd === true &&
                    <div className={styles.loader}>
                      <Loader />
                    </div>
                  }
                </div>
              }
            </div>
          </Dialog>
        </div>
      </div>

    </HeaderFooterLayout>
  )
}

export default Materials
